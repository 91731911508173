import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="facebook"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconFacebookComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.463 17.2688V19.7703H18.6318V22.8278H20.463V31.9166H24.2272V22.8278H26.7524C26.7524 22.8278 26.9904 21.3617 27.1048 19.7576H24.2417V17.6684C24.2417 17.3542 24.6523 16.9345 25.0592 16.9345H27.1084V13.7499H24.3198C20.3704 13.7499 20.463 16.811 20.463 17.2688Z"
    />
    <path
      d="M11.9332 8.29985C10.9696 8.29985 10.0454 8.68265 9.36404 9.36404C8.68265 10.0454 8.29985 10.9696 8.29985 11.9332V33.7333C8.29985 34.6969 8.68265 35.6211 9.36404 36.3025C10.0454 36.9839 10.9696 37.3666 11.9332 37.3666H33.7333C34.6969 37.3666 35.6211 36.9839 36.3025 36.3025C36.9839 35.6211 37.3666 34.6969 37.3666 33.7333V11.9332C37.3666 10.9696 36.9839 10.0454 36.3025 9.36404C35.6211 8.68265 34.6969 8.29985 33.7333 8.29985H11.9332ZM11.9332 4.6665H33.7333C35.6605 4.6665 37.5089 5.4321 38.8716 6.79487C40.2344 8.15764 41 10.006 41 11.9332V33.7333C41 35.6605 40.2344 37.5089 38.8716 38.8716C37.5089 40.2344 35.6605 41 33.7333 41H11.9332C10.006 41 8.15764 40.2344 6.79487 38.8716C5.4321 37.5089 4.6665 35.6605 4.6665 33.7333V11.9332C4.6665 10.006 5.4321 8.15764 6.79487 6.79487C8.15764 5.4321 10.006 4.6665 11.9332 4.6665Z"
    />
  </svg>`,
})
export class IconFacebookComponent extends IconComponent {}
