import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="external-link"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconExternalLinkComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.5717 5C24.8897 5 24.2357 5.27092 23.7534 5.75315C23.2712 6.23539 23.0003 6.88944 23.0003 7.57143C23.0003 8.25341 23.2712 8.90747 23.7534 9.3897C24.2357 9.87194 24.8897 10.1429 25.5717 10.1429H32.2214L16.0394 26.3249C15.7938 26.5621 15.5979 26.8458 15.4632 27.1595C15.3284 27.4733 15.2575 27.8107 15.2545 28.1521C15.2515 28.4935 15.3166 28.8321 15.4459 29.1482C15.5752 29.4642 15.7661 29.7513 16.0075 29.9927C16.249 30.2342 16.5361 30.4251 16.8521 30.5544C17.1681 30.6837 17.5067 30.7488 17.8482 30.7458C18.1896 30.7428 18.527 30.6719 18.8407 30.5371C19.1545 30.4024 19.4382 30.2065 19.6754 29.9609L35.8574 13.7789V20.4286C35.8574 21.1106 36.1283 21.7646 36.6106 22.2468C37.0928 22.7291 37.7469 23 38.4288 23C39.1108 23 39.7649 22.7291 40.2471 22.2468C40.7294 21.7646 41.0003 21.1106 41.0003 20.4286V7.57143C41.0003 6.88944 40.7294 6.23539 40.2471 5.75315C39.7649 5.27092 39.1108 5 38.4288 5H25.5717Z"
    />
    <path
      d="M10.1429 10.1429C8.77889 10.1429 7.47078 10.6847 6.50631 11.6492C5.54183 12.6136 5 13.9217 5 15.2857V35.8571C5 37.2211 5.54183 38.5292 6.50631 39.4937C7.47078 40.4582 8.77889 41 10.1429 41H30.7143C32.0783 41 33.3864 40.4582 34.3508 39.4937C35.3153 38.5292 35.8571 37.2211 35.8571 35.8571V28.1429C35.8571 27.4609 35.5862 26.8068 35.104 26.3246C34.6218 25.8423 33.9677 25.5714 33.2857 25.5714C32.6037 25.5714 31.9497 25.8423 31.4674 26.3246C30.9852 26.8068 30.7143 27.4609 30.7143 28.1429V35.8571H10.1429V15.2857H17.8571C18.5391 15.2857 19.1932 15.0148 19.6754 14.5326C20.1577 14.0503 20.4286 13.3963 20.4286 12.7143C20.4286 12.0323 20.1577 11.3782 19.6754 10.896C19.1932 10.4138 18.5391 10.1429 17.8571 10.1429H10.1429Z"
    />
  </svg>`,
})
export class IconExternalLinkComponent extends IconComponent {}
