<ng-container *transloco="let t">
  <a class="subsidiary ui-item-link regular-link" href="{{ subsidiary | establishmentUrl }}">
    <figure>
      <img [src]="subsidiary.image" [alt]="subsidiary.name" />
      @if (distance) {
        <pxw-tag size="xs" type="info">
          <pxw-icon icon="map-marker" />
          {{ roundDistance }} km
        </pxw-tag>
      }
    </figure>
    <div class="footer">
      <div class="content">
        <h2>{{ subsidiary.name }}</h2>
        <p>
          {{ subsidiary.address || subsidiary?.addressDetails?.freeSearch }}
        </p>
      </div>
      <div class="button">
        <button>
          <span>{{ t('subsidiaries.orderHere') }}</span>
        </button>
      </div>
    </div>
  </a>
</ng-container>
