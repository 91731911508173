import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  inject,
} from '@angular/core';
import { VirtualListService } from '../virtual-list.service';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'pxw-virtual-list-item',
  templateUrl: './virtual-list-item.component.html',
  styleUrls: ['./virtual-list-item.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet],
})
export class VirtualListItemComponent implements OnInit, OnDestroy {
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<unknown>;

  @Input({ required: true }) id: string;
  @Input({ required: true }) title: string;
  @Input() forceRender = false;

  @Output() intersectingChange = new EventEmitter<boolean>();

  isRendered = false;
  isDettached = false;

  @HostBinding('attr.title')
  get attrTitle() {
    return this.title;
  }

  get hostElement() {
    return this.rootEl.nativeElement;
  }

  get outletTemplate() {
    if (this.isRendered) {
      return this.itemTemplate;
    }
    return null;
  }

  public rootEl = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);
  private virtualWrapperService = inject(VirtualListService);
  private ngZone = inject(NgZone);
  private renderer = inject(Renderer2);

  ngOnInit(): void {
    this.virtualWrapperService.registerItem(this.id, this);

    if (this.forceRender) {
      this.isRendered = true;

      this.toggleReadyStyle();
    } else {
      this.cd.detach();
      this.isDettached = true;
    }
  }

  ngOnDestroy(): void {
    this.virtualWrapperService.unregisterItem(this.id);
  }

  toggleItemTemplate(isIntersecting: boolean) {
    if (!this.isRendered && isIntersecting) {
      this.isRendered = true;

      this.cd.reattach();
      this.cd.detectChanges();

      this.toggleReadyStyle(100);
    }
    this.intersectingChange.emit(isIntersecting);
  }

  private toggleReadyStyle(delay = 0) {
    if (!delay) {
      this.renderer.addClass(this.rootEl.nativeElement, 'ready');
    } else {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.renderer.addClass(this.rootEl.nativeElement, 'ready');
        }, delay);
      });
    }
  }
}
