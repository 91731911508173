import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { ItemListGroup } from './item-list.component';
import { SortProductsByStockPipe } from '@pedix-workspace/pedixapp-shared-pipes';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pxwItemListGroupProductsByGroup',
  standalone: true,
})
export class ItemListGroupProductsByGroupPipe<T extends { groupName: string }>
  implements PipeTransform
{
  private sortProductsByStockPipe = inject(SortProductsByStockPipe);

  transform(
    products: T[],
    undefinedGroupName: string,
  ): ItemListGroup<{ id: string; name: string }, T>[] {
    const groupsMap = new Map<string, T[]>();
    const undefinedGroupProducts: T[] = [];

    products.forEach(product => {
      if (!product.groupName) {
        undefinedGroupProducts.push(product);
      } else {
        if (!groupsMap.has(product.groupName)) {
          groupsMap.set(product.groupName, []);
        }
        groupsMap.get(product.groupName)?.push(product);
      }
    });

    let listIndex = 0;

    return (
      Array.from(groupsMap.entries())
        .concat([['undefined', undefinedGroupProducts]])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([groupName, groupProducts]) => groupProducts.length > 0)
        .map(([groupName, groupProducts]) => {
          return {
            group: {
              id: groupName,
              name: groupName === 'undefined' ? undefinedGroupName : groupName,
            },
            indexedItems: this.sortProductsByStockPipe.transform(groupProducts).map(product => ({
              listIndex: listIndex++,
              item: product,
            })),
          };
        })
    );
  }
}
