import { isPlatformBrowser } from '@angular/common';
import clsx from 'clsx';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding, Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  inject
} from '@angular/core';

@Component({
  selector: 'pxw-sticky-heading',
  template: '',
  styleUrls: ['./sticky-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class StickyHeadingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() scrollTarget: ElementRef<HTMLElement> | HTMLElement;
  @Input() targetElements: ElementRef<HTMLElement>[];
  @Input() invalidateValue: any; // Any value modification to force re-render

  @Output() activeHeadingChange = new EventEmitter<HTMLElement>();

  ready = false;
  parentOffset: number;
  elementsOffset: Array<{
    elementRef: ElementRef<HTMLElement>;
    offset: number;
  }> = [];
  activeHeading: ElementRef<HTMLElement>;
  onScrollTargetFn: () => void;

  @HostBinding('class')
  get classNames() {
    return clsx({ ready: this.ready });
  }

  get scrollTargetRef(): HTMLElement {
    if ('nativeElement' in this.scrollTarget) {
      return this.scrollTarget.nativeElement;
    }
    return this.scrollTarget;
  }

  private platformId = inject(PLATFORM_ID);
  
  private hostRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private zone = inject(NgZone);
  
  constructor() {}

  ngOnInit() {
    this.onScrollTargetFn = this.onScrollTarget.bind(this);

    this.zone.runOutsideAngular(() => {
      this.scrollTargetRef.addEventListener('scroll', this.onScrollTargetFn);
    });
  }

  ngOnDestroy() {
    this.scrollTargetRef.removeEventListener('scroll', this.onScrollTargetFn);
  }

  ngOnChanges(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.scrollTargetRef && this.targetElements?.length > 0) {
      setTimeout(() => {
        this.initialize();
      }, 0);
    }
  }

  private initialize() {
    this.ready = false;

    this.parentOffset = this.scrollTargetRef.offsetTop;
    this.hostRef.nativeElement.style.top = `${this.parentOffset}px`;

    this.initializeStickyElements();
    this.onScrollTarget();

    this.ready = true;
  }

  private initializeStickyElements() {
    const firstElementOffset = this.targetElements[0].nativeElement.offsetTop - this.parentOffset;

    this.elementsOffset = this.targetElements.map(elementRef => ({
      elementRef,
      offset: elementRef.nativeElement.offsetTop - this.parentOffset - firstElementOffset,
    }));

    this.elementsOffset.sort((a, b) => (a.offset < b.offset ? -1 : 1));
  }

  private onScrollTarget() {
    const scrollTop = this.scrollTargetRef.scrollTop;
    let activeElementOffset = this.elementsOffset[0];

    for (let i = 1; i < this.elementsOffset.length; i++) {
      if (this.elementsOffset[i].offset < scrollTop) {
        activeElementOffset = this.elementsOffset[i];
      }
    }

    if (activeElementOffset && activeElementOffset.elementRef !== this.activeHeading) {
      this.updateActiveChildren(activeElementOffset.elementRef);
    }
  }

  private updateActiveChildren(activeHeadingRef: ElementRef<HTMLElement>) {
    const firstElementClone = activeHeadingRef.nativeElement.cloneNode(true);

    this.hostRef.nativeElement.innerHTML = '';
    this.renderer.appendChild(this.hostRef.nativeElement, firstElementClone);

    this.activeHeadingChange.emit(activeHeadingRef.nativeElement);
  }
}
