type LatLngLiteral = {
  lat: number;
  lng: number;
};

export function getDistanceFromLatLonInKm(
  baseGeopoint: LatLngLiteral,
  targetGeopoint: LatLngLiteral,
) {
  const EARTH_RADIUS = 6371; // Radius of the earth in km
  const dLat = deg2rad(targetGeopoint.lat - baseGeopoint.lat); // deg2rad below
  const dLon = deg2rad(targetGeopoint.lng - baseGeopoint.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(baseGeopoint.lat)) *
      Math.cos(deg2rad(targetGeopoint.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = EARTH_RADIUS * c; // Distance in km

  return distance;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
