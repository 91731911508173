import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { InstagramIssueService } from '../../utils/instagram-issue.service';

@Component({
  selector: 'pxw-instagram-warning',
  standalone: true,
  imports: [CommonModule, AlertComponent],
  template: `
    <div class="instagram-warning">
      <div class="arrow-indicator">
        <img src="assets/images/arrow-top.png" alt="Flecha indicadora" />
      </div>
      <div class="warning-content">
        <h2>⚠️</h2>
        <p>Para continuar con tu pedido, es necesario que abras la tienda en otro navegador.</p>

        <div class="instruction-box">
          <pxw-alert type="info" size="md">
            <p class="instruction-text">
              @if (isAndroid) {
                Apretá en los tres puntitos de arriba a la derecha y seleccioná "Abrir en Chrome" (o
                en tu navegador favorito)
              } @else {
                Apretá en los tres puntitos de arriba a la derecha y seleccioná "Abrir en navegador
                externo"
              }
            </p>
          </pxw-alert>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .instagram-warning {
        position: fixed;
        top: 0;
        left: 0;
        right: -1px;
        bottom: 0;
        background: rgba(33, 36, 61, 0.85);
        z-index: 99999;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
      }

      .arrow-indicator {
        position: fixed;
        top: 20px;
        right: 18px;
        z-index: 10000;

        img {
          width: 70px;
          height: auto;
          animation: float 2s ease-in-out infinite;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          will-change: transform;
          -webkit-animation: float 2s ease-in-out infinite;
          perspective: 1000;
          -webkit-perspective: 1000;
        }
      }

      @keyframes float {
        0%,
        100% {
          transform: translateY(0) translateZ(0);
        }
        50% {
          transform: translateY(-10px) translateZ(0);
        }
      }

      .warning-content {
        max-width: 400px;
      }

      h2 {
        font-size: 47px;
        margin-bottom: 1rem;
        font-weight: 600;
        color: #ff7c7c;
      }

      p {
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 1.5rem;
      }

      .instruction-box {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      }

      .instruction-text {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }
      .hand-down-icon {
        font-size: 30px;
        display: block;
        margin-bottom: 0.5em;
      }
    `,
  ],
})
export class InstagramWarningComponent {
  private instagramIssueService = inject(InstagramIssueService);
  isAndroid = this.instagramIssueService.isAndroid;
}
