<ng-container *transloco="let t">
  @if (tag) {
    <pxw-product-tag size="xs" class="product-tag" [tag]="tag.tag" [color]="tag.color" />
  }
  <a
    data-testid="product-item"
    [title]="name"
    class="product ui-item-link"
    [ngClass]="{
      inactive: withoutStock || hidden
    }"
    [routerLink]="(withoutStock && !isAdminMode) || sortEnabled ? [] : detailRoute"
    [queryParams]="detailRouteQueryParams"
  >
    <figure>
      @if (image) {
        <pxw-image
          [src]="image"
          [alt]="name"
          [lazyLoad]="listIndex >= 0 ? listIndex > lazyLoadImageMinIndex : true"
          objectFit="cover"
          loader="sm"
        ></pxw-image>
      }

      @if (withoutStock) {
        <div class="content-without-stock">
          {{ t('productList.withoutStock') }}
        </div>
      } @else if (!image) {
        <div class="content-without-image">
          {{ t('productList.withoutImage') }}
        </div>
      }
    </figure>

    <div class="body">
      <div class="text">
        @if (displaySku && sku) {
          <p class="product-sku">{{ sku }}</p>
        }
        <h2 class="product-name">
          @if (hidden) {
            <pxw-icon icon="eye-slash" size="xs" class="hidden"></pxw-icon>
          }
          @if (!isAvailable) {
            <pxw-icon icon="clock" size="xs" color="warning" class="not-available"></pxw-icon>
          }
          {{ name }}
        </h2>
      </div>
      @if (presentationItems) {
        <pxw-product-price
          [price]="presentationMinimumPrice"
          [maxDiscount]="maxDiscount"
          layout="presentation-item"
        />
      } @else {
        <pxw-product-price [price]="price" [priceDiscount]="priceDiscount" layout="product-item" />
      }
    </div>
    @if (isAdminMode) {
      <div class="actions">
        @if (!sortEnabled) {
          <button
            name="edit"
            [attr.aria-label]="t('product.editProduct')"
            [ngClass]="{ 'edit-disabled': isUpdatingImages }"
            (click)="onClickEdit($event)"
          >
            <pxw-icon icon="edit"></pxw-icon>
          </button>
          <button
            name="delete"
            [attr.aria-label]="t('product.deleteProduct')"
            (click)="onClickDelete($event)"
          >
            <pxw-icon icon="trash-can"></pxw-icon>
          </button>
        }
        @if (sortEnabled) {
          <div class="move">
            <pxw-icon icon="cross-arrows" size="sm" color="black"></pxw-icon>
          </div>
        }
      </div>
    } @else {
      <div class="actions view-product" [ngClass]="{ inactive: withoutStock }">
        <pxw-icon icon="chevron-right" size="xs"></pxw-icon>
      </div>
    }
  </a>
</ng-container>
