import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ApiV1_Category } from '@pedix-workspace/api-v1';
import { isCategoryAvailable } from '@pedix-workspace/utils';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'appActiveCategories',
  standalone: true,
})
export class ActiveCategoriesPipe implements PipeTransform {
  transform(categories: ApiV1_Category[]): any {
    return (
      categories.filter(category => {
        if (!Array.isArray(category.products) || category.products.length === 0) {
          return false;
        }
        if (!category.active) {
          return false;
        }
        if (
          category.availability &&
          !isCategoryAvailable(category.availability) &&
          category.availability.isHidden
        ) {
          return false;
        }
        return true;
      }) || []
    );
  }
}
