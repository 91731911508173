import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Category } from '@pedix-workspace/utils';
import { ScrollPositionService } from '@pedix-workspace/angular-utils';
import { EstablishmentWorkingHoursService } from '../../main/establishment-working-hours.service';
import { CategoryAvailabilityDialogComponent } from '../category-availability-dialog/category-availability-dialog.component';
import { ApiV1_Category, ApiV1_ProductTag } from '@pedix-workspace/api-v1';
import { IsCategoryAvailablePipe } from './isCategoryAvailable.pipe';
import { ImageStoragePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { ShowOrderComponent } from '../show-order/show-order.component';
import { IconFilterComponent, IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { CategoryListItemComponent } from '@pedix-workspace/pedixapp-shared-category-list-item';
import { AsyncPipe } from '@angular/common';
import { SeoService } from '../../main/seo.service';
import { AppRequestContextService } from '../../../app-request-context.service';
import { TranslocoDirective } from '@ngneat/transloco';
import { MaxProductsDiscountPipe } from './maxProductsDiscount.pipe';
import { ActiveCategoriesPipe } from '../../../app-active-categories.pipe';
import { ActiveProductsPipe } from '../../../app-active-products.pipe';
import { getUniqueTagsWithQuantities } from '../product-tags/getUniqueTagsWithQuantities';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { ProductFiltersDialogComponent } from '../category/product-filters/product-filters-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    CategoryListItemComponent,
    IconLoadingComponent,
    ShowOrderComponent,
    CategoryAvailabilityDialogComponent,
    AsyncPipe,
    ImageStoragePipe,
    IsCategoryAvailablePipe,
    MaxProductsDiscountPipe,
    ActiveCategoriesPipe,
    ActiveProductsPipe,
    ButtonComponent,
    IconFilterComponent,
    ProductFiltersDialogComponent,
  ],
})
export class CategoriesComponent implements OnInit, AfterViewInit {
  @ViewChild('categoryList') categoryListElement: ElementRef;
  @ViewChild(CategoryAvailabilityDialogComponent)
  categoryAvailability: CategoryAvailabilityDialogComponent;
  @ViewChild(ProductFiltersDialogComponent) filtersDialog: ProductFiltersDialogComponent;

  establishmentSlug: string;
  categories: ApiV1_Category[];
  loading = false;
  lastScrollTop = 0;
  isCartDisabled = false;
  productTagsWithQuantities: { productTag: ApiV1_ProductTag; count: number }[] = [];

  get establishment() {
    return this.appRequestContext.establishment;
  }
  get selectedLanguage() {
    return this.appRequestContext.selectedLanguage;
  }

  private seoService = inject(SeoService);
  private scrollPosition = inject(ScrollPositionService);
  private appRequestContext = inject(AppRequestContextService);
  private establishmentWorkingHours = inject(EstablishmentWorkingHoursService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  ngOnInit() {
    this.seoService.setEstablishmentMetadata(this.establishment);

    this.categories = this.appRequestContext.categories;
    this.establishmentSlug = this.establishment.slug;

    this.productTagsWithQuantities = this.establishment.features.includes('PRODUCT_TAGS')
      ? getUniqueTagsWithQuantities(this.appRequestContext.products)
      : [];

    this.establishmentWorkingHours.openedStatus$
      .pipe(untilDestroyed(this))
      .subscribe(({ isCartDisabled }) => {
        this.isCartDisabled = isCartDisabled;
      });
  }

  ngAfterViewInit() {
    this.categoryListElement.nativeElement.scrollTop = this.scrollPosition.get(
      'categories',
      this.establishmentSlug,
    );
  }

  onScroll() {
    this.scrollPosition.set(
      'categories',
      this.categoryListElement.nativeElement.scrollTop,
      this.establishmentSlug,
    );
  }

  trackByCategoryId(index: number, category: Pick<Category, 'id'>) {
    return category.id;
  }

  displayAvailabilityForCategory(category: Pick<Category, 'availability'>) {
    this.categoryAvailability.open(category);
  }

  onFilterProducts() {
    this.filtersDialog.open();
  }

  onSelectedTags(selectedTags: string[]) {
    this.router.navigate(['/busqueda'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        qt: selectedTags.join(','),
      },
    });
  }
}
