<ng-container *transloco="let t">
  @switch (templateToRender) {
    @case ('cart-disabled') {
      <pxw-alert type="warning" size="sm">{{ t('global.cartDisabledWhileClosed') }}</pxw-alert>
    }
    @case ('not-available') {
      <pxw-alert
        type="warning"
        size="sm"
        (click)="categoryAvailabilityDialog.open(category)"
        class="not-available-wrapper"
      >
        <pxw-icon icon="clock" />
        <div class="not-available-content">
          {{ t('categoryAvailability.productNotAvailable') }}
          <pxw-button color="yellow" size="sm" [rounded]="true">{{
            t('categoryAvailability.checkSchedule')
          }}</pxw-button>
        </div>
      </pxw-alert>
      <app-category-availability-dialog
        #categoryAvailabilityDialog
      ></app-category-availability-dialog>
    }
    @case ('without-stock') {
      <pxw-alert type="info" size="sm">{{ t('product.withoutStock') }}</pxw-alert>
    }
    @default {
      <ng-content></ng-content>
    }
  }
</ng-container>
