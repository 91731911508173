<div
  class="ui-drawer__menu ui-drawer__menu--display-{{ display }}"
  [ngClass]="{
    'ui-drawer__menu--opened': isOpen === true,
    'ui-drawer__menu--closed': isOpen === false,
    'ui-drawer__menu--auto-open': shouldAutoOpen
  }"
  >
  <div class="ui-drawer__menu-child">
    <ng-container *ngTemplateOutlet="sideMenuTemplate"></ng-container>
  </div>
</div>
<div class="ui-drawer__content" #drawerContent>
  <div class="ui-drawer__content-child" [ngStyle]="{ 'width.px': contentWidth }">
    <ng-content></ng-content>
  </div>
  <div class="ui-drawer__overlay" (click)="toggleIsOpen(false)"></div>
</div>

@if (isOpen && display === 'tablet') {
  <pxw-modal-history
    name="side-menu"
    title="Menu"
    (popState)="toggleIsOpen(false)"
  ></pxw-modal-history>
}
