import { Component, ChangeDetectionStrategy, inject, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'pxw-horizontal-slider-item',
  templateUrl: './horizontal-slider-item.component.html',
  styleUrls: ['./horizontal-slider-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HorizontalSliderItemComponent {
  @Input() id: string;

  private rootEl = inject(ElementRef<HTMLElement>);

  get offsetLeft() {
    return this.rootEl.nativeElement?.offsetLeft;
  }
}
