import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="search"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconSearchComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.9231 20.2308C29.9231 17.5625 28.9748 15.28 27.0781 13.3834C25.1815 11.4868 22.899 10.5385 20.2308 10.5385C17.5625 10.5385 15.28 11.4868 13.3834 13.3834C11.4868 15.28 10.5385 17.5625 10.5385 20.2308C10.5385 22.899 11.4868 25.1815 13.3834 27.0781C15.28 28.9748 17.5625 29.9231 20.2308 29.9231C22.899 29.9231 25.1815 28.9748 27.0781 27.0781C28.9748 25.1815 29.9231 22.899 29.9231 20.2308ZM41 38.2308C41 38.9808 40.726 39.6298 40.1779 40.1779C39.6298 40.726 38.9808 41 38.2308 41C37.4519 41 36.8029 40.726 36.2837 40.1779L28.863 32.7788C26.2812 34.5673 23.4038 35.4615 20.2308 35.4615C18.1683 35.4615 16.1959 35.0613 14.3137 34.2608C12.4315 33.4603 10.8089 32.3786 9.44591 31.0156C8.08293 29.6526 7.0012 28.03 6.20072 26.1478C5.40024 24.2656 5 22.2933 5 20.2308C5 18.1683 5.40024 16.1959 6.20072 14.3137C7.0012 12.4315 8.08293 10.8089 9.44591 9.44591C10.8089 8.08293 12.4315 7.0012 14.3137 6.20072C16.1959 5.40024 18.1683 5 20.2308 5C22.2933 5 24.2656 5.40024 26.1478 6.20072C28.03 7.0012 29.6526 8.08293 31.0156 9.44591C32.3786 10.8089 33.4603 12.4315 34.2608 14.3137C35.0613 16.1959 35.4615 18.1683 35.4615 20.2308C35.4615 23.4038 34.5673 26.2812 32.7788 28.863L40.1995 36.2837C40.7332 36.8173 41 37.4663 41 38.2308Z"
    />
  </svg>`,
})
export class IconSearchComponent extends IconComponent {}
