import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { EstablishmentTheme } from '@pedix-workspace/utils';
import { ColorService } from './color.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;
  private document = inject(DOCUMENT);
  private colorService = inject(ColorService);

  constructor() {
    const rendererFactory = inject(RendererFactory2);

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  defaultThemeColors = {
    theme1: '#ffffff',
    theme1Contrast: '#9575cd',
    theme2: '#3B3C66',
    theme2Contrast: '#ffffff',
  };

  configureTheme(theme: EstablishmentTheme) {
    if (theme.color1) {
      const colorContrast1 =
        theme.colorContrast1 || this.colorService.getDefaultContrastColor1(theme.color1);

      this.renderer.setStyle(this.document.documentElement, `--theme1`, theme.color1, 2);
      this.renderer.setStyle(this.document.documentElement, `--theme1-contrast`, colorContrast1, 2);
    }
    if (theme.color2) {
      const colorContrast2 =
        theme.colorContrast2 || this.colorService.getDefaultContrastColor2(theme.color2);

      this.renderer.setStyle(this.document.documentElement, `--theme2`, theme.color2, 2);
      this.renderer.setStyle(this.document.documentElement, `--theme2-contrast`, colorContrast2, 2);
    }
    if (theme.color3) {
      this.renderer.setStyle(this.document.documentElement, `--yellow`, theme.color3, 2);
    }
    if (theme.color4) {
      this.renderer.setStyle(this.document.documentElement, `--cyan`, theme.color4, 2);
    }
  }

  resetTheme() {
    // NOTE: removeStyle doesn't work well on Safari, so we reassign color instead
    // IMPORTANT: these colors should match shared/styles/_colors.css vars
    this.renderer.setStyle(
      this.document.documentElement,
      `--theme1`,
      this.defaultThemeColors.theme1,
    );
    this.renderer.setStyle(
      this.document.documentElement,
      `--theme1-contrast`,
      this.defaultThemeColors.theme1Contrast,
    );
    this.renderer.setStyle(
      this.document.documentElement,
      `--theme2`,
      this.defaultThemeColors.theme2,
    );
    this.renderer.setStyle(
      this.document.documentElement,
      `--theme2-contrast`,
      this.defaultThemeColors.theme2Contrast,
    );
    this.renderer.setStyle(this.document.documentElement, `--yellow`, '#ffd082');
    this.renderer.setStyle(this.document.documentElement, `--cyan`, '#88e1f2');
  }
}
