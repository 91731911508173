import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  CurrencyConfig,
  UiFormReactiveConfigService,
} from '@pedix-workspace/angular-ui-form-reactive';
import { AppRequestContextService } from './app-request-context.service';

@Injectable({ providedIn: 'root' })
export class AppFormReactiveConfigService extends UiFormReactiveConfigService {
  private t = inject(TranslocoService);
  private appRequestContextService = inject(AppRequestContextService);

  getErrorRequiredMessage() {
    return this.t.translate('validation.required');
  }

  getErrorMinLengthMessage(min: number) {
    return this.t.translate('validation.minStringLength', { min });
  }

  getErrorMaxLengthMessage(max: number) {
    return this.t.translate('validation.maxStringLength', { max });
  }

  getErrorPatternMessage() {
    return this.t.translate('validation.pattern');
  }

  getErrorEmailMessage() {
    return this.t.translate('validation.email');
  }

  getErrorMinMessage(min: number) {
    return this.t.translate('validation.minValue', { min });
  }

  getErrorMaxMessage(max: number) {
    return this.t.translate('validation.maxValue', { max });
  }

  getMonths(): string[] {
    return [
      this.t.translate('months.1'),
      this.t.translate('months.2'),
      this.t.translate('months.3'),
      this.t.translate('months.4'),
      this.t.translate('months.5'),
      this.t.translate('months.6'),
      this.t.translate('months.7'),
      this.t.translate('months.8'),
      this.t.translate('months.9'),
      this.t.translate('months.10'),
      this.t.translate('months.11'),
      this.t.translate('months.12'),
    ];
  }

  getWeekDaysStartingOnSunday(): string[] {
    return [
      this.t.translate('dow.7'),
      this.t.translate('dow.1'),
      this.t.translate('dow.2'),
      this.t.translate('dow.3'),
      this.t.translate('dow.4'),
      this.t.translate('dow.5'),
      this.t.translate('dow.6'),
    ];
  }

  getCurrencyConfig(): CurrencyConfig {
    const currencyLocaleData = this.appRequestContextService.currencyLocaleData;

    return {
      symbol: currencyLocaleData.currencySymbol,
      decimals: currencyLocaleData.decimalsLength,
      groupSeparator: '',
      decimalSeparator: currencyLocaleData.decimalSeparator,
    };
  }
}
