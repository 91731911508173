<pxw-modal-form
  [title]="popupTitle"
  [disablePadding]="true"
  modalStyle="fullscreen"
  modalClasses="ui-popup"
  (modalOpen)="onPopupToggle($event)"
>
  @if (zoomImage) {
    <ng-template #modalContent>
      <pinch-zoom
        [auto-zoom-out]="false"
        [fullImage]="{ path: zoomImage.large, minScale: 1 }"
        [disableZoomControl]="loadingFullImage ? 'disable' : 'never'"
        zoomControl="two-buttons"
        zoomControlPosition="bottom"
        backgroundColor="transparent"
        (events)="onPinchZoomEvents($event)"
      >
        <img [src]="zoomImage.medium" class="zoom-image" [alt]="popupTitle" />
        @if (loadingFullImage) {
          <div class="ui-loader ui-loader--with-overlay ui-loader--local">
            <pxw-icon icon="loading" [spin]="true"></pxw-icon>
          </div>
        }
      </pinch-zoom>
    </ng-template>
  }
</pxw-modal-form>
