import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnInit,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { isEqual } from 'lodash-es';
import { GalleryComponent, GalleryItem, ImageItem, GalleryImageDef } from 'ng-gallery';
import { DeviceDetectorService } from 'ngx-device-detector';

import { IconLoadingComponent, IconZoomComponent } from '@pedix-workspace/angular-ui-icons';
import { NgClass } from '@angular/common';

export type GalleryImage = {
  large: string;
  medium: string;
  thumb: string;
};

@Component({
  selector: 'pxw-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconLoadingComponent, IconZoomComponent, NgClass, GalleryComponent, GalleryImageDef],
})
export class ImageGalleryComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(GalleryComponent) gallery: GalleryComponent;

  @Input() images: GalleryImage[];
  @Input() activeIndex = 0;
  @Input() zoomEnabled = false;

  @Output() imageZoom = new EventEmitter<GalleryImage | null>();
  @Output() activeIndexChange = new EventEmitter<number>();

  galleryImages: GalleryItem[];
  isDesktop: boolean;

  private deviceDetector = inject(DeviceDetectorService);

  constructor() {}

  ngOnInit(): void {
    this.isDesktop = this.deviceDetector.isDesktop();
  }

  ngAfterViewInit(): void {
    this.gallery.galleryRef.indexChanged.subscribe(state => {
      this.activeIndexChange.emit(state.currIndex || 0);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isEqual(changes['images']?.currentValue, changes['images']?.previousValue)) {
      this.galleryImages = changes['images']?.currentValue.map(
        (image: GalleryImage) =>
          new ImageItem({
            src: image.medium,
            ...image,
          }),
      );
    }
  }

  toggleImagePopup(imageIndex: number) {
    this.imageZoom.emit((this.galleryImages[imageIndex]?.data as GalleryImage) || null);
  }

  openImagePopup() {
    this.toggleImagePopup(this.activeIndex);
  }

  onGalleryItemClick(imageIndex: number) {
    this.toggleImagePopup(imageIndex);
  }
}
