import { Injectable, inject } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { InstagramIssueConfig } from '@pedix-workspace/utils';

@Injectable({
  providedIn: 'root',
})
export class InstagramIssueService {
  private firestore = inject(Firestore);
  private deviceService = inject(DeviceDetectorService);

  async shouldShowInstagramWarning(): Promise<boolean> {
    try {
      const isInstagram = this.deviceService.userAgent.includes('Instagram');
      if (!isInstagram) return false;

      const configDoc = await getDoc(doc(this.firestore, 'remoteConfig/instagram-issue'));
      if (!configDoc.exists()) return false;

      const config = configDoc.data() as InstagramIssueConfig;
      const isAndroid = this.deviceService.os === OS.ANDROID;
      const isIOS = this.deviceService.os === OS.IOS;

      return (isAndroid && config.android) || (isIOS && config.ios);
    } catch (error) {
      console.error('Error checking Instagram warning:', error);
      return false;
    }
  }

  get isAndroid(): boolean {
    return this.deviceService.os === OS.ANDROID;
  }
}
