/* eslint-disable no-underscore-dangle */
import { PathLocationStrategy, PlatformLocation } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { AppRequestContextService } from './app-request-context.service';


@Injectable()
export class AppPathLocationStrategy extends PathLocationStrategy {

  private platformLocation = inject(PlatformLocation);
  private appRequestContextService = inject(AppRequestContextService);


  getBaseHref() {
    // If source is path, then we prepend the slug to base path
    if (this.appRequestContextService.source === 'path') {
      return this.appRequestContextService.slug;
    }

    // Otherwise, base path should be empty
    return '';
  }

  prepareExternalUrl(internal: string): string {
    if (this.appRequestContextService.source === 'path') {
      return this.joinWithSlash(this.appRequestContextService.slug, internal);
    }
    return internal;
  }

  path(includeHash: boolean = false): string {
    const slugPrefixUrlPath = `/${this.appRequestContextService.slug}`;

    let pathname =
      this.platformLocation.pathname + this.normalizeQueryParams(this.platformLocation.search);

    if (pathname.startsWith(slugPrefixUrlPath)) {
      pathname = pathname.substr(slugPrefixUrlPath.length);
    }

    const hash = this.platformLocation.hash;
    return hash && includeHash ? `${pathname}${hash}` : pathname;
  }

  pushState(state: any, title: string, url: string, queryParams: string) {
    const externalUrl = this.prepareExternalUrl(url + this.normalizeQueryParams(queryParams));
    this.platformLocation.pushState(state, title, externalUrl);
  }

  replaceState(state: any, title: string, url: string, queryParams: string) {
    const externalUrl = this.prepareExternalUrl(url + this.normalizeQueryParams(queryParams));
    this.platformLocation.replaceState(state, title, externalUrl);
  }

  private joinWithSlash(start: string, end: string): string {
    if (start.length === 0) {
      return end;
    }
    if (end.length === 0) {
      return start;
    }
    let slashes = 0;
    if (start.endsWith('/')) {
      slashes++;
    }
    if (end.startsWith('/')) {
      slashes++;
    }
    if (slashes === 2) {
      return start + end.substring(1);
    }
    if (slashes === 1) {
      return start + end;
    }
    return start + '/' + end;
  }

  private normalizeQueryParams(params: string): string {
    return params && params[0] !== '?' ? '?' + params : params;
  }
}
