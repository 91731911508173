import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="phone"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconPhoneComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.0015 30.585L32.1015 27.4838C32.519 27.0712 33.0473 26.7889 33.6221 26.6709C34.197 26.5529 34.7937 26.6045 35.3398 26.8192L39.1179 28.3283C39.6698 28.5524 40.143 28.9349 40.478 29.4277C40.8129 29.9204 40.9946 30.5014 41 31.0972V38.0195C40.9968 38.4249 40.9116 38.8254 40.7496 39.1969C40.5876 39.5685 40.3521 39.9034 40.0573 40.1815C39.7625 40.4596 39.4145 40.6751 39.0343 40.8151C38.654 40.9551 38.2494 41.0167 37.8447 40.9961C11.3706 39.3486 6.02868 16.9203 5.01843 8.33662C4.97153 7.9151 5.01438 7.48842 5.14416 7.08466C5.27393 6.6809 5.48769 6.3092 5.77137 5.99401C6.05505 5.67883 6.40222 5.42731 6.79005 5.25599C7.17788 5.08468 7.59756 4.99745 8.02151 5.00006H14.7058C15.3023 5.00182 15.8846 5.18207 16.3778 5.51761C16.8711 5.85315 17.2527 6.32864 17.4736 6.88293L18.982 10.6625C19.2038 11.2067 19.2604 11.8042 19.1447 12.3804C19.029 12.9565 18.7462 13.4858 18.3316 13.9022L15.2317 17.0034C15.2317 17.0034 17.0169 29.0897 29.0015 30.585Z"
    />
  </svg>`,
})
export class IconPhoneComponent extends IconComponent {}
