import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import clsx from 'clsx';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pxw-product-tag',
  templateUrl: './product-tag.component.html',
  styleUrls: ['./product-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class ProductTagComponent {
  @Input() tag: string;
  @Input() color: string;
  @Input() count: number;
  @Input() size: 'xs' | 'sm';

  @HostBinding('class')
  get classNames() {
    return clsx(`size-${this.size}`);
  }

  @HostBinding('style.background')
  get background() {
    return this.color;
  }
}
