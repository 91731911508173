import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { RESPONSE } from '../express.tokens';
import { EnvironmentService } from '@pedix-workspace/angular-utils';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppEnvironmentService extends EnvironmentService {
  private platformId = inject(PLATFORM_ID);
  private response = inject(RESPONSE, { optional: true });
  
  constructor() {
    super();

    const devPort = '4200';
    const baseUrl = isPlatformServer(this.platformId) ? this.response.locals['publicUrl'] : location.href;
    const isLocalEnv = new URL(baseUrl).port === devPort;

    this.env = environment.production ? 'production' : 'development';
    this.siteUrl = isLocalEnv ? 'http://pedix.local:4200' : environment.siteUrl;
    this.isAdminApp = false;
  }
}
