<ng-container *transloco="let t">
  <section class="content-subsidiaries">
    <div class="ui-title">
      {{ t('subsidiaries.sectionTitle') }}

      <pxw-input-switch
        name="orderByNearest"
        [legend]="t('subsidiaries.orderByNearest')"
        [ngModel]="(orderBy$ | async) === 'nearest' ? true : false"
        (ngModelChange)="$event ? onOrderByNearest() : onOrderByCity()"
      />
    </div>
    @if (!loading) {
      @if ((orderBy$ | async) === 'city') {
        <div
          #scrollTarget
          class="subsidiaries-wrapper"
          [ngClass]="{ 'with-headings': displayCitiesHeading }"
        >
          @if (isBrowser && displayCitiesHeading) {
            <pxw-sticky-heading
              [scrollTarget]="scrollTarget"
              [targetElements]="stickyHeadings | pxwViewChildren | async"
            ></pxw-sticky-heading>
          }
          @for (group of subsidiariesByCity; track group) {
            @if (displayCitiesHeading) {
              <h2 class="group-heading" #cityHeading>
                <span class="title">
                  <pxw-icon icon="building" color="blue" size="sm"></pxw-icon>{{ group.city }}
                </span>
              </h2>
            }
            <div class="grid-subsidiaries">
              @for (subsidiary of group.subsidiaries; track subsidiary) {
                <app-subsidiary [subsidiary]="subsidiary"></app-subsidiary>
              }
            </div>
          }
        </div>
      } @else {
        <div class="subsidiaries-wrapper">
          <div class="grid-subsidiaries">
            @for (subsidiary of nearestSubsidiaries; track subsidiary) {
              <app-subsidiary
                [subsidiary]="subsidiary"
                [distance]="subsidiariesDistances[subsidiary.slug]"
              ></app-subsidiary>
            }
          </div>
        </div>
      }
    } @else {
      <div class="ui-loader">
        <pxw-icon icon="loading" [spin]="true"></pxw-icon>
      </div>
    }
    <pxw-modal-dialog #geolocationErrorDialog icon="alert" [title]="t('error.title')" type="alert">
      <p>{{ t('subsidiaries.geolocationError') }}</p>
    </pxw-modal-dialog>
  </section>
</ng-container>
