import { Routes } from '@angular/router';
import { ResumeComponent } from './resume/resume.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryComponent } from './category/category.component';
import { ProductComponent } from './product/product.component';
import { CategoryExistsGuard } from './category/category-exists.guard';
import { ProductExistsGuard } from './product/product-exists.guard';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ProductsByTagComponent } from './product-tags/products-by-tag/products-by-tag.component';

const establishmentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: CategoriesComponent,
      },
      {
        path: 'categoria/:categoryId',
        component: CategoryComponent,
        canActivate: [CategoryExistsGuard],
      },
      // NOTE: deprecated route, redirects to /categoria/:categoryId/producto/:productId
      // FIXME: after upgrading to Angular 18, the redirection could be done with a redirectTo function
      {
        path: 'producto/:productId',
        canActivate: [ProductExistsGuard],
        children: [],
      },
      {
        path: 'categoria/:categoryId/producto/:productId',
        component: ProductComponent,
        canActivate: [ProductExistsGuard],
      },
      {
        path: 'busqueda',
        component: SearchResultsComponent,
      },
      {
        path: 'productos-relacionados',
        component: ProductsByTagComponent,
      },
      {
        path: 'resumen',
        component: ResumeComponent,
      },
      {
        path: 'resumen/edicion/:cartItemId',
        component: ProductComponent,
      },

      // --- Catch All ---
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

export default establishmentRoutes;
