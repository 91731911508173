<ng-container *transloco="let t">
  <pxw-modal-dialog
    #availabilityDialog
    [title]="t('categoryAvailability.dialogTitle')"
    type="alert"
  >
    <pxw-icon icon="info" />
    <ng-template #modalContent>
      @if (daysOfWeekValue) {
        <p>
          <b>{{ t('global.days') }}:</b> {{ daysOfWeekValue }}
        </p>
      }
      @if (timeRangeValue) {
        <p>
          <b>{{ t('global.timetable') }}:</b> {{ timeRangeValue }}
        </p>
      }
    </ng-template>
  </pxw-modal-dialog>
</ng-container>
