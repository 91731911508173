@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    [attr.for]="inputId"
  >
    {{ label }}
  </label>
}

<div class="ui-input__editor">
  <div
    class="ui-input__switch"
    [ngClass]="{ 'ui-input__switch--active': isChecked, 'ui-input__switch--disabled': isDisabled }"
    (click)="onToggle($event)"
  >
    <input
      #input
      class="ui-input__switch-input ui-input__switch-toggle"
      type="checkbox"
      [checked]="isChecked"
      [value]="isChecked ? 'on' : 'off'"
    />
  </div>

  @if (legend) {
    <label
      (click)="onToggle($event)"
      [attr.for]="inputId"
      class="ui-input__checkbox-legend"
      [ngClass]="{ 'ui-input__checkbox-legend--read-only': isDisabled }"
    >
      {{ legend }}
    </label>
  }
</div>

@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<ng-content></ng-content>
