import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppSearchResultsService } from '../../../app-search-results.service';
import {
  IconBackComponent,
  IconClockComponent,
  IconFilterComponent,
} from '@pedix-workspace/angular-ui-icons';
import { ZonelessEventDirective } from '@pedix-workspace/angular-utils';
import {
  ItemListComponent,
  ItemListGroupProductsByCategoryPipe,
  ProductListItemComponent,
} from '@pedix-workspace/pedixapp-shared-catalog';
import { ImageStoragePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { IsCategoryAvailablePipe } from '../categories/isCategoryAvailable.pipe';
import { AppRequestContextService } from '../../../app-request-context.service';
import { TranslocoDirective } from '@ngneat/transloco';
import { ApiV1_Category, ApiV1_Product, ApiV1_ProductTag } from '@pedix-workspace/api-v1';
import { AppLayoutComponent } from '../../main/app-layout/app-layout.component';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { CategoryAvailabilityDialogComponent } from '../category-availability-dialog/category-availability-dialog.component';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { ProductFiltersDialogComponent } from '../category/product-filters/product-filters-dialog.component';
import { BehaviorSubject, filter, map, switchMap } from 'rxjs';
import { getUniqueTagsWithQuantities } from '../product-tags/getUniqueTagsWithQuantities';

@UntilDestroy()
@Component({
  selector: 'app-search-results',
  standalone: true,
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
  imports: [
    CommonModule,
    IconBackComponent,
    IconClockComponent,
    ButtonComponent,
    RouterLink,
    ZonelessEventDirective,
    ProductListItemComponent,
    ImageStoragePipe,
    TranslocoDirective,
    IsCategoryAvailablePipe,
    AlertComponent,
    CategoryAvailabilityDialogComponent,
    ItemListComponent,
    ProductFiltersDialogComponent,
    IconFilterComponent,
    ItemListGroupProductsByCategoryPipe,
  ],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private searchResultsService = inject(AppSearchResultsService);
  private appRequestContextService = inject(AppRequestContextService);
  private appLayoutComponentRef = inject(AppLayoutComponent, { optional: true });

  @ViewChild(ProductFiltersDialogComponent) filtersDialog: ProductFiltersDialogComponent;

  searchText: string;
  searchTags: string;
  searchResults: ApiV1_Product[] = [];
  categories: ApiV1_Category[];
  selectedCategoryId: string;
  productTagsWithQuantities: { productTag: ApiV1_ProductTag; count: number }[] = [];

  private selectedTagIds$ = new BehaviorSubject<string[]>([]);

  get currencySymbol() {
    return this.appRequestContextService.currencyLocaleData.currencySymbol;
  }

  get selectedTagIds(): string[] {
    return this.selectedTagIds$.value;
  }

  get displaySku(): boolean {
    return this.appRequestContextService.shouldDisplaySku;
  }

  ngOnInit(): void {
    this.categories = this.appRequestContextService.categories;

    this.activatedRoute.queryParams
      .pipe(
        filter(queryParams => {
          if (!queryParams.q && !queryParams.qt) {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            return false;
          }
          if (queryParams.qt) {
            this.selectedTagIds$.next(queryParams.qt.split(','));
          } else {
            this.selectedTagIds$.next([]);
          }

          return true;
        }),
        switchMap(queryParams => {
          this.searchText = queryParams.q;
          this.searchTags = queryParams.qt;

          return this.searchResultsService.getSearchResults(this.searchText, {
            selectedLanguage: this.appRequestContextService.selectedLanguage,
            displaySku: this.displaySku,
          });
        }),
        switchMap(searchResults => {
          this.productTagsWithQuantities = getUniqueTagsWithQuantities(searchResults);

          return this.selectedTagIds$.pipe(
            map(selectedTagIds =>
              this.searchResultsService.getFilteredResults(searchResults, {
                productTagIds: selectedTagIds,
              }),
            ),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe(filteredResults => {
        this.searchResults = filteredResults;
      });
  }

  ngOnDestroy(): void {
    if (this.appLayoutComponentRef) {
      this.appLayoutComponentRef.searchText = '';
    }
  }

  onFilterProducts() {
    this.filtersDialog.open();
  }

  onSelectedTags(selectedTags: string[]) {
    this.selectedTagIds$.next(selectedTags);
  }
}
