import { Pipe, PipeTransform, inject } from '@angular/core';
import { Product } from '@pedix-workspace/utils';
import { StockService } from './stock.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'pxwStockIsOutOfStock',
  standalone: true,
})
export class StockIsOutOfStockPipe implements PipeTransform {
  private stockService = inject(StockService);

  constructor() {}

  transform(product: Pick<Product, 'id' | 'presentations'>): Observable<boolean> {
    return this.stockService.isProductOutOfStock$(product);
  }
}
