import { DatePipe, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { TemporaryClosure, WorkingDays } from '@pedix-workspace/utils';
import { WorkingHoursMessageDetailsComponent } from '@pedix-workspace/pedixapp-shared-working-hours';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { IconInfoComponent } from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'app-working-hours-message',
  templateUrl: './working-hours-message.component.html',
  styleUrls: ['./working-hours-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
  standalone: true,
  imports: [
    TranslocoDirective,
    ModalDialogComponent,
    WorkingHoursMessageDetailsComponent,
    IconInfoComponent,
  ],
})
export class WorkingHoursMessageComponent {
  @ViewChild('workingHoursDialog') workingHoursDialog;

  @Input() isClosed: boolean;
  @Input() isAboutToClose: boolean;
  @Input() isTemporaryClosed: boolean;
  @Input() workingDays: WorkingDays;
  @Input() temporaryClosure: TemporaryClosure;

  get isSsr() {
    return isPlatformServer(this.platformId);
  }

  get temporaryClosureMessage() {
    if (this.temporaryClosure?.message) {
      return this.temporaryClosure.message;
    }
    if (this.temporaryClosure?.to) {
      const returnDate = this.datePipe.transform(this.temporaryClosure?.to, 'dd/MM/yy');

      return this.t.translate('workingHours.temporaryClosedDescription', { returnDate });
    }
    return '';
  }

  private platformId = inject(PLATFORM_ID);

  private datePipe = inject(DatePipe);
  private t = inject(TranslocoService);

  constructor() {}

  displayWorkingHours() {
    this.workingHoursDialog.open();
  }
}
