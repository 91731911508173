import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="web"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconWebComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.13 26C30.27 24.845 30.375 23.69 30.375 22.5C30.375 21.31 30.27 20.155 30.13 19H36.045C36.325 20.12 36.5 21.2925 36.5 22.5C36.5 23.7075 36.325 24.88 36.045 26M27.0325 35.73C28.0825 33.7875 28.8875 31.6875 29.4475 29.5H34.61C32.9145 32.4195 30.2247 34.631 27.0325 35.73ZM26.595 26H18.405C18.23 24.845 18.125 23.69 18.125 22.5C18.125 21.31 18.23 20.1375 18.405 19H26.595C26.7525 20.1375 26.875 21.31 26.875 22.5C26.875 23.69 26.7525 24.845 26.595 26ZM22.5 36.43C21.0475 34.33 19.875 32.0025 19.1575 29.5H25.8425C25.125 32.0025 23.9525 34.33 22.5 36.43ZM15.5 15.5H10.39C12.068 12.5726 14.7559 10.3576 17.95 9.27C16.9 11.2125 16.1125 13.3125 15.5 15.5ZM10.39 29.5H15.5C16.1125 31.6875 16.9 33.7875 17.95 35.73C14.7626 34.6304 12.0785 32.4185 10.39 29.5ZM8.955 26C8.675 24.88 8.5 23.7075 8.5 22.5C8.5 21.2925 8.675 20.12 8.955 19H14.87C14.73 20.155 14.625 21.31 14.625 22.5C14.625 23.69 14.73 24.845 14.87 26M22.5 8.5525C23.9525 10.6525 25.125 12.9975 25.8425 15.5H19.1575C19.875 12.9975 21.0475 10.6525 22.5 8.5525ZM34.61 15.5H29.4475C28.8998 13.3326 28.0888 11.2403 27.0325 9.27C30.2525 10.3725 32.93 12.595 34.61 15.5ZM22.5 5C12.8225 5 5 12.875 5 22.5C5 27.1413 6.84374 31.5925 10.1256 34.8744C11.7507 36.4994 13.6798 37.7884 15.803 38.6679C17.9262 39.5474 20.2019 40 22.5 40C27.1413 40 31.5925 38.1563 34.8744 34.8744C38.1563 31.5925 40 27.1413 40 22.5C40 20.2019 39.5474 17.9262 38.6679 15.803C37.7884 13.6798 36.4994 11.7507 34.8744 10.1256C33.2493 8.50061 31.3202 7.21157 29.197 6.33211C27.0738 5.45265 24.7981 5 22.5 5Z"
    />
  </svg>`,
})
export class IconWebComponent extends IconComponent {}
