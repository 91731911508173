import {
  Component,
  ElementRef,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
  makeStateKey,
} from '@angular/core';
import {
  GeolocationResult,
  GeolocationService,
  getDistanceFromLatLonInKm,
} from '@pedix-workspace/angular-ui-map';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UniversalHelpersService } from '../../main/universal-helpers.service';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { isPlatformBrowser, NgClass, AsyncPipe } from '@angular/common';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { ApiV1_Subsidiary } from '@pedix-workspace/api-v1';
import { ViewChildrenPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { SubsidiaryComponent } from './subsidiary/subsidiary.component';
import { IconBuildingComponent, IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { StickyHeadingComponent } from '@pedix-workspace/angular-ui-sticky-heading';
import { AppRequestContextService } from '../../../app-request-context.service';
import { InputSwitchComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { FormsModule } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-subsidiaries',
  templateUrl: './subsidiaries.component.html',
  styleUrls: ['./subsidiaries.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    StickyHeadingComponent,
    IconBuildingComponent,
    IconLoadingComponent,
    SubsidiaryComponent,
    ModalDialogComponent,
    AsyncPipe,
    ViewChildrenPipe,
    InputSwitchComponent,
    FormsModule,
  ],
})
export class SubsidiariesComponent implements OnInit {
  @ViewChildren('cityHeading') stickyHeadings: QueryList<ElementRef>;
  @ViewChild('geolocationErrorDialog') geolocationErrorDialog: ModalDialogComponent;

  loading = true;
  loadingButton = false;
  subsidiariesByCity: { city: string; subsidiaries: ApiV1_Subsidiary[] }[] = [];
  nearestSubsidiaries: ApiV1_Subsidiary[] = [];
  orderBy$ = new BehaviorSubject<'city' | 'nearest'>('city');
  geolocationResult$ = new BehaviorSubject<GeolocationResult | null>(null);
  subsidiariesDistances: Record<string, number> = {};
  isBrowser = false;
  displayCitiesHeading = false;

  private platformId = inject(PLATFORM_ID);

  private geolocationService = inject(GeolocationService);
  private universalHelpers = inject(UniversalHelpersService);
  private t = inject(TranslocoService);
  private analyticsService = inject(AnalyticsService);
  private appRequestContext = inject(AppRequestContextService);

  constructor() {}

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    const subsidiaries = this.appRequestContext.subsidiaries;

    combineLatest([this.orderBy$, this.geolocationResult$])
      .pipe(
        this.universalHelpers.untilCompleteOrTimeout(),
        this.universalHelpers.addOrRestoreFromCache(makeStateKey('subsidiaries')),
        untilDestroyed(this),
      )
      .subscribe(([sortBy, geolocationResult]) => {
        this.loading = false;

        if (sortBy === 'city') {
          this.sortSubsidiariesByCity(subsidiaries);
        } else if (sortBy === 'nearest' && geolocationResult) {
          this.sortSubsidiariesByNearest(subsidiaries);
        }
      });
  }

  sortSubsidiariesByCity(subsidiaries: ApiV1_Subsidiary[]) {
    const subsidiariesByCityMap: Record<string, ApiV1_Subsidiary[]> = {};
    const otherSubsidiaries = [];

    this.subsidiariesByCity = [];

    subsidiaries.forEach(subsidiary => {
      if (subsidiary.addressDetails) {
        const city = `${subsidiary.addressDetails.state} - ${subsidiary.addressDetails.locality}`;

        if (!subsidiariesByCityMap[city]) {
          subsidiariesByCityMap[city] = [];
        }
        subsidiariesByCityMap[city].push(subsidiary);
      } else {
        otherSubsidiaries.push(subsidiary);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    Object.entries(subsidiariesByCityMap).forEach(([city, subsidiaries]) => {
      this.subsidiariesByCity.push({ city, subsidiaries });
    });

    for (let i = 0; i < this.subsidiariesByCity.length; i++) {
      for (let j = i + 1; j < this.subsidiariesByCity.length; j++) {
        if (
          this.subsidiariesByCity[j].subsidiaries[0].addressDetails?.state ===
          this.subsidiariesByCity[i].subsidiaries[0].addressDetails?.state
        ) {
          const subsidiaryToMove = this.subsidiariesByCity[j];

          this.subsidiariesByCity.splice(j, 1);
          this.subsidiariesByCity.splice(i + 1, 0, subsidiaryToMove);

          break;
        }
      }
    }

    this.displayCitiesHeading = this.subsidiariesByCity.length > 0;

    if (otherSubsidiaries.length > 0) {
      this.subsidiariesByCity.push({
        city: this.t.translate('subsidiaries.otherBranches'),
        subsidiaries: otherSubsidiaries,
      });
    }
  }

  async sortSubsidiariesByNearest(subsidiaries: ApiV1_Subsidiary[]) {
    this.subsidiariesDistances = {};
    this.displayCitiesHeading = false;

    subsidiaries.forEach(subsidiarie => {
      const subsidiarieCoordinates = subsidiarie?.addressDetails?.coordinates;
      const distance = subsidiarieCoordinates
        ? getDistanceFromLatLonInKm(this.geolocationResult$.value, subsidiarieCoordinates)
        : Number.MAX_SAFE_INTEGER;

      this.subsidiariesDistances[subsidiarie.slug] = distance;
    });

    this.nearestSubsidiaries = subsidiaries.slice(0).sort((subsidiarieA, subsidiarieB) => {
      return (
        this.subsidiariesDistances[subsidiarieA.slug] -
        this.subsidiariesDistances[subsidiarieB.slug]
      );
    });
  }

  onOrderByCity() {
    this.orderBy$.next('city');
    this.analyticsService.logEvent('subsidiaries_orderby_clicked', {
      subsidiaries_order_by: 'city',
    });
  }

  async onOrderByNearest() {
    this.orderBy$.next('nearest');

    this.analyticsService.logEvent('subsidiaries_orderby_clicked', {
      subsidiaries_order_by: 'nearest',
    });

    this.loading = true;
    const geolocationResult = await this.geolocationService.geolocate();
    this.loading = false;

    if (geolocationResult) {
      this.geolocationResult$.next(geolocationResult);
    } else {
      this.analyticsService.logEvent('subsidiaries_orderby_error');
      await this.geolocationErrorDialog.open().catch();

      this.orderBy$.next('city');
    }
  }
}
