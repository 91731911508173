<gallery
  class="gallery"
  [ngClass]="{ 'zoom-enabled': zoomEnabled }"
  [items]="galleryImages"
  [dots]="galleryImages.length > 1"
  [counter]="false"
  [thumb]="false"
  [nav]="isDesktop"
  [dotsSize]="20"
  (itemClick)="onGalleryItemClick($event)"
>
  <ng-container *galleryImageDef="let item; let active = active">
    <div class="ui-loader ui-loader--local">
      <pxw-icon icon="loading" [spin]="true"></pxw-icon>
    </div>
  </ng-container>
</gallery>

@if (zoomEnabled) {
  <pxw-icon icon="zoom" class="zoom-icon" (click)="openImagePopup()"></pxw-icon>
}
