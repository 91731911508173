import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ApiV1_Product } from '@pedix-workspace/api-v1';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'appActiveProducts',
  standalone: true,
})
export class ActiveProductsPipe implements PipeTransform {
  transform(products: ApiV1_Product[]): any {
    return products.filter(product => product.active);
  }
}
