import { Injectable, inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppTranslationsGuard implements CanActivate {
  private t = inject(TranslocoService);
  
  canActivate() {
    return this.t.selectTranslation()
      .pipe(map(() => true));
  }
}