import { Injectable, inject } from '@angular/core';
import { TranslocoLoader } from '@ngneat/transloco';

import { of } from 'rxjs';
import { AppRequestContext, AppRequestContextService } from '../app-request-context.service';
import { environment } from '../../environments/environment';

export type Translations = AppRequestContext['translations']['esMx'];

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private appRequestContextService = inject(AppRequestContextService);

  getTranslation(lang: string) {
    // If the running context isE2E, then we won't load translations
    if (environment.isE2E) {
      return of({ e2e: 'true ' });
    }

    return of(this.appRequestContextService.translations);
  }
}
