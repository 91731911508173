import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="share"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconShareComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M33.5 26C35.5781 26 37.3477 26.7305 38.8086 28.1914C40.2695 29.6523 41 31.4219 41 33.5C41 35.5781 40.2695 37.3477 38.8086 38.8086C37.3477 40.2695 35.5781 41 33.5 41C31.4219 41 29.6523 40.2695 28.1914 38.8086C26.7305 37.3477 26 35.5781 26 33.5C26 33.3125 26.0156 33.0469 26.0469 32.7031L17.6094 28.4844C16.1719 29.8281 14.4688 30.5 12.5 30.5C10.4219 30.5 8.65234 29.7695 7.19141 28.3086C5.73047 26.8477 5 25.0781 5 23C5 20.9219 5.73047 19.1523 7.19141 17.6914C8.65234 16.2305 10.4219 15.5 12.5 15.5C14.4688 15.5 16.1719 16.1719 17.6094 17.5156L26.0469 13.2969C26.0156 12.9531 26 12.6875 26 12.5C26 10.4219 26.7305 8.65234 28.1914 7.19141C29.6523 5.73047 31.4219 5 33.5 5C35.5781 5 37.3477 5.73047 38.8086 7.19141C40.2695 8.65234 41 10.4219 41 12.5C41 14.5781 40.2695 16.3477 38.8086 17.8086C37.3477 19.2695 35.5781 20 33.5 20C31.5312 20 29.8281 19.3281 28.3906 17.9844L19.9531 22.2031C19.9844 22.5469 20 22.8125 20 23C20 23.1875 19.9844 23.4531 19.9531 23.7969L28.3906 28.0156C29.8281 26.6719 31.5312 26 33.5 26Z"
    />
  </svg>`,
})
export class IconShareComponent extends IconComponent {}
