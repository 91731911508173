import { Injectable } from '@angular/core';

export type GeolocationResult = {
  lat: number;
  lng: number;
} | null;

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  geolocate(): Promise<GeolocationResult> {
    return new Promise(resolve => {
      if (!navigator.geolocation) {
        resolve(null);
      }
      navigator.geolocation.getCurrentPosition(
        position => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
        error => {
          console.error(error);

          resolve(null);
        },
        { timeout: 5000 },
      );
    });
  }
}
