import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { Category, formatDaysOfWeek, formatHour } from '@pedix-workspace/utils';

import { TranslocoDirective } from '@ngneat/transloco';
import { IconInfoComponent } from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'app-category-availability-dialog',
  templateUrl: './category-availability-dialog.component.html',
  styleUrls: ['./category-availability-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, ModalDialogComponent, IconInfoComponent],
})
export class CategoryAvailabilityDialogComponent {
  @ViewChild('availabilityDialog') availabilityDialog: ModalDialogComponent;

  selectedCategory: Pick<Category, 'availability'>;
  daysOfWeekValue: string | null;
  timeRangeValue: string | null;

  constructor() {}

  open(category: Pick<Category, 'availability'>) {
    this.selectedCategory = category;

    const { daysOfWeek, timeRange } = category.availability;

    if (daysOfWeek?.length > 0) {
      this.daysOfWeekValue = formatDaysOfWeek(daysOfWeek);
    } else {
      this.daysOfWeekValue = null;
    }

    if (timeRange?.from && timeRange?.to) {
      this.timeRangeValue = `de ${formatHour(timeRange.from)} a ${formatHour(timeRange.to)}`;
    } else {
      this.timeRangeValue = null;
    }

    this.availabilityDialog.open();
  }
}
