<ng-container *transloco="let t">
  @if (isTemporaryClosed) {
    <div class="closed-message">
      <span class="icon">⛔</span>
      <p class="text-details">
        {{ t('workingHours.closedMessageIntro') }}
        <ng-container></ng-container>
        @if (temporaryClosureMessage) {
          <br />
          <span class="subtitle">{{ temporaryClosureMessage }}</span>
        }
      </p>
    </div>
  } @else {
    @if (isClosed || isAboutToClose) {
      <button class="closed-message" (click)="displayWorkingHours()">
        @if (isAboutToClose) {
          <span class="icon">🕓</span>
          <p class="text-details">
            {{ t('workingHours.aboutToCloseMessageIntro') }}
            <br />
            <span class="subtitle">{{ t('workingHours.closedMessageSubtitle') }}</span>
          </p>
        } @else {
          <span class="icon">😴</span>
          <p class="text-details">
            {{ t('workingHours.closedMessageIntro') }}
            <br />
            <span class="subtitle">{{ t('workingHours.closedMessageSubtitle') }}</span>
          </p>
        }
      </button>
    }
  }

  @if ((isClosed || isAboutToClose) && !isSsr) {
    <pxw-modal-dialog #workingHoursDialog [title]="t('workingHours.dialogTitle')" type="alert">
      <pxw-icon icon="info" />
      @if (workingDays?.isActive) {
        <pxw-working-hours-message-details
          [workingDays]="workingDays"
        ></pxw-working-hours-message-details>
      }
    </pxw-modal-dialog>
  }
</ng-container>
