import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  inject,
  HostBinding,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import clsx from 'clsx';

import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import {
  AdminPanelHeaderNavigation,
  AVAILABLE_LANGUAGES,
  SUPPORTED_LANGUAGES,
} from '@pedix-workspace/utils';
import { ApiV1_Establishment, ApiV1_Product } from '@pedix-workspace/api-v1';
import { ImageStoragePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { RouterLink } from '@angular/router';
import {
  IconCheckoutComponent,
  IconCloseComponent,
  IconMenuComponent,
  IconSearchComponent,
} from '@pedix-workspace/angular-ui-icons';

import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { InputTextComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { FormsModule } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    IconMenuComponent,
    IconCheckoutComponent,
    IconSearchComponent,
    IconCloseComponent,
    RouterLink,
    ButtonComponent,
    ImageStoragePipe,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdownToggle,
    InputTextComponent,
    FormsModule,
  ],
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild('searchInput') searchInput: InputTextComponent;

  @Input() establishment: ApiV1_Establishment;
  @Input() hasCartItems: boolean;
  @Input() displayAdminButton: boolean;
  @Input() selectedLanguage: SUPPORTED_LANGUAGES;
  @Input() availableLanguages: AVAILABLE_LANGUAGES;
  @Input() searchText: string;

  @Output() clickLateralMenu = new EventEmitter();
  @Output() languageSelected = new EventEmitter<SUPPORTED_LANGUAGES>();
  @Output() searchTextChange = new EventEmitter<string>();

  searchEnabled = false;
  filteredProducts: ApiV1_Product[] = [];

  private analyticsService = inject(AnalyticsService);

  @HostBinding('class')
  get classes() {
    return clsx({
      'search-enabled': this.searchEnabled,
    });
  }

  get hasSubsidiaries() {
    return this.establishment?.subsidiaries?.length > 0;
  }

  get establishmentName() {
    return this.establishment.name;
  }

  get establishmentLogo() {
    return this.establishment.logo;
  }

  get adminUrl() {
    return environment.adminUrl;
  }

  ngOnInit(): void {
    if (this.searchText) {
      this.onSearchOpen();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['searchText']?.currentValue === '' &&
      changes['searchText']?.currentValue !== changes['searchText']?.previousValue
    ) {
      this.onSearchClose();
    }
  }

  onClickLateralMenu($event) {
    this.clickLateralMenu.emit($event);
  }

  clickOption(header_menu: AdminPanelHeaderNavigation) {
    this.analyticsService.logEvent('adminpanel_headeroptions_clicked', { header_menu });
  }

  onSearchOpen() {
    this.searchEnabled = true;

    setTimeout(() => {
      this.searchInput.focus();
    });
  }

  onSearchClose() {
    this.searchEnabled = false;

    this.searchTextChange.emit('');
  }

  onSearchKeyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.searchTextChange.emit(this.searchInput.value);
    } else if ($event.key === 'Escape') {
      this.searchInput.writeValue('');
      this.searchInput.input?.nativeElement?.blur();
    }
  }

  onSearchFocusOut() {
    this.searchTextChange.emit(this.searchInput.value);

    if (!this.searchInput.value) {
      this.onSearchClose();
    }
  }
}
