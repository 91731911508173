/**
 * Calculates precision of number
 *
 * @param {number} number Number to be checked
 *
 * @returns {number} Precision of the number
 */
function precision(number) {
  const _n = +number;
  if (Number.isNaN(_n)) return NaN;
  const sciRe = /^[+-]?\d+(?:\.(\d+))?e([+-]?\d+)$/;
  const numRe = /^[+-]?\d+(?:\.(\d+))?$/;
  const zerosRe = /^[+-]?\d+?(0+)$/;
  const _s = _n.toString();
  if (zerosRe.test(_s)) {
    const group = _s.match(zerosRe)?.[1];
    return group ? -group.length : 0;
  }
  if (numRe.test(_s)) {
    const group = _s.match(numRe)?.[1];
    return group ? group.length : 0;
  }
  if (sciRe.test(_s)) {
    const [, numGroup = '', expGroup = 0] = Array.from(_s.match(sciRe) || []);
    return numGroup.length - +expGroup;
  }
  return 0;
}

/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
function __incString(s, amount) {
  return String(+s > Number.MAX_SAFE_INTEGER ? BigInt(s) + BigInt(amount) : +s + amount);
}
function __padNumber(n, p) {
  if (p >= 0) return n;
  const _str = String(n);
  const _neg = _str.startsWith('-');
  const _dlen = _str.length - +_neg;
  const _sign = +!_neg || -1;
  const _up = +_str[_str.length + p] > 4 + +_neg;
  const _slice = _str.slice(+_neg, p);
  const _padded = (_neg ? '-' : '') + __incString(_slice, +_up * _sign).padEnd(_dlen, '0');
  return typeof n === 'bigint' ? BigInt(_padded) : Number(_padded);
}
function __absZero(z) {
  return z === 0 && !Math.sign(z) ? 0 : z;
}
function round(num, precision = 12) {
  if (precision < -100 || precision > 100) throw RangeError('Precision value should be in -100..100 range.');
  if (typeof num === 'bigint') return precision < 0 ? __padNumber(num, precision) : num;
  const f1 = 10 ** precision;
  const f2 = 10 ** Math.sign(precision);
  const product = Math.round(num * (f1 * f2) / f2);
  return precision > 0 ? __absZero(product / f1) : __absZero(Math.round(product / f1));
}

/**
 * Checks if the first argument approximately equals
 * to the second argument within delta
 *
 * @param {number} a Number to be checked
 * @param {number} b Number to be checked
 * @param {number} delta Acceptable difference between a and b
 *
 * @returns {boolean} True or False
 */
function approx(a, b, delta = 0) {
  const _p = Math.max(precision(a), precision(b), precision(delta));
  return round(+Math.abs(a - b), _p) <= delta;
}

/**
 * Calculates modulo in the correct way including negative numbers.
 * Fixes so called JavaScript modulo bug:
 * https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
 *
 * @param {number} a Left operand
 * @param {number} b Right operand
 *
 * @returns {number} Modulo of a mod b
 */
function mod(a, b) {
  return (+a % +b + +b) % +b;
}

/**
 * Converts string or number to a certain precision
 * NOTE! This function will not coerce values to number, so
 * ```
 * num(false) => NaN
 * num(null) => NaN
 * ```
 *
 * @param {string | number} numberLike Number to be converted
 * @param {number} precision Precision, the number to be rounded to
 */
function num(numberLike, precision = 12) {
  if (numberLike == null || Array.isArray(numberLike)) return NaN;
  const _s = numberLike.toString();
  const _n = /%$/.test(_s) ? +_s.slice(0, -1) / 100 : +_s;
  return precision !== undefined ? round(_n, precision) : _n;
}

/**
 * Generates random number within range with certain precision
 *
 * @param {number[]} range Array of min and max values of the range
 * @param {number} precision Precision, the generated number to be rounded to
 */
function random(range = [0, 1], precision = 12) {
  const [min, max] = range;
  return round(min + Math.random() * (max - min), precision);
}
export { approx, mod, num, precision, random, round };
