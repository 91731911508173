import { isPlatformServer, NgClass, NgTemplateOutlet, NgStyle } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  ContentChild,
  TemplateRef,
  Input,
  Output, PLATFORM_ID,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  inject,
  OnInit
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { ModalHistoryComponent } from '@pedix-workspace/angular-ui-modal';

export type ToggleStateEvent = 'is-opening' | 'opened' | 'is-closing' | 'closed';

export const drawerStateToggleSubject$ = new Subject<ToggleStateEvent>();

@UntilDestroy()
@Component({
  selector: 'pxw-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, NgStyle, ModalHistoryComponent],
})
export class DrawerComponent implements OnInit, OnChanges {
  @ContentChild('sideMenu') sideMenuTemplate: TemplateRef<any> | null = null;
  @ViewChild('drawerContent') drawerContent: ElementRef<HTMLDivElement>;

  @Input() isOpen: boolean | null = null;
  @Input() shouldAutoOpen = false;

  @Output() isOpenChange = new EventEmitter<boolean | null>();

  display: 'desktop' | 'tablet';
  contentWidth: number | null;
  transitionSpeed = 250; // NOTE: this should match SCSS $transition-speed ms

  private platformId = inject(PLATFORM_ID);
  
  private breakpointObserver = inject(BreakpointObserver);
  private cd = inject(ChangeDetectorRef);
  
  constructor() {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.breakpointObserver
      .observe([Breakpoints.WebLandscape])
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.breakpoints[Breakpoints.WebLandscape]) {
          this.display = 'desktop';

          if (!this.isOpen && this.shouldAutoOpen) {
            this.toggleIsOpen(true);
          } else {
            this.adjusteContentWidth(this.isOpen);
          }
        } else {
          this.display = 'tablet';

          if (this.isOpen) {
            this.toggleIsOpen(false);
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen'] && changes['isOpen'].currentValue !== changes['isOpen'].previousValue) {
      this.adjusteContentWidth(changes['isOpen'].currentValue);
    }
    if (
      changes['shouldAutoOpen'] &&
      changes['shouldAutoOpen'].currentValue !== changes['shouldAutoOpen'].previousValue
    ) {
      if (this.shouldAutoOpen && this.display === 'desktop') {
        this.toggleIsOpen(true);
      } else {
        this.toggleIsOpen(false);
      }
    }
  }

  toggleIsOpen(isOpen: boolean) {
    if (isOpen !== this.isOpen) {
      setTimeout(() => {
        this.isOpenChange.emit(isOpen);
      }, 0);
    }
  }

  private adjusteContentWidth(isOpen: boolean | null) {
    drawerStateToggleSubject$.next(isOpen ? 'is-opening' : 'is-closing');

    if (isOpen && this.display === 'tablet') {
      this.contentWidth = this.drawerContent?.nativeElement.clientWidth;
    }

    setTimeout(() => {
      drawerStateToggleSubject$.next(isOpen ? 'opened' : 'closed');

      if (!isOpen || this.display !== 'tablet') {
        this.contentWidth = null;

        this.cd.markForCheck();
      }
    }, this.transitionSpeed);
  }
}
