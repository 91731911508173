import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import linkifyStr from 'linkify-string';


@Component({
  selector: 'pxw-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class RichTextComponent {
  @Input() set text(text: string) {
    if (!text) {
      this.safeHtml = null;

      return;
    }
    const richText = linkifyStr(text, {
      target: '_blank',
    });

    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(richText);
  }

  safeHtml: SafeHtml | null;

  private domSanitizer = inject(DomSanitizer);
  
  constructor() {}
}
