import { Pipe, PipeTransform, inject } from '@angular/core';
import { StockService } from './stock.service';
import { StockInfo } from '@pedix-workspace/shared-stock';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'pxwStockInfo',
  standalone: true,
})
export class StockInfoPipe implements PipeTransform {
  private stockService = inject(StockService);
  
  constructor() {}

  transform(cartItemId: string): Observable<StockInfo> {
    return this.stockService.stock$.pipe(map(stock => stock.getStockInfoByItem(cartItemId)));
  }
}
