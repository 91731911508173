import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, throttleTime } from 'rxjs';

@UntilDestroy()
@Directive({ selector: '[pxwDetectScroll]', standalone: true })
export class DetectScrollDirective implements OnInit {
  @Input() set invalidateValue(value: unknown) {
    this.notifyScrollStateChange(true);
  }

  @Output() scrollStateChange = new EventEmitter<boolean>();

  private elementRef = inject(ElementRef<HTMLElement>);
  private ngZone = inject(NgZone);
  private platformId = inject(PLATFORM_ID);

  get isScrollActive() {
    if (!this.elementRef.nativeElement) {
      return false;
    }
    return (
      this.elementRef.nativeElement.clientHeight !== this.elementRef.nativeElement.scrollHeight
    );
  }

  ngOnInit(): void {
    this.notifyScrollStateChange(true);

    if (isPlatformBrowser(this.platformId)) {
      this.ngZone.runOutsideAngular(() => {
        fromEvent(window, 'resize')
          .pipe(
            throttleTime(150, undefined, { leading: true, trailing: true }),
            untilDestroyed(this),
          )
          .subscribe(() => {
            this.notifyScrollStateChange(false);
          });
      });
    }
  }

  notifyScrollStateChange(deferred: boolean) {
    if (deferred) {
      setTimeout(() => this.scrollStateChange.emit(this.isScrollActive));
    } else {
      this.scrollStateChange.emit(this.isScrollActive);
    }
  }
}
