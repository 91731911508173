<ng-container *transloco="let t">
  <div class="product-option">
    <div class="option-heading">
      <div>
        <h2 class="option-name">
          {{ option | propInSelectedLanguage: 'name' : selectedLanguage : true }}
        </h2>
        <h3 class="option-instructions">{{ optionInstructions }}</h3>
      </div>
      @if (!isSingleChoice) {
        <span class="option-condition">{{
          optionRequired
            ? t('product.optionSelectionRequired')
            : t('product.optionSelectionOptional')
        }}</span>
      }
    </div>
    <div class="option-items">
      @if (isSingleChoice) {
        <pxw-input-radio-group
          [name]="'option-' + option.id"
          [options]="option.items || []"
          [ngModel]="singleChoiceSelection"
          [disabledKeys]="disabledOptionKeys"
          (ngModelChange)="onSingleChoiceSelected($event)"
          valueKey="id"
        >
          <ng-template #itemTemplate let-item>
            <pxw-product-option-item-radio
              [optionId]="option.id"
              [optionMin]="optionMin"
              [item]="item"
              [cartItemId]="cartItemId"
              [displaySku]="displaySku"
              [selectedLanguage]="selectedLanguage"
              [count]="item.quantity || 0"
              [disableIncrement]="disableIncrement"
              [displayItemQuantities]="option.displayItemQuantities || false"
              [maxCount]="itemsMaxCountSelection[item.id]"
              (itemImageClick)="onItemImageClick(item)"
            ></pxw-product-option-item-radio>
          </ng-template>
        </pxw-input-radio-group>
      } @else {
        <pxw-input-checkbox-group
          [name]="'option-' + option.id"
          [options]="option.items || []"
          [ngModel]="multipleChoiceSelection"
          valueKey="id"
          [disabledKeys]="disabledOptionKeys"
          (checkboxToggle)="onToggleOption($event.option, $event.nextState)"
        >
          <ng-template #itemTemplate let-item>
            <pxw-product-option-item-checkbox
              [optionId]="option.id"
              [optionMin]="optionMin"
              [item]="item"
              [cartItemId]="cartItemId"
              [displaySku]="displaySku"
              [selectedLanguage]="selectedLanguage"
              [count]="item.quantity || 0"
              [disableIncrement]="disableIncrement"
              [displayItemQuantities]="option.displayItemQuantities || false"
              [maxCount]="itemsMaxCountSelection[item.id]"
              (countChange)="onOptionItemCountChange(item, $event)"
              (itemImageClick)="onItemImageClick(item)"
            ></pxw-product-option-item-checkbox>
          </ng-template>

          @if (validationErrorRule) {
            <pxw-form-validation
              [name]="'selectedItemsCount-' + option.id"
              [ngModel]="selectedItemsCount"
              [displayErrors]="displayErrors || disableIncrement"
              [min]="validationErrorRule.min"
              [max]="validationErrorRule.max"
              [messages]="{
                min: validationErrorRule.message,
                max: validationErrorRule.message
              }"
            />
          }
        </pxw-input-checkbox-group>
      }
    </div>
  </div>
</ng-container>
