import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ApiV1_Subsidiary } from '@pedix-workspace/api-v1';
import { EstablishmentUrlPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';

import { TranslocoDirective } from '@ngneat/transloco';
import { IconMapMarkerComponent } from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'app-subsidiary',
  templateUrl: './subsidiary.component.html',
  styleUrls: ['./subsidiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TagComponent, EstablishmentUrlPipe, IconMapMarkerComponent],
})
export class SubsidiaryComponent {
  @Input() subsidiary: ApiV1_Subsidiary;
  @Input() distance: number;

  get address(): string {
    return (
      this.subsidiary.address ||
      this.subsidiary.addressDetails?.freeSearch ||
      this.subsidiary.addressDetails?.fullAddress
    );
  }

  get roundDistance(): string | number {
    if (this.distance < 10) {
      return this.distance.toFixed(1);
    }
    if (this.distance > 100) {
      return `>100`;
    }
    return Math.floor(this.distance);
  }
}
