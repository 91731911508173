import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="ban"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconBanComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2721 10.2721C17.3016 3.24262 28.6979 3.24262 35.7279 10.2721C42.7574 17.3016 42.7574 28.6979 35.7279 35.7279C28.6984 42.7574 17.3021 42.7574 10.2721 35.7279C3.24263 28.6983 3.24263 17.3021 10.2721 10.2721ZM15.1768 11.9953L34.0042 30.8223C37.7619 25.5502 37.2755 18.1841 32.5455 13.454C27.8155 8.72398 20.4489 8.23759 15.1772 11.9953H15.1768V11.9953ZM11.9949 15.1768C8.23742 20.4489 8.72393 27.8155 13.4545 32.545C18.184 37.2755 25.5506 37.7624 30.8227 34.0042L11.9953 15.1768H11.9949Z"
      />
    </svg>
  `,
})
export class IconBanComponent extends IconComponent {}
