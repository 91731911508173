import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="zoom"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconZoomComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5686 14H20.7089C20.8103 14 20.8609 14.0407 20.8609 14.1222V24.8778C20.8609 24.9593 20.8103 25 20.7089 25H19.5686C19.4672 25 19.4165 24.9593 19.4165 24.8778V14.1222C19.4165 14.0407 19.4672 14 19.5686 14Z"
      stroke="#FF7C7C"
    />
    <path
      d="M14.1428 18.8889H26.135C26.2302 18.8889 26.2778 18.9318 26.2778 19.0176V19.9825C26.2778 20.0683 26.2302 20.1111 26.135 20.1111H14.1428C14.0476 20.1111 14 20.0683 14 19.9825V19.0176C14 18.9318 14.0476 18.8889 14.1428 18.8889Z"
      stroke="#FF7C7C"
    />
    <path
      d="M29.9231 19.7308C29.9231 17.0625 28.9748 14.78 27.0781 12.8834C25.1815 10.9868 22.899 10.0385 20.2308 10.0385C17.5625 10.0385 15.28 10.9868 13.3834 12.8834C11.4868 14.78 10.5385 17.0625 10.5385 19.7308C10.5385 22.399 11.4868 24.6815 13.3834 26.5781C15.28 28.4748 17.5625 29.4231 20.2308 29.4231C22.899 29.4231 25.1815 28.4748 27.0781 26.5781C28.9748 24.6815 29.9231 22.399 29.9231 19.7308ZM41 37.7308C41 38.4808 40.726 39.1298 40.1779 39.6779C39.6298 40.226 38.9808 40.5 38.2308 40.5C37.4519 40.5 36.8029 40.226 36.2837 39.6779L28.863 32.2788C26.2812 34.0673 23.4038 34.9615 20.2308 34.9615C18.1683 34.9615 16.1959 34.5613 14.3137 33.7608C12.4315 32.9603 10.8089 31.8786 9.44591 30.5156C8.08293 29.1526 7.0012 27.53 6.20072 25.6478C5.40024 23.7656 5 21.7933 5 19.7308C5 17.6683 5.40024 15.6959 6.20072 13.8137C7.0012 11.9315 8.08293 10.3089 9.44591 8.94591C10.8089 7.58293 12.4315 6.5012 14.3137 5.70072C16.1959 4.90024 18.1683 4.5 20.2308 4.5C22.2933 4.5 24.2656 4.90024 26.1478 5.70072C28.03 6.5012 29.6526 7.58293 31.0156 8.94591C32.3786 10.3089 33.4603 11.9315 34.2608 13.8137C35.0613 15.6959 35.4615 17.6683 35.4615 19.7308C35.4615 22.9038 34.5673 25.7812 32.7788 28.363L40.1995 35.7837C40.7332 36.3173 41 36.9663 41 37.7308Z"
    />
  </svg>`,
})
export class IconZoomComponent extends IconComponent {}
