import { Injectable } from '@angular/core';

import { color, contrast, sRGBColor } from '@snigo.dev/color';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  minimumContrastRatio = 2;
  preferredContrastRatio = 6;
  hueOffset = 0;

  getDefaultContrastColor1(baseColor: string) {
    return this.getContrastColor(baseColor);
  }

  getDefaultContrastColor2(baseColor: string) {
    return '#FFFFFF';
  }

  getContrastColor(baseColor: string, contrastRatio: number = this.preferredContrastRatio): string {
    const colorObject: sRGBColor = color(baseColor) as sRGBColor;

    const [contrastColor] = contrast.find(colorObject, {
      hue: colorObject.hue,
      saturation: colorObject.saturation,
      targetContrast: contrastRatio,
    }) as [sRGBColor];

    if (!contrastColor) {
      return this.getContrastColor(baseColor, contrastRatio - 0.5);
    }

    return contrastColor.toHexString();
  }

  validateContrast(baseColor: string, contrastColor: string): boolean {
    return (contrast(baseColor, contrastColor, 5) || 0) > this.minimumContrastRatio;
  }
}
