import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { PinchZoomComponent } from '@pedix-workspace/angular-ui-ivy-pinch-zoom';
import { ModalFormComponent } from '@pedix-workspace/angular-ui-modal';

export type ZoomImage = {
  medium: string;
  large: string;
};

@Component({
  selector: 'pxw-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconLoadingComponent, PinchZoomComponent, ModalFormComponent],
})
export class ImageZoomComponent implements OnChanges {
  static PRELOADED_IMAGES: Set<string> = new Set();

  @ViewChild(ModalFormComponent) modalForm: ModalFormComponent;

  @Input() popupTitle: string;
  @Input() zoomImage: ZoomImage | null;
  @Input() isOpen = false;

  @Output() isOpenChange = new EventEmitter<boolean>();

  loadingFullImage: boolean;
  popupVisible: boolean;

  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);

  private renderer = inject(Renderer2);
  private cd = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['zoomImage'] && this.zoomImage) {
      this.preloadLargeImage(this.zoomImage);
    }
    if (
      isPlatformBrowser(this.platformId) &&
      changes['isOpen']?.currentValue === true &&
      changes['isOpen']?.currentValue !== changes['isOpen'].previousValue
    ) {
      this.modalForm.open(true);
    }
  }

  onPopupToggle(isPopupOpen: boolean) {
    this.isOpenChange.emit(isPopupOpen ? true : false);
  }

  onPopupShowing($event: any) {
    $event.component.content()?.parentElement?.parentElement?.classList.add('ui-popup');

    this.popupVisible = true;

    this.cd.markForCheck();
  }

  onPopupHidden() {
    this.popupVisible = false;

    this.cd.markForCheck();
  }

  onPinchZoomEvents(event: { name: string }) {
    switch (event.name) {
      case 'startLoadingFullImage':
        this.loadingFullImage = true;
        this.cd.detectChanges();
        break;
      case 'loadedFullImage':
        this.loadingFullImage = false;
        this.cd.detectChanges();
        break;
    }
  }

  private preloadLargeImage(zoomImage: ZoomImage) {
    if (
      !isPlatformBrowser(this.platformId) ||
      ImageZoomComponent.PRELOADED_IMAGES.has(zoomImage.large)
    ) {
      return;
    }
    ImageZoomComponent.PRELOADED_IMAGES.add(zoomImage.large);

    const preconnectLink = this.document.createElement('link');

    preconnectLink.rel = 'preload';
    preconnectLink.as = 'image';
    preconnectLink.href = zoomImage.large;

    this.renderer.appendChild(this.document.head, preconnectLink);
  }
}
