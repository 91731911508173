import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="building"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconBuildingComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 41.1H42"
        stroke="#FF7C7C"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4998 25.9H9.6998C8.65046 25.9 7.7998 26.7507 7.7998 27.8V39.2C7.7998 40.2493 8.65046 41.1 9.6998 41.1H13.4998C14.5491 41.1 15.3998 40.2493 15.3998 39.2V27.8C15.3998 26.7507 14.5491 25.9 13.4998 25.9Z"
        stroke="#FF7C7C"
        stroke-width="4"
        stroke-linejoin="round"
      />
      <path
        d="M11.5996 33.5H12.5496"
        stroke="#FF7C7C"
        stroke-width="4"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M36.3004 5H17.3004C16.251 5 15.4004 5.85066 15.4004 6.9V39.2C15.4004 40.2493 16.251 41.1 17.3004 41.1H36.3004C37.3497 41.1 38.2004 40.2493 38.2004 39.2V6.9C38.2004 5.85066 37.3497 5 36.3004 5Z"
        stroke="#FF7C7C"
        stroke-width="4"
        stroke-linejoin="round"
      />
      <path
        d="M21.0996 10.7H24.8996V14.5H21.0996V10.7ZM28.6996 10.7H32.4996V14.5H28.6996V10.7ZM21.0996 17.35H24.8996V21.15H21.0996V17.35ZM28.6996 17.35H32.4996V21.15H28.6996V17.35ZM28.6996 24H32.4996V27.8H28.6996V24ZM28.6996 30.65H32.4996V34.45H28.6996V30.65Z"
      />
    </svg>
  `,
})
export class IconBuildingComponent extends IconComponent {}
