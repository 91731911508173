import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IconBackComponent,
  IconClockComponent,
  IconFilterComponent,
} from '@pedix-workspace/angular-ui-icons';
import { ZonelessEventDirective } from '@pedix-workspace/angular-utils';
import {
  ItemListComponent,
  ItemListGroupProductsByCategoryPipe,
  ProductListItemComponent,
} from '@pedix-workspace/pedixapp-shared-catalog';
import { ImageStoragePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { IsCategoryAvailablePipe } from '../../categories/isCategoryAvailable.pipe';
import { AppRequestContextService } from '../../../../app-request-context.service';
import { TranslocoDirective } from '@ngneat/transloco';
import { ApiV1_Category, ApiV1_Product, ApiV1_ProductTag } from '@pedix-workspace/api-v1';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { CategoryAvailabilityDialogComponent } from '../../category-availability-dialog/category-availability-dialog.component';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { ProductFiltersDialogComponent } from '../../category/product-filters/product-filters-dialog.component';
import { filter, map } from 'rxjs';
import { AppSearchResultsService } from '../../../../app-search-results.service';

@UntilDestroy()
@Component({
  selector: 'app-products-by-tag',
  standalone: true,
  templateUrl: './products-by-tag.component.html',
  styleUrl: './products-by-tag.component.scss',
  imports: [
    CommonModule,
    IconBackComponent,
    IconClockComponent,
    ButtonComponent,
    RouterLink,
    ZonelessEventDirective,
    ProductListItemComponent,
    ImageStoragePipe,
    TranslocoDirective,
    IsCategoryAvailablePipe,
    AlertComponent,
    CategoryAvailabilityDialogComponent,
    ItemListComponent,
    ProductFiltersDialogComponent,
    IconFilterComponent,
    ItemListGroupProductsByCategoryPipe,
  ],
})
export class ProductsByTagComponent implements OnInit {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private appRequestContextService = inject(AppRequestContextService);

  private searchResultsService = inject(AppSearchResultsService);

  tagId: string;
  productTag: ApiV1_ProductTag;
  searchResults: ApiV1_Product[];
  categories: ApiV1_Category[];
  selectedCategoryId: string;

  get currencySymbol() {
    return this.appRequestContextService.currencyLocaleData.currencySymbol;
  }

  get displaySku(): boolean {
    return this.appRequestContextService.shouldDisplaySku;
  }

  ngOnInit(): void {
    this.categories = this.appRequestContextService.categories;
    this.activatedRoute.queryParams
      .pipe(
        filter(queryParams => {
          if (!queryParams.t) {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            return false;
          }
          return true;
        }),
        map(queryParams => {
          this.tagId = queryParams.t;

          this.productTag = this.appRequestContextService.productTags.find(
            productTag => productTag.id === this.tagId,
          );

          return this.searchResultsService.getFilteredResults(
            this.appRequestContextService.products,
            { productTagIds: [this.tagId] },
          );
        }),
        untilDestroyed(this),
      )
      .subscribe(filteredResults => {
        this.searchResults = filteredResults;
      });
  }
}
