import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="eye-slash"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconEyeSlashComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7258 31.4148C10.6989 29.2655 8.30355 26.4699 6.95003 24.7046C6.57154 24.2173 6.36609 23.6178 6.36609 23.0008C6.36609 22.3838 6.57154 21.7843 6.95003 21.297C9.39948 18.0995 15.2762 11.5156 22.1373 11.5156C25.2151 11.5156 28.0928 12.8396 30.5537 14.5902"
      stroke="#FF7C7C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.8561 33.9988C19.9212 34.3158 21.0261 34.4793 22.1373 34.4844C28.9984 34.4844 34.8751 27.9005 37.3246 24.703C37.703 24.2152 37.9082 23.6154 37.9079 22.9981C37.9076 22.3808 37.7018 21.7811 37.323 21.2938C36.4621 20.1707 35.5386 19.0971 34.5569 18.0781M25.6319 19.5334C25.1762 19.0702 24.6334 18.7017 24.0347 18.4494C23.436 18.197 22.7933 18.0657 22.1436 18.0629C21.4938 18.0602 20.85 18.1862 20.2492 18.4335C19.6484 18.6809 19.1025 19.0447 18.6431 19.5041C18.1836 19.9634 17.8196 20.5092 17.5721 21.11C17.3246 21.7107 17.1985 22.3545 17.2011 23.0042C17.2037 23.654 17.3349 24.2967 17.5871 24.8955C17.8393 25.4943 18.2076 26.0372 18.6707 26.4929L25.6319 19.5334ZM9.01233 36.125L35.2623 9.875L9.01233 36.125Z"
      stroke="#FF7C7C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
})
export class IconEyeSlashComponent extends IconComponent {}
