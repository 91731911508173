import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRequestContextService } from '../../../app-request-context.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryExistsGuard implements CanActivate {

  private appRequestContext = inject(AppRequestContextService);
  private router = inject(Router);

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { categoryId = '' } = {
      ...routeSnapshot.params,
      ...(routeSnapshot.parent?.params || {}),
    };

    const category = this.appRequestContext.categories.find(c => c.id === categoryId);

    if (!category || !category.active) {
      return this.getCatalogueUrlTree();
    }
    return true;
  }

  private getCatalogueUrlTree() {
    return this.router.createUrlTree(['/']);
  }
}
