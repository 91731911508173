import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="twitter"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconTwitterComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 7H9.09091L33.6364 39H29.5455L5 7ZM12.3636 7H16.4545L41 39H36.9091L12.3636 7ZM8.27273 7H16.4545V10.2H8.27273V7ZM29.5455 35.8H37.7273V39H29.5455V35.8ZM33.6364 7H39.3636L11.5455 39H5.81818L33.6364 7Z"
    />
  </svg>`,
})
export class IconTwitterComponent extends IconComponent {}
