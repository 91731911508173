import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  QueryList,
  ContentChildren,
  inject,
  AfterViewInit,
} from '@angular/core';
import { HorizontalSliderItemComponent } from '../horizontal-slider-item/horizontal-slider-item.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pxw-horizontal-slider',
  templateUrl: './horizontal-slider.component.html',
  styleUrls: ['./horizontal-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HorizontalSliderComponent implements AfterViewInit {
  @ContentChildren(HorizontalSliderItemComponent)
  children: QueryList<HorizontalSliderItemComponent>;

  private rootEl: ElementRef<HTMLElement> = inject(ElementRef);

  ngAfterViewInit(): void {
    this.children.changes.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.rootEl.nativeElement) {
        this.rootEl.nativeElement.scrollLeft = 0;
      }
    });
  }

  scrollToIndex(index: number, animated: boolean) {
    const sliderItem = this.children.toArray()?.[index];

    if (sliderItem) {
      const left = this.children.first === sliderItem ? 0 : sliderItem.offsetLeft;

      this.rootEl.nativeElement.scrollTo({
        left,
        behavior: <ScrollBehavior>(animated ? 'smooth' : 'instant'),
      });
    }
  }

  scrollToId(id: string, animated: boolean) {
    const sliderItem = this.children.toArray().find(item => item.id === id);

    if (sliderItem) {
      const left = this.children.first === sliderItem ? 0 : sliderItem.offsetLeft;

      this.rootEl.nativeElement.scrollTo({
        left,
        behavior: <ScrollBehavior>(animated ? 'smooth' : 'instant'),
      });
    }
  }
}
