import { ApplicationConfig } from '@angular/core';
import { ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { environment } from '../environments/environment';
import { LoggerModule } from 'ngx-logger';
import { withInterceptorsFromDi, provideHttpClient, withFetch } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFunctions } from '@angular/fire/functions';
import { provideStorage } from '@angular/fire/storage';
import { provideAuth } from '@angular/fire/auth';
import {
  getFirebaseApp,
  getFirebaseAuth,
  getFirebaseStorage,
  getFirebaseFunctions,
} from './firebase/clients';
import { provideFirebaseApp } from '@angular/fire/app';

import { AppCurrencyFormatService } from './app-currency-format.service';
import { CurrencyFormatService } from '@pedix-workspace/pedixapp-shared-pipes';
import { AppRequestContextService } from './app-request-context.service';
import { ModulePreloadManualStrategyService } from '@pedix-workspace/pedixapp-core-services';

import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withPreloading,
} from '@angular/router';

import { AppPathLocationStrategy } from './app.path-location-strategy';
import { LocationStrategy, registerLocaleData } from '@angular/common';
import { AppEnvironmentService } from './app-environment.service';
import { EnvironmentService, ErrorHandlerService } from '@pedix-workspace/angular-utils';
import { provideClientHydration } from '@angular/platform-browser';
import { appRoutes } from './app.routes';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco/transloco-http-loader.module';
import { UiFormReactiveConfigService } from '@pedix-workspace/angular-ui-form-reactive';
import { AppFormReactiveConfigService } from './app-form-reactive-config.service';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { AppHereMapsConfigService } from './app-here-maps-config.service';
import {
  HERE_MAPS_SERVICE_API_KEY,
  UiHereMapsConfigService,
} from '@pedix-workspace/angular-ui-here-maps';

import { PixelModule } from 'ngx-multi-pixel';
import { GOOGLE_MAPS_SERVICE_API_KEY } from '@pedix-workspace/angular-ui-map';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      provideFirebaseApp(() => getFirebaseApp()),
      provideAuth(() => getFirebaseAuth()),
      provideFirestore(() =>
        initializeFirestore(getFirebaseApp(), { ignoreUndefinedProperties: true }),
      ),
      provideStorage(() => getFirebaseStorage()),
      provideFunctions(() => getFirebaseFunctions()),
      // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      LoggerModule.forRoot({
        serverLoggingUrl: '/logs',
        enableSourceMaps: true,
        level: environment.logger.logLevel,
        serverLogLevel: environment.logger.serverLogLevel,
        disableConsoleLogging: environment.logger.disableConsoleLogging,
      }),
      PixelModule.forRoot({ enabled: true, pixelId: [environment.pedixPixelId] }),
    ),
    { provide: EnvironmentService, useClass: AppEnvironmentService },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: LocationStrategy, useClass: AppPathLocationStrategy },
    { provide: AppPathLocationStrategy, useExisting: LocationStrategy },
    {
      provide: LOCALE_ID,
      useFactory: (appRequestContextService: AppRequestContextService) => {
        registerLocaleData(appRequestContextService.localeData, appRequestContextService.localeId);
        return appRequestContextService.localeId;
      },
      deps: [AppRequestContextService],
    },
    {
      provide: CurrencyFormatService,
      useClass: AppCurrencyFormatService,
    },
    {
      provide: UiFormReactiveConfigService,
      useClass: AppFormReactiveConfigService,
    },
    { provide: UiHereMapsConfigService, useClass: AppHereMapsConfigService },
    { provide: HERE_MAPS_SERVICE_API_KEY, useValue: environment.hereMaps.apiKey },
    { provide: GOOGLE_MAPS_SERVICE_API_KEY, useValue: environment.googleMaps.apiKey },
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withPreloading(ModulePreloadManualStrategyService),
    ),
    provideTransloco({
      config: {
        availableLangs: ['es-MX', 'es-AR'],
        defaultLang: 'es-MX',
        fallbackLang: 'es-MX',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: false,
        prodMode: environment.production,
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideToastr({
      timeOut: 6000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
    }),
  ],
};
