import { Route } from '@angular/router';
import { NotFoundComponent } from './features/main/not-found/not-found.component';
import { AppLayoutComponent } from './features/main/app-layout/app-layout.component';
import { AppTranslationsGuard } from './features/main/app-translations.guard';
import { AppEstablishmentGuardService } from './app-establishment.guard';
import { SubsidiariesComponent } from './features/establishment/subsidiaries/subsidiaries.component';
import establishmentRoutes from './features/establishment/establishment.routes';

export const appRoutes: Route[] = [
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AppTranslationsGuard, AppEstablishmentGuardService],
    children: [
      {
        path: 'sucursales',
        component: SubsidiariesComponent,
      },
      {
        path: 'pedido',
        loadChildren: () => import('./features/order-checkout/order-checkout.routes'),
        data: { preloadName: 'order-checkout' },
      },
      {
        path: 'pagos',
        loadChildren: () => import('./features/payments/payments.routes'),
      },
      {
        path: '',
        loadChildren: () => establishmentRoutes,
      },
      // --- Catch All ---
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];
