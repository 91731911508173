import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="tags"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconTagsComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3857 36.1785L9.375 22.1678V25.0301C9.375 25.7886 9.67553 26.5184 10.2194 27.048L21.3678 38.1964C22.484 39.3126 24.3016 39.3126 25.4178 38.1964L34.3051 29.3091C35.4214 28.1929 35.4214 26.3753 34.3051 25.2591L23.3857 36.1785Z"
      />
      <path
        d="M21.3678 31.0408C21.9259 31.5989 22.6558 31.8851 23.3857 31.8851C24.1155 31.8851 24.8454 31.5989 25.4035 31.0408L34.2908 22.1535C35.407 21.0373 35.407 19.2197 34.2908 18.1035L23.1424 6.95505C22.6128 6.42553 21.883 6.125 21.1245 6.125H12.2372C10.663 6.125 9.375 7.41301 9.375 8.98724V17.8745C9.375 18.633 9.67553 19.3628 10.2194 19.8924L21.3678 31.0408ZM12.2372 8.98724H21.1245L32.2729 20.1357L23.3857 29.0229L12.2372 17.8745V8.98724Z"
      />
      <path
        d="M15.4574 13.9962C16.4453 13.9962 17.2463 13.1952 17.2463 12.2073C17.2463 11.2193 16.4453 10.4184 15.4574 10.4184C14.4694 10.4184 13.6685 11.2193 13.6685 12.2073C13.6685 13.1952 14.4694 13.9962 15.4574 13.9962Z"
      />
    </svg>
  `,
})
export class IconTagsComponent extends IconComponent {}
