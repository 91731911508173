import { Component, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getCartItemSubtotal } from '@pedix-workspace/orders';
import { CartItem } from '@pedix-workspace/utils';
import { CurrencyFormatPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';

import { TranslocoDirective } from '@ngneat/transloco';
import { AppCartItemsService } from '../../../app-cart-items.service';

@UntilDestroy()
@Component({
  selector: 'app-show-order',
  templateUrl: './show-order.component.html',
  styleUrls: ['./show-order.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, ButtonComponent, RouterLink, CurrencyFormatPipe],
})
export class ShowOrderComponent implements OnInit {
  showBlock = false;
  amount = 0;

  private appCartItemsService = inject(AppCartItemsService);

  ngOnInit(): void {
    this.appCartItemsService.cartItemsObservable$
      .pipe(untilDestroyed(this))
      .subscribe(cartItems => {
        this.showBlock = cartItems.length > 0 ? true : false;
        this.amount = cartItems.length > 0 ? this.calculateAmount(cartItems) : 0;
      });
  }

  private calculateAmount(cartItems: CartItem[]) {
    return cartItems.reduce(
      (amount, cartItem) => amount + getCartItemSubtotal(cartItem, { usePriceDiscount: true }),
      0,
    );
  }
}
