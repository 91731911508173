import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Category } from '@pedix-workspace/utils';
import { CategoryAvailabilityDialogComponent } from '../../category-availability-dialog/category-availability-dialog.component';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';

import { TranslocoDirective } from '@ngneat/transloco';
import { IconClockComponent } from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'app-product-footer',
  templateUrl: './product-footer.component.html',
  styleUrls: ['./product-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    AlertComponent,
    ButtonComponent,
    CategoryAvailabilityDialogComponent,
    IconClockComponent,
  ],
})
export class ProductFooterComponent {
  @Input() isCartDisabled: boolean;
  @Input() isCategoryAvailable: boolean;
  @Input() isWithoutStock: boolean;
  @Input() category: Pick<Category, 'availability'>;

  get templateToRender(): string {
    if (this.isCartDisabled) {
      return 'cart-disabled';
    }
    if (!this.isCategoryAvailable) {
      return 'not-available';
    }
    if (this.isWithoutStock) {
      return 'without-stock';
    }
    return 'content';
  }
}
