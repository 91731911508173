import { Pipe, PipeTransform, inject } from '@angular/core';
import { ApiV1_ProductTag } from '@pedix-workspace/api-v1';
import { AppRequestContextService } from '../../../app-request-context.service';
import { getUniqueTagsWithQuantities } from './getUniqueTagsWithQuantities';

@Pipe({
  name: 'appProductTagsWithQuantities',
  standalone: true,
})
export class ProductTagsWithQuantitiesPipe implements PipeTransform {
  private appRequestContext = inject(AppRequestContextService);

  transform(productTagIds: string[]): { productTag: ApiV1_ProductTag; count: number }[] {
    return getUniqueTagsWithQuantities(this.appRequestContext.activeProducts).filter(current =>
      productTagIds.includes(current.productTag.id),
    );
  }
}
