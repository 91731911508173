<ng-container *transloco="let t">
  <img class="image" [alt]="t('notFound.sectionTitle')" src="/assets/images/404.png" with="550" height="516" />

  <h1 class="title">{{ t('notFound.title') }}</h1>
  <p class="description">{{ t('notFound.description') }}</p>

  <pxw-button color="pink" display="inline" size="lg" [rounded]="true" (click)="onCtaButtonClicked()">
    {{ t('notFound.ctaText') }}
  </pxw-button>
</ng-container>
