import { Pipe, PipeTransform, inject } from '@angular/core';
import { ApiV1_Product, ApiV1_ProductOptionItem } from '@pedix-workspace/api-v1';
import { PriceDiscountPipe } from '@pedix-workspace/pedixapp-shared-pipes';

@Pipe({
  name: 'maxProductsDiscount',
  standalone: true,
})
export class MaxProductsDiscountPipe implements PipeTransform {
  private priceDiscountPipe = inject(PriceDiscountPipe);

  transform(productsOrPresentations: ApiV1_Product[] | ApiV1_ProductOptionItem[]): number {
    return productsOrPresentations.reduce((maxDiscount, productOrPresentation) => {
      let currentDiscount = 0;

      if (isProduct(productOrPresentation)) {
        currentDiscount = this.transform(productOrPresentation.presentations.items);
      } else {
        currentDiscount = this.priceDiscountPipe.transform(
          productOrPresentation.price,
          productOrPresentation.priceDiscount,
        );
      }

      if (currentDiscount > maxDiscount) {
        return currentDiscount;
      }
      return maxDiscount;
    }, 0);
  }
}

function isProduct(input: ApiV1_Product | ApiV1_ProductOptionItem): input is ApiV1_Product {
  return !!input['presentations'];
}
