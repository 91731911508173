import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRequestContextService } from '../../../app-request-context.service';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { SeoService } from '../../main/seo.service';
import { EstablishmentWorkingHoursService } from '../../main/establishment-working-hours.service';
import { ApiV1_Category, ApiV1_Product, ApiV1_ProductTag } from '@pedix-workspace/api-v1';
import { IsCategoryAvailablePipe } from '../categories/isCategoryAvailable.pipe';
import {
  PropInSelectedLanguagePipe,
  ImageStoragePipe,
} from '@pedix-workspace/pedixapp-shared-pipes';
import { ShowOrderComponent } from '../show-order/show-order.component';
import {
  ItemListComponent,
  ItemListProductGroupingStrategyPipe,
  ProductListItemComponent,
} from '@pedix-workspace/pedixapp-shared-catalog';
import { CategoryAvailabilityDialogComponent } from '../category-availability-dialog/category-availability-dialog.component';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import {
  IconBackComponent,
  IconClockComponent,
  IconFilterComponent,
} from '@pedix-workspace/angular-ui-icons';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { ProductFiltersDialogComponent } from './product-filters/product-filters-dialog.component';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { AppSearchResultsService } from '../../../app-search-results.service';
import { getUniqueTagsWithQuantities } from '../product-tags/getUniqueTagsWithQuantities';
import { ProductGroupingStrategy } from '@pedix-workspace/utils';

@UntilDestroy()
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    IconBackComponent,
    IconClockComponent,
    IconFilterComponent,
    AlertComponent,
    ButtonComponent,
    CategoryAvailabilityDialogComponent,
    ProductListItemComponent,
    ShowOrderComponent,
    AsyncPipe,
    ImageStoragePipe,
    PropInSelectedLanguagePipe,
    IsCategoryAvailablePipe,
    ItemListComponent,
    ProductFiltersDialogComponent,
    ItemListProductGroupingStrategyPipe,
    NgTemplateOutlet,
  ],
})
export class CategoryComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private analytics = inject(AnalyticsService);
  private seoService = inject(SeoService);
  private appRequestContextService = inject(AppRequestContextService);
  private establishmentWorkingHours = inject(EstablishmentWorkingHoursService);
  private searchResultsService = inject(AppSearchResultsService);

  @ViewChild('scrollTarget') scrollTarget: ElementRef<HTMLElement>;
  @ViewChild(ProductFiltersDialogComponent) filtersDialog: ProductFiltersDialogComponent;

  category: ApiV1_Category;
  categories: ApiV1_Category[];
  products: ApiV1_Product[];
  isCartDisabled = false;
  productTagsWithQuantities: { productTag: ApiV1_ProductTag; count: number }[] = [];

  private selectedTagIds$ = new BehaviorSubject<string[]>([]);

  get currencySymbol() {
    return this.appRequestContextService.currencyLocaleData.currencySymbol;
  }

  get selectedLanguage() {
    return this.appRequestContextService.selectedLanguage;
  }

  get selectedTagIds(): string[] {
    return this.selectedTagIds$.value;
  }

  get groupingStrategy(): ProductGroupingStrategy {
    return this.category?.dynamicGrouping || 'product-group';
  }

  get displaySku(): boolean {
    return this.appRequestContextService.shouldDisplaySku;
  }

  ngOnInit(): void {
    this.categories = this.appRequestContextService.categories;

    this.activatedRoute.params
      .pipe(
        map(params => {
          this.category = this.appRequestContextService.categories.find(
            category => category.id === params.categoryId,
          );
          return this.appRequestContextService.getProductsByCategoryId(this.category.id) || [];
        }),
        switchMap(products => {
          this.productTagsWithQuantities = getUniqueTagsWithQuantities(products);

          return this.selectedTagIds$.pipe(
            map(selectedTags =>
              this.searchResultsService.getFilteredResults(products, {
                productTagIds: selectedTags,
              }),
            ),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe(filteredProducts => {
        this.products = filteredProducts;

        this.seoService.setCategoryMetadata(
          this.category,
          this.appRequestContextService.establishment,
        );

        this.analytics.listCategoryProducts(this.category);
      });

    this.establishmentWorkingHours.openedStatus$
      .pipe(untilDestroyed(this))
      .subscribe(({ isCartDisabled }) => {
        this.isCartDisabled = isCartDisabled;
      });
  }

  onFilterProducts() {
    this.filtersDialog.open();
  }

  onSelectedTags(selectedTags: string[]) {
    this.selectedTagIds$.next(selectedTags);
  }
}
