import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ScrollPositionService {

  private scrollMapping = new Map<string, { value: number, context?: string }>();

  set(key: string, value: number, context?: string) {
    this.scrollMapping.set(key, { value, context });
  }

  get(key: string, context?: string) {
    const match = this.scrollMapping.get(key);

    if (!match || context !== match.context) {
      return 0;
    }
    return match.value;
  }
}