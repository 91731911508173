import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppRequestContextService } from './app-request-context.service';
import { convertEstablishmentData } from '@pedix-workspace/shared-models';
import { StockService } from '@pedix-workspace/pedixapp-stock';
import { StockProduct } from '@pedix-workspace/shared-stock';
import { AppCartItemsService } from './app-cart-items.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppEstablishmentGuardService {
  private platformId = inject(PLATFORM_ID);

  private router = inject(Router);
  private appRequestContextService = inject(AppRequestContextService);
  private appCartItemsService = inject(AppCartItemsService);
  private stockService = inject(StockService);

  constructor() {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const { previewMode } = route.queryParams;

    if (!this.appRequestContextService.establishment) {
      this.router.navigate(['/not-found'], { skipLocationChange: true });

      return false;
    }

    const establishment = convertEstablishmentData(
      this.appRequestContextService.establishment.id,
      this.appRequestContextService.establishment,
    );

    // NOTE: esto no se puede hacer en el server?
    if (
      this.appRequestContextService.source === 'subdomain' &&
      establishment.subdomainStatus !== 'ACTIVE'
    ) {
      this.router.navigate(['/not-found'], { skipLocationChange: true });

      return false;
    }

    this.appRequestContextService.previewMode = previewMode;

    // Updates stock info
    this.stockService.products$.next(this.appRequestContextService.products);

    // Updates cartItems data
    if (isPlatformBrowser(this.platformId)) {
      this.appCartItemsService.initSession(establishment.slug);

      this.appCartItemsService.cartItemsObservable$.subscribe(cartItems => {
        this.stockService.cartItems$.next(cartItems);
      });
    }

    if (
      state.url !== `/sucursales` &&
      establishment.subsidiaries &&
      establishment.subsidiaries.length > 0
    ) {
      this.router.navigate(['/sucursales'], { replaceUrl: true });
      return false;
    }

    if (
      state.url === `/sucursales` &&
      (!establishment.subsidiaries || establishment.subsidiaries.length === 0)
    ) {
      this.router.navigate(['/'], { replaceUrl: true });
      return false;
    }

    return true;
  }
}
