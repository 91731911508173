<ng-container *transloco="let t">
  <a
    data-testid="category-item"
    class="category wow fadeInDown ui-item-link"
    data-wow-delay="0.5s"
    [routerLink]="sortEnabled ? [] : detailRoute"
    [queryParams]="detailRouteQueryParams"
    [title]="name"
  >
    <pxw-image
      class="background"
      [ngClass]="{ 'not-available': displayNotAvailableTag }"
      [src]="image"
      [alt]="name"
      objectFit="cover"
      loader="none"
      [lazyLoad]="listIndex >= 0 ? listIndex > LAZY_LOAD_IMAGE_MIN_INDEX : true"
    ></pxw-image>
    @if (displayOverlay) {
      <div class="overlay"></div>
    }
    <div class="content">
      <h2 [attr.title]="name">
        @if (!hideLabelInList) {
          <span>{{ name }}</span>
        }
      </h2>
      @if (isAdminMode) {
        <div class="status-tags">
          @if (!active) {
            <pxw-tag size="xs" type="info">
              <pxw-icon icon="eye-slash" />
              {{ t('categories.inactiveTag') }}
            </pxw-tag>
          }
          @if (isDynamic) {
            <pxw-tag size="xs" type="info">
              <pxw-icon icon="tags" />
              {{ t('categories.dynamicCategoryTag') }}</pxw-tag
            >
          }
          @if (availability?.isActive) {
            <pxw-tag size="xs" type="info">
              <pxw-icon icon="clock" />
              {{ t('categories.availabilityTag') }}</pxw-tag
            >
          }
        </div>
      }
      @if (!isAdminMode) {
        <div class="status-tags">
          @if (displayNotAvailableTag) {
            <pxw-tag
              (click)="onClickNotAvailable($event)"
              class="not-available"
              size="xs"
              icon="clock"
              type="warning"
              >{{ availabilityText }}</pxw-tag
            >
          } @else if (maxDiscount) {
            <pxw-tag type="success" size="xs">
              {{ t('categories.maxDiscount', { maxDiscount }) }}
            </pxw-tag>
          }
        </div>
      }
    </div>
  </a>
  @if (isAdminMode) {
    <div class="actions">
      @if (!sortEnabled) {
        <button
          name="edit"
          [attr.aria-label]="t('categories.editCategory')"
          (click)="onClickEdit($event)"
        >
          <pxw-icon icon="edit" size="sm"></pxw-icon>
        </button>
        <button
          name="delete"
          [attr.aria-label]="t('categories.deleteCategory')"
          (click)="onClickDelete($event)"
        >
          <pxw-icon icon="trash-can" size-md></pxw-icon>
        </button>
      }
      @if (sortEnabled) {
        <div class="move">
          <pxw-icon icon="cross-arrows" size="sm"></pxw-icon>
        </div>
      }
    </div>
  }
</ng-container>
