import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, Injectable, NgZone, PLATFORM_ID, StateKey, TransferState } from '@angular/core';

import { noop, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UniversalHelpersService {

  private platformId = inject(PLATFORM_ID);
  
  private state = inject(TransferState);
  private ngZone = inject(NgZone);
  
  constructor() {}

  /* 
    This operator is needed during SSR when "querying by watching" documents
    When querying documents with collectionData or docData, what happens behind scenes
    is that a subscriber is created and will "wait" until the socket send the results.
    Because this is not wrapped with a Promise, Angular doesn't know it should wait for some result to be fetched.
    Thereby, on SSR it will send the response before completing a giving fetch.
    What this operator does, is calling `setTimeout`, which will "force" Angular to wait a given amount of time until completed.
    Within that given "timeout", it is expected that the socket successfully responds.
    When the response is received, the "timeout" is cleared, so no additional waiting happens.
    If the response is not received, then the "timeout" is completed and Angular will send the response without the fetch being completed.
  */
  untilCompleteOrTimeout(timeout = 5000) {
    const complete = this._untilCompleteOrTimeout(timeout);
    return <T>(source: Observable<T>): Observable<T> => {
      return new Observable(subscriber => {
        source.subscribe({
          next(value) {
            subscriber.next(value);
            complete();
          },
          error(error: unknown) {
            subscriber.error(error);
            complete();
          },
          complete() {
            subscriber.complete();
            complete();
          }
        });
      });
    }
  }

  /*
    This operator is useful for storing state on SSR and reading it back on CSR
    During SSR, it will wait until the subscription resolves and stores its value on TransferState, then proceed normally without modifying the original Observable
    During CSR, it will try to read any previous TransferState; if there is, it will immediately dispatch that cached value
    Then, it will proceed normally with the subscription chain (similar behavior as startWith operator)
  */
  addOrRestoreFromCache(stateKey: StateKey<any>) {
    return <T>(source: Observable<T>): Observable<T> => {
      return new Observable(subscriber => {
        if (isPlatformBrowser(this.platformId)) {
          const serverState = this.state.get<T>(stateKey, undefined);

          if (serverState !== undefined) {
            this.state.remove(stateKey);

            subscriber.next(serverState);
          }
        }

        source.subscribe({
          next: value => {
            if (isPlatformServer(this.platformId) && Boolean(value)) {
              this.state.set<T>(stateKey, value);
            }
            subscriber.next(value);
          },
          error(error: unknown) {
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          }
        });
      });
    }
  }

  private _untilCompleteOrTimeout(timeout: number){
    if (isPlatformBrowser(this.platformId)) {
      return noop;
    }
    const timer = setTimeout(noop, timeout);

    return () => this.ngZone.run(() => {
      clearTimeout(timer);
    });
  }
}
