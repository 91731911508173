import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TagComponent, TagSize, TagType } from '@pedix-workspace/angular-ui-alert';
import { IconAlertComponent } from '@pedix-workspace/angular-ui-icons';
import { StockInfo } from '@pedix-workspace/shared-stock';

@Component({
  selector: 'pxw-available-stock',
  templateUrl: './available-stock.component.html',
  styleUrls: ['./available-stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TagComponent, IconAlertComponent],
})
export class AvailableStockComponent {
  @Input() stockInfo: StockInfo | null;
  @Input() cartItemId: string | undefined;
  @Input() optionId: string | undefined;
  @Input() minimumStockForWarning = 7;
  @Input() size: TagSize = 'sm';

  private t = inject(TranslocoService);

  constructor() {}

  get displayWarningMessage(): boolean {
    if (!this.stockInfo || this.stockInfo.hasUnlimitedStock) {
      return false;
    }
    return this.stockInfo.hasMissingStock || this.maxAvailableStock <= this.minimumStockForWarning;
  }

  get consumedStock() {
    return this.stockInfo?.getConsumedStock(this.cartItemId) || 0;
  }

  get maxAvailableStock(): number {
    return this.stockInfo?.item.stockQty || 0;
  }

  get type(): TagType {
    if (this.maxAvailableStock <= 0) {
      return 'warning';
    }
    return 'info';
  }

  get message(): string {
    if (this.maxAvailableStock === 0) {
      return this.t.translate('product.withoutStock');
    }
    if (this.maxAvailableStock === 1) {
      return this.t.translate('product.availableUnit');
    }
    return this.t.translate('product.availableUnits', { availableStock: this.maxAvailableStock });
  }
}
