import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  inject,
} from '@angular/core';
import { VirtualListService } from '../virtual-list.service';
import { isPlatformServer } from '@angular/common';
import { filter, fromEvent, throttleTime } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pxw-virtual-list-wrapper',
  templateUrl: './virtual-list-wrapper.component.html',
  styleUrls: ['./virtual-list-wrapper.component.scss'],
  providers: [VirtualListService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualListWrapperComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() scrollTarget: HTMLElement;
  @Input() silenceChangeDetector: boolean;
  @Input() debounceTime = 200;
  @Input() preloadOffset = 1000;
  @Input() set invalidateValue(value: unknown) {
    setTimeout(() => this.refresh());
  }

  private platformId = inject(PLATFORM_ID);

  private virtualWrapperService = inject(VirtualListService);
  private ngZone = inject(NgZone);

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollTarget, 'scroll')
        .pipe(
          filter(() => !this.silenceChangeDetector),
          throttleTime(this.debounceTime, undefined, { leading: true, trailing: true }),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.ngZone.run(() => {
            this.toggleRenderInVisibleElements();
          });
        });
    });
  }

  ngAfterContentInit(): void {
    if (isPlatformServer(this.platformId) || this.silenceChangeDetector) {
      return;
    }
    this.toggleRenderInVisibleElements();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformServer(this.platformId) || this.silenceChangeDetector) {
      return;
    }
    if (
      changes['silenceChangeDetector']?.currentValue !==
      changes['silenceChangeDetector']?.previousValue
    ) {
      this.toggleRenderInVisibleElements();
    }
  }

  refresh() {
    if (isPlatformServer(this.platformId) || this.silenceChangeDetector) {
      return;
    }
    this.toggleRenderInVisibleElements();
  }

  private toggleRenderInVisibleElements() {
    for (const virtualItem of this.virtualWrapperService.virtualItems) {
      const isIntersecting = this.childElementIsVisible(this.scrollTarget, virtualItem.hostElement);

      if (isIntersecting) {
        virtualItem.toggleItemTemplate(isIntersecting);
      }
    }
  }

  private childElementIsVisible(outerElement: HTMLElement, innerElement: HTMLElement) {
    const outerRect = outerElement.getBoundingClientRect();
    const innerRect = innerElement.getBoundingClientRect();

    const outerTopWithOffset = outerRect.top - this.preloadOffset;
    const outerBottomWithOffset = outerRect.bottom + this.preloadOffset;

    return innerRect.bottom > outerTopWithOffset && innerRect.top < outerBottomWithOffset;
  }
}
