import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ProductOptionItem, SUPPORTED_LANGUAGES } from '@pedix-workspace/utils';

import { NgClass, AsyncPipe } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { AvailableStockComponent, StockInfoPipe } from '@pedix-workspace/pedixapp-stock';
import { IconImageComponent } from '@pedix-workspace/angular-ui-icons';
import { PropInSelectedLanguagePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { ProductPriceComponent } from '../../product-price/product-price.component';

type ProductOptionItemSelector = Pick<
  ProductOptionItem,
  'id' | 'name' | 'description' | 'price' | 'priceDiscount' | 'sku' | 'image'
>;

@Component({
  selector: 'pxw-product-option-item-radio',
  templateUrl: './product-option-item-radio.component.html',
  styleUrls: ['./product-option-item-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    AsyncPipe,
    AvailableStockComponent,
    IconImageComponent,
    StockInfoPipe,
    PropInSelectedLanguagePipe,
    ProductPriceComponent,
  ],
})
export class ProductOptionItemRadioComponent {
  @Input() optionId: string;
  @Input() optionMin: number;
  @Input() item: ProductOptionItemSelector;
  @Input() cartItemId: string | undefined;
  @Input() displaySku: boolean;
  @Input() selectedLanguage: SUPPORTED_LANGUAGES | undefined;
  @Input() count: number;
  @Input() disableIncrement: boolean;
  @Input() displayItemQuantities: boolean;
  @Input() maxCount: number;

  @Output() itemImageClick = new EventEmitter();

  onItemImageClick($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.itemImageClick.emit();
  }
}
