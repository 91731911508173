import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, Renderer2, inject } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[click.zoneless],[scroll.zoneless]',
  standalone: true,
})
export class ZonelessEventDirective implements OnInit, OnDestroy {
  @Output('click.zoneless') clickFn = new EventEmitter<Event>();
  @Output('scroll.zoneless') scrollFn = new EventEmitter<Event>();

  private renderer = inject(Renderer2);
  private element = inject(ElementRef);
  private ngZone = inject(NgZone);

  private unsubscribe: () => void;

  get eventName() {
    if (this.clickFn.observed) {
      return 'click';
    } else if (this.scrollFn.observed) {
      return 'scroll';
    }
    return null;
  }

  get targetEvent() {
    if (this.clickFn.observed) {
      return this.clickFn
    } else if (this.scrollFn.observed) {
      return this.scrollFn;
    }
    return null;
  }

  ngOnInit() {
    if (this.eventName) {
      this.ngZone.runOutsideAngular(() => {
        this.unsubscribe = this.renderer.listen(this.element.nativeElement, this.eventName, e => {
          this.targetEvent?.emit(e);
        });
      });
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}