import { Component, OnInit, PLATFORM_ID, OnDestroy, inject } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import {
  CartItem,
  CartItemOptionItems,
  SharedOption,
  ProductOptionItem,
  CartItemCategory,
  CartItemProduct,
} from '@pedix-workspace/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';

import { filter, map } from 'rxjs/operators';
import { isPlatformServer, NgClass, AsyncPipe, isPlatformBrowser } from '@angular/common';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { getCartItemSubtotal } from '@pedix-workspace/orders';
import { nanoid } from 'nanoid';
import { GalleryImage } from '@pedix-workspace/angular-ui-image-gallery';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';
import { ZoomImage } from '@pedix-workspace/angular-ui-image-zoom';
import {
  ImageStoragePipe,
  EstablishmentUrlPipe,
  PriceDiscountPipe,
} from '@pedix-workspace/pedixapp-shared-pipes';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { StockService } from '@pedix-workspace/pedixapp-stock';
import { StockInfo, StockProduct } from '@pedix-workspace/shared-stock';
import { SeoService } from '../../main/seo.service';
import { EstablishmentWorkingHoursService } from '../../main/establishment-working-hours.service';
import { WhatsappLinkService } from '@pedix-workspace/angular-utils';
import { ApiV1_Category, ApiV1_Establishment, ApiV1_Product } from '@pedix-workspace/api-v1';
import { isProductWithoutStock } from '@pedix-workspace/shared-models';
import { IsCategoryAvailablePipe } from '../categories/isCategoryAvailable.pipe';
import { StockInfoPipe, AvailableStockComponent } from '@pedix-workspace/pedixapp-stock';
import {
  PropInSelectedLanguagePipe,
  CurrencyFormatPipe,
} from '@pedix-workspace/pedixapp-shared-pipes';
import {
  CounterComponent,
  ProductPriceComponent,
  ProductOptionSelectorComponent,
  ProductTagComponent,
} from '@pedix-workspace/pedixapp-shared-catalog';
import { ProductFooterComponent } from './product-footer/product-footer.component';
import { RichTextComponent } from '@pedix-workspace/angular-ui-rich-text';
import { ImageZoomComponent } from '@pedix-workspace/angular-ui-image-zoom';
import { ImageGalleryComponent } from '@pedix-workspace/angular-ui-image-gallery';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import {
  IconBackComponent,
  IconLoadingComponent,
  IconShareComponent,
} from '@pedix-workspace/angular-ui-icons';
import { AppRequestContextService } from '../../../app-request-context.service';
import { AppCartItemsService } from '../../../app-cart-items.service';
import { InputTextareaComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { FormsModule, NgForm } from '@angular/forms';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { ProductTagsWithQuantitiesPipe } from '../product-tags/product-tags-with-quantities.pipe';

@UntilDestroy()
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [StockService],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    IconBackComponent,
    IconShareComponent,
    IconLoadingComponent,
    ButtonComponent,
    ImageGalleryComponent,
    ImageZoomComponent,
    AvailableStockComponent,
    RichTextComponent,
    ProductOptionSelectorComponent,
    InputTextareaComponent,
    ProductFooterComponent,
    CounterComponent,
    AsyncPipe,
    CurrencyFormatPipe,
    PropInSelectedLanguagePipe,
    StockInfoPipe,
    IsCategoryAvailablePipe,
    FormsModule,
    PriceDiscountPipe,
    ProductPriceComponent,
    TagComponent,
    RouterLink,
    ProductTagsWithQuantitiesPipe,
    ProductTagComponent,
  ],
})
export class ProductComponent implements OnInit, OnDestroy {
  paramsInput$ = new BehaviorSubject<{ categoryId: string; productId: string }>(undefined);
  product: ApiV1_Product;
  category: ApiV1_Category;
  sharedOptions: SharedOption[];
  cartItem: CartItem;
  cartItemId: string;
  productQuantity = 1;
  productObservations = '';
  editMode = false;
  selectedOptions: CartItemOptionItems[] = [];
  selectedPresentationOption: CartItemOptionItems;
  isCartDisabled = false;
  imageToZoom: ZoomImage | null;
  imageToZoomTitle: string;
  isZoomOpen = false;
  activeGalleryIndex = 0;
  galleryImages: GalleryImage[] = [];
  productShareUrl: SafeUrl;
  stockInfo: StockInfo;
  hasStockIssues: boolean;
  isCategoryDisabled: boolean;
  isWithoutStock = false;

  get whatsappLinkHttpTarget() {
    return this.whatsappLinkService.getWhatsappLinkHttpTarget();
  }

  get establishment(): ApiV1_Establishment {
    return this.appRequestContext.establishment;
  }

  get displayShareButton(): boolean {
    return this.establishment.seoSocialMedia
      ? this.establishment.seoSocialMedia.displayShareButton
      : true;
  }

  get selectedLanguage() {
    return this.appRequestContext.selectedLanguage;
  }

  get maxQuantity(): number | undefined {
    if (this.product.presentations) {
      if (!this.product.presentations.displayItemQuantities && this.selectedPresentationOption) {
        return this.selectedPresentationOption.items[0].stockQty ?? undefined;
      }
    } else {
      return this.product.stockQty ?? undefined;
    }
    return undefined;
  }

  get displaySku(): boolean {
    return this.appRequestContext.shouldDisplaySku;
  }

  private platformId = inject(PLATFORM_ID);

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private toastrService = inject(ToastrService);
  private analytics = inject(AnalyticsService);
  private seoService = inject(SeoService);
  private t = inject(TranslocoService);
  private whatsappLinkService = inject(WhatsappLinkService);
  private establishmentUrlPipe = inject(EstablishmentUrlPipe);
  private imageStoragePipe = inject(ImageStoragePipe);
  private appRequestContext = inject(AppRequestContextService);
  private stockService = inject(StockService);
  private establishmentWorkingHours = inject(EstablishmentWorkingHoursService);
  private appCartItemsService = inject(AppCartItemsService);

  ngOnInit() {
    // ProductComponent uses its own version of StockService (check providers[]); this is on purpose, leave it this way
    this.stockService.products$.next(this.appRequestContext.products as StockProduct[]);
    this.stockService.cartItems$.next(this.appCartItemsService.cartItems);

    // Produces productId$ next value on route change
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.categoryId && params.productId) {
        this.cartItemId = nanoid();
        this.paramsInput$.next({
          categoryId: params.categoryId,
          productId: params.productId,
        });
      } else if (params.cartItemId && !isPlatformServer(this.platformId)) {
        const cartItem = this.appCartItemsService.findCartItem(params.cartItemId);

        this.editMode = true;
        this.cartItem = cloneDeep(cartItem);
        this.cartItemId = cartItem.id;
        this.productQuantity = this.cartItem.qty;
        this.productObservations = this.cartItem.observations;
        this.selectedPresentationOption = this.cartItem.selectedPresentation;

        if (!this.cartItem) {
          this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
          return;
        }

        this.paramsInput$.next({
          categoryId: this.cartItem.category.id,
          productId: this.cartItem.product.id,
        });
      }
    });

    this.paramsInput$
      .pipe(
        filter(({ productId }) => {
          return !!productId;
        }),
        map(({ categoryId, productId }) => {
          const category = this.appRequestContext.categories.find(c => c.id === categoryId);
          const product = category.products.find(
            categoryProduct => categoryProduct.id === productId,
          );

          return [category, product] as const;
        }),
        untilDestroyed(this),
      )
      .subscribe(([category, product]) => {
        this.category = category;
        this.product = cloneDeep(product);
        this.isWithoutStock = isProductWithoutStock({
          stockQty: this.product.stockQty,
          presentationItems: this.product?.presentations?.items,
        });

        this.galleryImages = this.product.images.map(image => this.getGalleryImages(image));
        this.imageToZoom = this.galleryImages[this.activeGalleryIndex];
        this.imageToZoomTitle = this.product.name;
        this.productShareUrl = this.getProductShareUrl();

        this.seoService.setProductMetadata(product, this.establishment);
        this.analytics.viewProduct(
          product,
          category,
          this.establishment.categories ? this.establishment.categories[0] : null,
        );

        if (this.cartItem?.selectedOptions) {
          this.selectedOptions = this.product.options.map(embeededOption =>
            this.cartItem.selectedOptions.find(
              cartItemOption => cartItemOption.option.id === embeededOption.id,
            ),
          );
        }
      });

    if (isPlatformBrowser(this.platformId)) {
      combineLatest([this.paramsInput$, this.stockService.stock$])
        .pipe(untilDestroyed(this))
        .subscribe(([{ productId }, stock]) => {
          this.stockInfo = stock.getStockInfoByItem(productId);

          const cartItem = this.createOrUpdateCartItem();

          this.hasStockIssues = stock
            .getStockInfoArrayByCartItem(cartItem.id)
            .some(stockInfo => stockInfo.getMissingStockByCartItem(this.cartItemId) > 0);
        });
    }

    this.establishmentWorkingHours.openedStatus$
      .pipe(untilDestroyed(this))
      .subscribe(({ isCartDisabled }) => {
        this.isCartDisabled = isCartDisabled;
      });
  }

  onStockSelectionChanged() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const cartItem = this.createOrUpdateCartItem();

    this.stockService.cartItemOverride = cartItem;
  }

  ngOnDestroy() {
    this.seoService.setDefaultMetadata();
  }

  back() {
    if (this.editMode) {
      this.router.navigate([`../../`], { relativeTo: this.activatedRoute });
    } else {
      if (
        this.activatedRoute.snapshot.queryParams['q'] ||
        this.activatedRoute.snapshot.queryParams['qt']
      ) {
        this.router.navigate([`/busqueda`], {
          queryParams: {
            q: this.activatedRoute.snapshot.queryParams['q'],
            qt: this.activatedRoute.snapshot.queryParams['qt'],
          },
        });
      } else if (this.activatedRoute.snapshot.queryParams['t']) {
        this.router.navigate([`/productos-relacionados`], {
          queryParams: { t: this.activatedRoute.snapshot.queryParams['t'] },
        });
      } else {
        this.router.navigate(['../..'], {
          relativeTo: this.activatedRoute,
        });
      }
    }
  }

  addToCart($event: FormDataEvent, productForm: NgForm, product: ApiV1_Product) {
    if (!productForm.valid) {
      this.toastrService.error(this.t.translate('validation.fixErrors'));

      return;
    }
    const cartItem = this.createOrUpdateCartItem();

    if (this.editMode) {
      this.appCartItemsService.updateCartItem(cartItem);
    } else {
      this.appCartItemsService.addCartItem(cartItem);
    }
    this.back();
  }

  get cartItemSubtotal() {
    return getCartItemSubtotal(
      {
        product: this.product,
        qty: this.productQuantity,
        selectedPresentation: this.selectedPresentationOption,
        selectedOptions: this.selectedOptions,
      },
      { usePriceDiscount: false },
    );
  }

  get cartItemSubtotalWithDiscount() {
    return getCartItemSubtotal(
      {
        product: this.product,
        qty: this.productQuantity,
        selectedPresentation: this.selectedPresentationOption,
        selectedOptions: this.selectedOptions,
      },
      { usePriceDiscount: true },
    );
  }

  onImageZoom(zoomImage: ZoomImage | null) {
    this.imageToZoom = zoomImage;
    this.imageToZoomTitle = this.product.name;
    this.isZoomOpen = true;
  }

  onGalleryImageChange(galleryIndex: number) {
    this.imageToZoom = this.galleryImages[galleryIndex];
  }

  onItemImageClick(item: ProductOptionItem) {
    this.imageToZoom = this.getGalleryImages(item.image);
    this.isZoomOpen = true;
    this.imageToZoomTitle = item.name;
  }

  private createOrUpdateCartItem(): CartItem {
    const cartItemCategory: CartItemCategory = {
      id: this.category.id,
      name: this.category.name,
      image: this.category.image,
      availability: this.category.availability,
      establishmentId: this.establishment.id,
    };
    const cartItemProduct: CartItemProduct = {
      id: this.product.id,
      categoryId: this.category.id,
      establishmentId: this.establishment.id,
      description: this.product.description,
      name: this.product.name,
      sku: this.product.sku,
      price: this.product.price,
      priceDiscount: this.product.priceDiscount,
      stockQty: this.product.stockQty,
      groupName: this.product.groupName,
      images: this.product.images,
      linkedProductTagIds: this.product.linkedProductTagIds,
      presentations: this.product.presentations,
    };
    return cloneDeep({
      id: this.cartItemId,
      category: cartItemCategory,
      product: cartItemProduct,
      selectedPresentation: this.selectedPresentationOption,
      qty: this.productQuantity,
      observations: this.productObservations.trim(),
      selectedOptions: this.selectedOptions.filter(cartItemOption => Boolean(cartItemOption)),
    });
  }

  private getGalleryImages(image: string) {
    return {
      large: this.imageStoragePipe.transform(image, { size: '2000x2000' }),
      medium: this.imageStoragePipe.transform(image, { size: '800x800' }),
      thumb: this.imageStoragePipe.transform(image, { size: '250x250' }),
    };
  }

  private getProductShareUrl() {
    const productUrl = this.establishmentUrlPipe.transform(this.establishment, [
      'categoria',
      this.category.id,
      'producto',
      this.product.id,
    ]);

    const text = this.t.translate('product.shareText', { productUrl });

    return this.whatsappLinkService.getTrustedLink({ text });
  }
}
