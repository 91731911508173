import { Directive, ElementRef, Input, inject } from '@angular/core';

@Directive({ selector: '[pxwScrollBreakItem]', standalone: true })
export class ScrollBreakItemDirective {
  @Input({ required: true }) id: string;

  private elementRef = inject(ElementRef<HTMLElement>);

  get hostElement() {
    return this.elementRef.nativeElement;
  }

  get offsetTop() {
    return this.elementRef.nativeElement?.offsetTop;
  }
}
