import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="home"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconHomeComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.2756 22.7214L23.3732 6.37235C23.2587 6.25432 23.1227 6.16067 22.973 6.09678C22.8233 6.03289 22.6628 6 22.5007 6C22.3386 6 22.1781 6.03289 22.0284 6.09678C21.8787 6.16067 21.7427 6.25432 21.6282 6.37235L5.72582 22.7214C5.26253 23.1981 5 23.8455 5 24.5208C5 25.9229 6.10803 27.0629 7.47087 27.0629H9.14643V38.7289C9.14643 39.432 9.69852 40 10.3819 40H20.0299V31.1025H24.3539V40H34.6196C35.3029 40 35.855 39.432 35.855 38.7289V27.0629H37.5306C38.1869 27.0629 38.8162 26.7968 39.2795 26.3162C40.2408 25.3232 40.2408 23.7145 39.2756 22.7214Z"
    />
  </svg>`,
})
export class IconHomeComponent extends IconComponent {}
