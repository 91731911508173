import { NgZone, PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { Category, isCategoryAvailable } from '@pedix-workspace/utils';
import { map, startWith } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { runOutsideAngularScheduler } from '../../../utils/zone-schedulers';

@Pipe({
  name: 'isCategoryAvailable$',
  standalone: true,
})
export class IsCategoryAvailablePipe implements PipeTransform {
  private platformId = inject(PLATFORM_ID);

  private ngZone = inject(NgZone);

  constructor() {}

  transform(value: Pick<Category, 'availability'>): Observable<boolean> {
    if (isPlatformServer(this.platformId) || !value?.availability) {
      return of(true);
    }

    const secondsToMinuteOClock = 60 - new Date().getSeconds();

    return timer(secondsToMinuteOClock * 1000, 60000, runOutsideAngularScheduler(this.ngZone)).pipe(
      map(() => isCategoryAvailable(value.availability)),
      startWith(isCategoryAvailable(value.availability)),
    );
  }
}
