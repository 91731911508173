<ng-container *transloco="let t">
  <header>
    <div class="home-button">
      @if (!hasSubsidiaries) {
        <pxw-icon
          class="menu-toggle"
          icon="menu"
          color="theme1-contrast"
          (click)="onClickLateralMenu($event)"
        ></pxw-icon>
      }
      <a class="ui-item-link logo-wrapper" [routerLink]="['/']">
        @if (establishmentLogo) {
          <img
            class="logo"
            [src]="establishmentLogo | imageStorage: { size: '250x250' }"
            alt="Logo {{ establishmentName }}"
          />
        } @else {
          <span class="establishment-name">{{ establishmentName }}</span>
        }
      </a>
    </div>
    @if (!searchEnabled) {
      <div class="actions">
        @if (displayAdminButton) {
          <div class="ui-item-link admin-action preview">
            <pxw-button
              size="sm"
              color="theme1-contrast"
              [rounded]="true"
              [href]="adminUrl"
              rel="nofollow"
              target="_blank"
              (click)="clickOption('settings')"
            >
              {{ t('header.goToAdminShort') }}
            </pxw-button>
            <pxw-button
              size="md"
              color="theme1-contrast"
              [rounded]="true"
              [href]="adminUrl"
              rel="nofollow"
              target="_blank"
              (click)="clickOption('settings')"
            >
              {{ t('header.goToAdminLong') }}
            </pxw-button>
          </div>
        }
        @if (availableLanguages.length > 1) {
          <span class="ui-item-link admin-action language-selector">
            <div #dropdown="ngbDropdown" ngbDropdown [autoClose]="true" placement="bottom-right">
              <span class="selected-language" ngbDropdownToggle>
                {{ selectedLanguage }}
              </span>
              <div ngbDropdownMenu style="margin-top: 0.5em">
                @for (language of availableLanguages; track language) {
                  <button
                    ngbDropdownItem
                    [disabled]="language === selectedLanguage"
                    (click)="languageSelected.emit(language)"
                  >
                    <!-- t(language.en, language.es)-->
                    {{ t('language.' + language) }}
                    @if (language === selectedLanguage) {
                      <span>({{ t('header.currentLanguage') }})</span>
                    }
                  </button>
                }
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem class="language-selector__close" (click)="dropdown.close()">
                  {{ t('global.cancel') }}
                </button>
              </div>
              <div class="ui-dropdown__backdrop" [hidden]="!dropdown.isOpen()"></div>
            </div>
          </span>
        }
        @if (hasCartItems) {
          <a
            class="ui-item-link admin-action cart-wrapper"
            rel="nofollow"
            [routerLink]="['/resumen']"
          >
            <pxw-icon icon="checkout" size="md" color="theme1-contrast"></pxw-icon>
            <span class="dot"></span>
          </a>
        }
        @if (!hasSubsidiaries) {
          <span class="ui-item-link action-item">
            <pxw-icon
              icon="search"
              size="sm"
              color="theme1-contrast"
              (click)="onSearchOpen()"
            ></pxw-icon>
          </span>
        }
      </div>
    }
    <pxw-input-text
      #searchInput
      name="search"
      class="search-text-input"
      [ngModel]="searchText"
      [placeholder]="t('header.searchProduct')"
      [autofocus]="true"
      [hidden]="!searchEnabled"
      inputMode="search"
      (keyUp)="onSearchKeyUp($event)"
      (focusOut)="onSearchFocusOut()"
    >
      <span class="addon-append">
        <pxw-icon
          class="ui-clickable"
          icon="close"
          size="xs"
          color="silver"
          (click)="onSearchClose()"
        ></pxw-icon>
      </span>
    </pxw-input-text>
  </header>
</ng-container>
