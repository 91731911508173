import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { ItemListGroup } from './item-list.component';
import { SortProductsByStockPipe } from '@pedix-workspace/pedixapp-shared-pipes';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pxwItemListGroupProductsByCategory',
  standalone: true,
})
export class ItemListGroupProductsByCategoryPipe<
  P extends { categoryId: string },
  C extends { id: string; name: string },
> implements PipeTransform
{
  private sortProductsByStockPipe = inject(SortProductsByStockPipe);

  transform(products: P[], categories: C[]): ItemListGroup<C, P>[] {
    const categoriesMap = new Map<string, { category: C; products: P[] }>();

    categories?.forEach(category => {
      categoriesMap.set(category.id, {
        category,
        products: [],
      });
    });

    products?.forEach(product => {
      categoriesMap.get(product.categoryId)?.products.push(product);
    });

    let listIndex = 0;

    return Array.from(categoriesMap.values())
      .filter(map => map.products.length > 0)
      .map(map => {
        return {
          group: map.category,
          indexedItems: this.sortProductsByStockPipe.transform(map.products).map(product => ({
            listIndex: listIndex++,
            item: product,
          })),
        };
      });
  }
}
