<ng-container *transloco="let t">
  <section class="content-products">
    <div class="ui-title">
      <a class="ui-item-link" [routerLink]="['../']">
        <pxw-icon icon="back" />
      </a>
      <span class="category-title">
        {{ category | propInSelectedLanguage: 'name' : selectedLanguage : true }}
      </span>
      @if (productTagsWithQuantities.length > 0) {
        <pxw-button
          size="sm"
          [rounded]="true"
          color="theme2-contrast"
          (click)="onFilterProducts()"
          style="margin-left: 0.5em"
        >
          <pxw-icon icon="filter" />
          {{ t('productFiltersDialog.title') }}
        </pxw-button>
      }
    </div>

    @if (
      products
        | pxwItemListProductGroupingStrategy
          : { groupingStrategy, categories, undefinedGroupName: t('product.undefinedGroupName') };
      as productsByGroup
    ) {
      <pxw-item-list
        [groupingStrategy]="groupingStrategy"
        [itemListGroups]="productsByGroup"
        [emptyListText]="t('productList.emptyList')"
        [itemHeight]="100"
        [rememberScrollSettings]="{ key: 'category', context: category.id }"
        [autoHideSlider]="true"
      >
        <ng-template
          #layoutTemplate
          let-horizontalSliderTemplate="horizontalSliderTemplate"
          let-itemListTemplate="itemListTemplate"
        >
          <ng-container *ngTemplateOutlet="horizontalSliderTemplate" />

          <div>
            @if ((category | isCategoryAvailable$ | async) === false && !isCartDisabled) {
              <div class="not-available-wrapper">
                <pxw-alert class="not-available-alert" type="warning" size="sm">
                  <pxw-icon icon="clock" />
                  <div class="not-available-content">
                    {{ t('categoryAvailability.productsNotAvailable') }}
                    <pxw-button
                      color="yellow"
                      size="sm"
                      [rounded]="true"
                      (click)="categoryAvailabilityDialog.open(category)"
                      >{{ t('categoryAvailability.checkSchedule') }}</pxw-button
                    >
                  </div>
                </pxw-alert>
                <app-category-availability-dialog
                  #categoryAvailabilityDialog
                ></app-category-availability-dialog>
              </div>
            }
          </div>
          <ng-container *ngTemplateOutlet="itemListTemplate" />
        </ng-template>
        <ng-template #listItemTemplate let-product="item" let-listIndex="index">
          <app-product-list-item
            class="product-list-item"
            [id]="product.id"
            [name]="product.name"
            [image]="product.images?.[0] | imageStorage: { size: '250x250' }"
            [price]="product.price"
            [priceDiscount]="product.priceDiscount"
            [sku]="product.sku"
            [displaySku]="displaySku"
            [stockQty]="product.stockQty"
            [tag]="product.primaryTag"
            [presentationItems]="product.presentations?.items"
            [currencySymbol]="currencySymbol"
            [detailRoute]="['/categoria', category.id, 'producto', product.id]"
            [listIndex]="listIndex"
          ></app-product-list-item>
        </ng-template>
      </pxw-item-list>
    }

    <app-show-order></app-show-order>
  </section>

  @if (productTagsWithQuantities.length > 0) {
    <app-product-filters-dialog
      [tagsWithQuantities]="productTagsWithQuantities"
      [selectedTagIds]="selectedTagIds"
      (selectedTagIdsChange)="onSelectedTags($event)"
    />
  }
</ng-container>
