<ng-container *transloco="let t">
  <section class="content-categories">
    <div class="ui-title">
      {{ t('categories.sectionTitle') }}

      @if (productTagsWithQuantities.length > 0) {
        <pxw-button
          size="sm"
          [rounded]="true"
          color="theme2-contrast"
          (click)="onFilterProducts()"
          style="margin-left: 0.5em"
        >
          <pxw-icon icon="filter" />
          {{ t('productFiltersDialog.title') }}
        </pxw-button>
      }
    </div>
    <div #categoryList class="categories-wrapper" (scroll)="onScroll()">
      @if (!loading) {
        @if (categories && categories.length > 0) {
          <div class="category-list">
            @for (
              category of categories | appActiveCategories;
              track trackByCategoryId(i, category);
              let i = $index
            ) {
              <pxw-category-list-item
                [id]="category.id"
                [name]="category.name"
                [active]="category.active"
                [image]="category.image | imageStorage: { size: '500x500', quality: 80 }"
                [hideLabelInList]="category.hideLabelInList"
                [availability]="category.availability"
                [listIndex]="i"
                [scrollTarget]="categoryList"
                [detailRoute]="['./categoria', category.id]"
                [isAvailable]="category | isCategoryAvailable$ | async"
                [isCartDisabled]="isCartDisabled"
                [maxDiscount]="category.products | appActiveProducts | maxProductsDiscount"
                (clickNotAvailable)="displayAvailabilityForCategory($event)"
              ></pxw-category-list-item>
            }
          </div>
        } @else {
          <div class="without-categories">
            <h6>{{ t('categories.emptyList') }}</h6>
          </div>
        }
      } @else {
        <div class="ui-loader">
          <pxw-icon icon="loading" [spin]="true"></pxw-icon>
        </div>
      }
    </div>
    <app-show-order></app-show-order>
  </section>

  <app-category-availability-dialog></app-category-availability-dialog>

  @if (productTagsWithQuantities.length > 0) {
    <app-product-filters-dialog
      [tagsWithQuantities]="productTagsWithQuantities"
      (selectedTagIdsChange)="onSelectedTags($event)"
    />
  }
</ng-container>
