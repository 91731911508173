import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="edit"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconEditComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00843 39H9.18843L17.5285 38.2372C18.4421 38.1459 19.2966 37.7419 19.9486 37.0931L37.9488 19.0279C38.6474 18.2871 39.025 17.2987 38.9987 16.279C38.9725 15.2594 38.5446 14.2918 37.8088 13.5883L32.3287 8.08841C31.6135 7.41417 30.6762 7.0273 29.6953 7.00139C28.7144 6.97548 27.7582 7.31235 27.0086 7.9479L9.00843 26.0131C8.36195 26.6674 7.95943 27.525 7.86842 28.4419L7.00841 36.8121C6.98146 37.1061 7.01947 37.4024 7.11973 37.68C7.21998 37.9575 7.38001 38.2095 7.58841 38.4179C7.77529 38.6039 7.99693 38.7511 8.24061 38.851C8.48428 38.9509 8.74521 39.0015 9.00843 39ZM29.5487 10.8986L35.0087 16.3783L31.0087 20.2925L25.6486 14.913L29.5487 10.8986ZM11.7485 28.7831L23.0086 17.5626L28.4086 22.9822L17.2085 34.2227L11.2085 34.7848L11.7485 28.7831Z"
    />
  </svg>`,
})
export class IconEditComponent extends IconComponent {}
