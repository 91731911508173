import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Category, formatDaysOfWeek, formatHour } from '@pedix-workspace/utils';

import { NgClass } from '@angular/common';

import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { ImageComponent } from '@pedix-workspace/angular-ui-image';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import {
  IconClockComponent,
  IconCrossArrowsComponent,
  IconEditComponent,
  IconEyeSlashComponent,
  IconTagsComponent,
  IconTrashCanComponent,
} from '@pedix-workspace/angular-ui-icons';

@Component({
  selector: 'pxw-category-list-item',
  templateUrl: 'category-list-item.component.html',
  styleUrls: ['category-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    NgClass,
    ImageComponent,
    TagComponent,
    IconEyeSlashComponent,
    IconClockComponent,
    IconTagsComponent,
    IconEditComponent,
    IconTrashCanComponent,
    IconCrossArrowsComponent,
  ],
})
export class CategoryListItemComponent {
  @Input() id: string;
  @Input() name: string;
  @Input() active: boolean;
  @Input() image: string;
  @Input() hideLabelInList: boolean;
  @Input() availability: Category['availability'];
  @Input() isDynamic = false;
  @Input() listIndex: number;
  @Input() sortEnabled: boolean;
  @Input() scrollTarget: HTMLElement;
  @Input() isAdminMode: boolean;
  @Input() detailRoute: string[];
  @Input() detailRouteQueryParams: Record<string, string>;
  @Input() isAvailable = true;
  @Input() isCartDisabled = false;
  @Input() maxDiscount: number;

  @Output() clickEdit = new EventEmitter<Pick<Category, 'id' | 'name' | 'availability'>>();
  @Output() clickDelete = new EventEmitter<Pick<Category, 'id' | 'name' | 'availability'>>();
  @Output() clickNotAvailable = new EventEmitter<Pick<Category, 'id' | 'name' | 'availability'>>();

  LAZY_LOAD_IMAGE_MIN_INDEX = 9;

  get displayNotAvailableTag(): boolean {
    return !this.isAvailable && !this.isCartDisabled;
  }

  get displayOverlay(): boolean {
    if (this.displayNotAvailableTag) {
      return true;
    }
    if (this.hideLabelInList) {
      return false;
    }
    return true;
  }

  get availabilityText(): string {
    if (!this.availability) {
      return '';
    }
    const textParts = [];
    const { daysOfWeek, timeRange } = this.availability;

    if (daysOfWeek?.length > 0) {
      textParts.push(formatDaysOfWeek(daysOfWeek));
    }
    if (timeRange?.from && timeRange?.to) {
      textParts.push(`${formatHour(timeRange.from)} a ${formatHour(timeRange.to)}`);
    }

    return textParts.join(' de ');
  }

  get entity(): Pick<Category, 'id' | 'name' | 'availability'> {
    return { id: this.id, name: this.name, availability: this.availability };
  }

  onClickEdit(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.clickEdit.emit(this.entity);
  }

  onClickDelete(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.clickDelete.emit(this.entity);
  }

  onClickNotAvailable(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.clickNotAvailable.emit(this.entity);
  }
}
