import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ProductOptionItem, SUPPORTED_LANGUAGES } from '@pedix-workspace/utils';

import { NgClass, AsyncPipe } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { IconImageComponent } from '@pedix-workspace/angular-ui-icons';
import { AvailableStockComponent, StockInfoPipe } from '@pedix-workspace/pedixapp-stock';
import { PropInSelectedLanguagePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { CounterComponent } from '../../counter/counter.component';
import { ProductPriceComponent } from '../../product-price/product-price.component';

type ProductOptionItemSelector = Pick<
  ProductOptionItem,
  'id' | 'name' | 'description' | 'price' | 'priceDiscount' | 'sku' | 'image'
>;

@Component({
  selector: 'pxw-product-option-item-checkbox',
  templateUrl: './product-option-item-checkbox.component.html',
  styleUrls: ['./product-option-item-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    CounterComponent,
    AsyncPipe,
    IconImageComponent,
    AvailableStockComponent,
    StockInfoPipe,
    PropInSelectedLanguagePipe,
    ProductPriceComponent,
  ],
})
export class ProductOptionItemCheckboxComponent {
  @Input() optionId: string;
  @Input() optionMin: number;
  @Input() item: ProductOptionItemSelector;
  @Input() cartItemId: string | undefined;
  @Input() displaySku: boolean;
  @Input() selectedLanguage: SUPPORTED_LANGUAGES | undefined;
  @Input() count: number;
  @Input() disableIncrement: boolean;
  @Input() displayItemQuantities: boolean;
  @Input() maxCount: number;

  @Output() countChange = new EventEmitter<number>();
  @Output() itemImageClick = new EventEmitter();

  get isChecked(): boolean {
    return this.count > 0;
  }

  onItemImageClick($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.itemImageClick.emit();
  }

  onCountChange(count: number) {
    this.countChange.emit(count);
  }
}
