import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="advertisement"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconAdvertisementComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.1515 10.321C35.7606 10.0736 35.3135 9.9239 34.8497 9.8851C34.3858 9.84631 33.9193 9.91961 33.4911 10.0985L19.4619 15.5047C19.1297 15.6369 18.7745 15.7049 18.4159 15.7049H9.43447C8.68066 15.7049 7.95773 15.9979 7.42471 16.5194C6.89168 17.041 6.59223 17.7483 6.59223 18.4858V18.7083H3.75V25.3826H6.59223V25.6718C6.61005 26.3977 6.91734 27.088 7.44844 27.5952C7.97955 28.1024 8.69233 28.3862 9.43447 28.386L12.8451 35.4607C13.0761 35.9371 13.4397 36.3401 13.8942 36.6235C14.3487 36.9068 14.8756 37.059 15.4145 37.0625H16.847C17.5969 37.0566 18.314 36.7611 18.8421 36.2402C19.3702 35.7193 19.6665 35.0153 19.6665 34.2816V28.653L33.4911 34.0591C33.8312 34.1915 34.1938 34.2594 34.5598 34.2593C35.1276 34.2504 35.6805 34.0803 36.1515 33.7699C36.5255 33.5228 36.8339 33.1922 37.0514 32.8053C37.2689 32.4184 37.3891 31.9865 37.4021 31.5451V12.6125C37.4 12.1598 37.285 11.7145 37.067 11.3151C36.8491 10.9158 36.5348 10.5745 36.1515 10.321ZM16.8243 18.4858V25.6718H9.43447V18.4858H16.8243ZM16.8243 34.2816H15.3918L12.595 28.386H16.8243V34.2816ZM20.5078 26.0055C20.2378 25.8705 19.9563 25.7588 19.6665 25.6718V18.3301C19.9536 18.2722 20.235 18.1903 20.5078 18.0854L34.5598 12.6125V31.4784L20.5078 26.0055ZM37.4703 19.2645V24.8264C38.2241 24.8264 38.947 24.5334 39.48 24.0119C40.0131 23.4903 40.3125 22.783 40.3125 22.0454C40.3125 21.3079 40.0131 20.6006 39.48 20.079C38.947 19.5575 38.2241 19.2645 37.4703 19.2645Z"
      />
    </svg>
  `,
})
export class IconAdvertisementComponent extends IconComponent {}
