import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="filter"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconFilterComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9992 37.8801C28.0792 38.4467 27.8792 39.0511 27.4192 39.4477C27.2342 39.6228 27.0145 39.7617 26.7726 39.8564C26.5307 39.9512 26.2713 40 26.0094 40C25.7475 40 25.4882 39.9512 25.2463 39.8564C25.0044 39.7617 24.7846 39.6228 24.5996 39.4477L16.5807 31.8743C16.3626 31.6729 16.1968 31.4266 16.0962 31.1547C15.9956 30.8828 15.963 30.5925 16.0008 30.3067V20.6369L6.42216 9.05958C6.09742 8.66586 5.95089 8.16675 6.01459 7.67131C6.07829 7.17587 6.34704 6.72438 6.76211 6.4155C7.14206 6.15109 7.562 6 8.00194 6H35.9981C36.438 6 36.8579 6.15109 37.2379 6.4155C37.653 6.72438 37.9217 7.17587 37.9854 7.67131C38.0491 8.16675 37.9026 8.66586 37.5778 9.05958L27.9992 20.6369V37.8801ZM12.0814 9.77727L20.0003 19.3337V29.759L23.9997 33.5363V19.3149L31.9186 9.77727H12.0814Z"
    />
  </svg>`,
})
export class IconFilterComponent extends IconComponent {}
