import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="checkout"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCheckoutComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 32C14.85 32 13.515 33.35 13.515 35C13.515 36.65 14.85 38 16.5 38C18.15 38 19.5 36.65 19.5 35C19.5 33.35 18.15 32 16.5 32ZM31.5 32C29.85 32 28.515 33.35 28.515 35C28.515 36.65 29.85 38 31.5 38C33.15 38 34.5 36.65 34.5 35C34.5 33.35 33.15 32 31.5 32ZM18.15 24.5H29.325C30.45 24.5 31.44 23.885 31.95 22.955L37.5 12.44L34.875 11L29.325 21.5H18.795L12.405 8H7.5V11H10.5L15.9 22.385L13.875 26.045C12.78 28.055 14.22 30.5 16.5 30.5H34.5V27.5H16.5L18.15 24.5ZM24 8L30 14L24 20L21.885 17.885L24.255 15.5H18V12.5H24.255L21.87 10.115L24 8Z"
      />
    </svg>
  `,
})
export class IconCheckoutComponent extends IconComponent {}
