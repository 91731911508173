import { inject } from '@angular/core';
import { Injectable } from "@angular/core";
import { CurrencyLocaleData } from "@pedix-workspace/utils";
import { CurrencyFormatService } from "@pedix-workspace/pedixapp-shared-pipes";
import { AppRequestContextService } from "./app-request-context.service";

@Injectable()
export class AppCurrencyFormatService extends CurrencyFormatService {

  private appRequestContextService = inject(AppRequestContextService);

  get currencyLocaleData(): CurrencyLocaleData {
    return this.appRequestContextService.currencyLocaleData;
  }
}
