import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="trash-can"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconTrashCanComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5625 9.84687H16.25C16.4219 9.84687 16.5625 9.70766 16.5625 9.5375V9.84687H28.4375V9.5375C28.4375 9.70766 28.5781 9.84687 28.75 9.84687H28.4375V12.6312H31.25V9.5375C31.25 8.17238 30.1289 7.0625 28.75 7.0625H16.25C14.8711 7.0625 13.75 8.17238 13.75 9.5375V12.6312H16.5625V9.84687ZM36.25 12.6312H8.75C8.05859 12.6312 7.5 13.1843 7.5 13.8687V15.1062C7.5 15.2764 7.64062 15.4156 7.8125 15.4156H10.1719L11.1367 35.641C11.1992 36.9597 12.3008 38 13.6328 38H31.3672C32.7031 38 33.8008 36.9636 33.8633 35.641L34.8281 15.4156H37.1875C37.3594 15.4156 37.5 15.2764 37.5 15.1062V13.8687C37.5 13.1843 36.9414 12.6312 36.25 12.6312ZM31.0664 35.2156H13.9336L12.9883 15.4156H32.0117L31.0664 35.2156Z"
    />
  </svg>`,
})
export class IconTrashCanComponent extends IconComponent {}
