import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  signal,
  Output,
  EventEmitter,
} from '@angular/core';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';

import { TranslocoDirective } from '@ngneat/transloco';
import {
  IconBanComponent,
  IconCheckComponent,
  IconFilterComponent,
} from '@pedix-workspace/angular-ui-icons';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { ApiV1_ProductTag } from '@pedix-workspace/api-v1';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';

@Component({
  selector: 'app-product-filters-dialog',
  templateUrl: './product-filters-dialog.component.html',
  styleUrls: ['./product-filters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    ModalDialogComponent,
    IconFilterComponent,
    TagComponent,
    ButtonComponent,
    IconBanComponent,
    IconCheckComponent,
  ],
})
export class ProductFiltersDialogComponent {
  @ViewChild(ModalDialogComponent) modalDialog: ModalDialogComponent;

  @Input() tagsWithQuantities: { productTag: ApiV1_ProductTag; count: number }[] = [];
  @Input() selectedTagIds: string[] = [];

  @Output() selectedTagIdsChange = new EventEmitter<string[]>();

  #selectedTagIdsMap = signal<Record<string, true>>({});

  get selectedTagIdsMap() {
    return this.#selectedTagIdsMap();
  }

  open() {
    if (Array.isArray(this.selectedTagIds)) {
      this.#selectedTagIdsMap.set(
        this.selectedTagIds.reduce((tagsMap, tagId) => {
          tagsMap[tagId] = true;

          return tagsMap;
        }, {}),
      );
    }
    this.modalDialog
      .open()
      .then(() => {
        this.selectedTagIdsChange.emit(Object.keys(this.selectedTagIdsMap));
      })
      .catch(() => {});
  }

  toggleSelectedTag(selectedTag: string) {
    this.#selectedTagIdsMap.update(selectedTagIdsMap => {
      if (selectedTagIdsMap[selectedTag]) {
        delete selectedTagIdsMap[selectedTag];
      } else {
        selectedTagIdsMap[selectedTag] = true;
      }
      return selectedTagIdsMap;
    });
  }

  onRemoveFilters() {
    this.#selectedTagIdsMap.set({});

    this.modalDialog.close();
  }

  onApplyFilters() {
    this.modalDialog.close();
  }
}
