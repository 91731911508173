import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { RESPONSE } from '../../../../express.tokens';
import { Response } from 'express';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, ButtonComponent],
})
export class NotFoundComponent implements OnInit {
  private platformId = inject(PLATFORM_ID);
  private response = inject(RESPONSE, { optional: true });


  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.response.status(404);
    }
  }

  onCtaButtonClicked() {
    location.href = environment.landingUrl;
  }
}
