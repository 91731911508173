import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  inject,
} from '@angular/core';
import { ScrollBreakItemDirective } from './scroll-break-item.directive';
import { isPlatformBrowser } from '@angular/common';

@Directive({ selector: '[pxwScrollBreaksWrapper]', standalone: true })
export class ScrollBreaksWrapperDirective implements AfterViewInit, OnDestroy {
  @ContentChildren(ScrollBreakItemDirective, { descendants: true })
  scrollBreakItemsQuery: QueryList<ScrollBreakItemDirective>;

  @Input() scrollOffset = 0;
  @Input() set invalidateValue(value: unknown) {
    if (this.lastInvalidateValue && this.lastInvalidateValue !== value) {
      setTimeout(() => this.refresh());
    }
    this.lastInvalidateValue = value;
  }

  @Output() breakItemActiveChange = new EventEmitter<ScrollBreakItemDirective>();

  private platformId = inject(PLATFORM_ID);
  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private renderer = inject(Renderer2);
  private ngZone = inject(NgZone);
  private cd = inject(ChangeDetectorRef);

  private autoScrolling = false;
  private lastInvalidateValue: unknown;

  observer: IntersectionObserver | null = null;
  itemsVisibility: IntersectionObserverEntry[] | null = null;
  isObserverOnline = false;

  get breakItemDirectives() {
    return this.scrollBreakItemsQuery?.toArray() || [];
  }

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');

    if (isPlatformBrowser(this.platformId)) {
      this.observer = new IntersectionObserver(
        entries => {
          if (!this.itemsVisibility) {
            const validEntries = entries.every(entry =>
              this.elementRef.nativeElement.contains(entry.target),
            );
            if (!validEntries) {
              return;
            }
            this.itemsVisibility = entries;
          } else {
            for (const entry of entries) {
              const entryIndex = this.itemsVisibility.findIndex(
                itemEntry => itemEntry.target === entry.target,
              );

              this.itemsVisibility[entryIndex] = entry;
            }
          }
          const firstIntersecting = this.itemsVisibility.find(entry => entry.isIntersecting);

          if (firstIntersecting) {
            const firstItem = this.findItemByHostElement(firstIntersecting.target);

            if (firstItem && !this.autoScrolling) {
              this.breakItemActiveChange.emit(firstItem);
            }
          }
        },
        { root: this.elementRef.nativeElement },
      );
      this.observeChanges();
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();

    this.observer = null;
  }

  scrollToItem(itemId: string, animated: boolean) {
    if (this.autoScrolling) {
      return;
    }
    const scrollBreakItem = this.breakItemDirectives.find(item => item.id === itemId);

    if (scrollBreakItem) {
      const maxScroll = Math.floor(
        this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.clientHeight,
      );
      const currentScrollTop = Math.floor(this.elementRef.nativeElement.scrollTop);
      const newScrollTop = Math.floor(scrollBreakItem.offsetTop + this.scrollOffset);

      if (newScrollTop > currentScrollTop && currentScrollTop === maxScroll) {
        return;
      }
      if (animated) {
        this.autoScrolling = true;

        this.elementRef.nativeElement.style.pointerEvents = 'none';

        this.setScrollEndCallback(this.elementRef.nativeElement).then(() => {
          this.autoScrolling = false;
          this.elementRef.nativeElement.style.pointerEvents = 'auto';
        });
      }
      this.elementRef.nativeElement?.scrollTo({
        top: newScrollTop,
        behavior: <ScrollBehavior>(animated ? 'smooth' : 'instant'),
      });
    }
  }

  refresh() {
    this.unobserveChanges();

    if (this.elementRef.nativeElement) {
      this.elementRef.nativeElement.scrollTop = 0;
    }

    this.observeChanges();
  }

  private observeChanges() {
    if (this.observer === null) {
      return;
    }
    this.isObserverOnline = true;

    this.ngZone.runOutsideAngular(() => {
      if (this.observer === null) {
        return;
      }
      for (const virtualItem of this.breakItemDirectives) {
        this.observer.observe(virtualItem.hostElement);
      }
    });
  }

  private unobserveChanges() {
    if (this.observer === null || !this.isObserverOnline) {
      return;
    }
    this.isObserverOnline = false;
    this.itemsVisibility = null;

    for (const virtualItem of this.breakItemDirectives) {
      this.observer.unobserve(virtualItem.hostElement);
    }
  }

  private findItemByHostElement(hostElement: Element): ScrollBreakItemDirective | undefined {
    return this.scrollBreakItemsQuery.toArray().find(item => item.hostElement === hostElement);
  }

  private setScrollEndCallback(target: HTMLElement): Promise<void> {
    return new Promise(resolve => {
      let i: ReturnType<typeof setInterval> | null;

      if (target.clientHeight === target.scrollHeight) {
        resolve();
      }

      const debounceCb = () => {
        if (i) {
          clearInterval(i);
          i = null;
        }
        i = setInterval(() => {
          target.removeEventListener('scroll', debounceCb);

          setTimeout(resolve);
        }, 100);
      };
      this.ngZone.runOutsideAngular(() => {
        target.addEventListener('scroll', debounceCb);
      });
    });
  }
}
