<ng-container *transloco="let t">
  <section class="content-products">
    <div class="ui-title">
      <a class="ui-item-link" [routerLink]="['../']">
        <pxw-icon icon="back"></pxw-icon>
      </a>
      <div class="title-wrapper">
        {{ t('productsByTag.title') }}:&nbsp;
        <span class="tag-title">{{ productTag?.tag }}</span>
      </div>
    </div>
    <pxw-item-list
      groupingStrategy="category"
      [itemListGroups]="searchResults | pxwItemListGroupProductsByCategory: categories"
      [emptyListText]="t('productsByTag.emptyList')"
      [itemHeight]="90"
      [rememberScrollSettings]="{ key: 'products-by-tag', context: tagId }"
      [autoHideSlider]="true"
    >
      <ng-template #groupHeadingTemplate let-groupItem="groupItem" let-category="group">
        <h2 class="group-heading">
          <span class="title">
            {{ category.name }}
            <span class="count">({{ groupItem.indexedItems.length }})</span>
          </span>
        </h2>
        @if ((category | isCategoryAvailable$ | async) === false) {
          <div class="not-available-wrapper">
            <pxw-alert class="not-available-alert" type="warning" size="sm">
              <pxw-icon icon="clock" />
              <div class="not-available-content">
                {{ t('categoryAvailability.productsNotAvailable') }}
                <pxw-button
                  color="yellow"
                  size="sm"
                  [rounded]="true"
                  (click)="categoryAvailabilityDialog.open(category)"
                >
                  {{ t('categoryAvailability.checkSchedule') }}
                </pxw-button>
              </div>
            </pxw-alert>
            <app-category-availability-dialog
              #categoryAvailabilityDialog
            ></app-category-availability-dialog>
          </div>
        }
      </ng-template>
      <ng-template #listItemTemplate let-category="group" let-product="item" let-listIndex="index">
        <app-product-list-item
          class="product-list-item"
          [id]="product.id"
          [name]="product.name"
          [image]="product.images?.[0] | imageStorage: { size: '250x250' }"
          [price]="product.price"
          [priceDiscount]="product.priceDiscount"
          [sku]="product.sku"
          [displaySku]="displaySku"
          [isAvailable]="category | isCategoryAvailable$ | async"
          [stockQty]="product.stockQty"
          [tag]="product.primaryTag"
          [presentationItems]="product.presentations?.items"
          [currencySymbol]="currencySymbol"
          [detailRoute]="['/categoria', product.categoryId, 'producto', product.id]"
          [detailRouteQueryParams]="{ t: tagId }"
          [listIndex]="listIndex"
        ></app-product-list-item>
      </ng-template>
    </pxw-item-list>
  </section>
</ng-container>
