import { ApiV1_Product, ApiV1_ProductTag } from '@pedix-workspace/api-v1';

export function getUniqueTagsWithQuantities(
  products: Pick<ApiV1_Product, 'tags'>[],
): { productTag: ApiV1_ProductTag; count: number }[] {
  const tagsWithQuantities = {};

  products.forEach(product => {
    product.tags?.forEach(productTag => {
      if (tagsWithQuantities[productTag.id] === undefined) {
        tagsWithQuantities[productTag.id] = {
          productTag,
          count: 0,
        };
      }
      tagsWithQuantities[productTag.id].count++;
    });
  });

  return Object.values(tagsWithQuantities);
}
