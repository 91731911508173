import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="chevron-right"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconChevronRightComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.454 10.6106L24.1434 23.375L11.454 36.1394C11.1513 36.4439 11 36.8045 11 37.2212C11 37.6378 11.1513 37.9984 11.454 38.3029L15.4209 42.2933C15.7236 42.5978 16.0821 42.75 16.4963 42.75C16.9105 42.75 17.269 42.5978 17.5717 42.2933L35.3033 24.4567C35.606 24.1522 35.7573 23.7917 35.7573 23.375C35.7573 22.9583 35.606 22.5978 35.3033 22.2933L17.5717 4.45673C17.269 4.15224 16.9105 4 16.4963 4C16.0821 4 15.7236 4.15224 15.4209 4.45673L11.454 8.44712C11.1513 8.7516 11 9.11218 11 9.52885C11 9.94551 11.1513 10.3061 11.454 10.6106Z"
      />
    </svg>
  `,
})
export class IconChevronRightComponent extends IconComponent {}
