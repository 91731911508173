import { Injectable } from '@angular/core';
import { VirtualListItemComponent } from './virtual-list-item/virtual-list-item.component';

@Injectable()
export class VirtualListService {
  _virtualItems = new Map<string, VirtualListItemComponent>();

  get virtualItems() {
    return Array.from(this._virtualItems.values());
  }

  registerItem(id: string, item: VirtualListItemComponent) {
    this._virtualItems.set(id, item);
  }

  unregisterItem(id: string) {
    this._virtualItems.delete(id);
  }

  getItem(id: string) {
    return this._virtualItems.get(id);
  }
}
