import { Component, OnInit, Renderer2, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
  Establishment,
  EstablishmentOpenedStatus,
  LANGUAGE_COOKIE,
  LANGUAGE_QUERY,
  SUPPORTED_LANGUAGES,
  UserDataWithClaims,
} from '@pedix-workspace/utils';
import { ThemeService } from '@pedix-workspace/angular-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT, isPlatformBrowser, isPlatformServer, NgClass } from '@angular/common';
import { SeoService } from '../seo.service';
import { Meta } from '@angular/platform-browser';
import { DrawerComponent } from '@pedix-workspace/angular-ui-nav';
import { EstablishmentWorkingHoursService } from '../establishment-working-hours.service';
import { ApiV1_Establishment } from '@pedix-workspace/api-v1';
import { ModalHistoryComponent } from '@pedix-workspace/angular-ui-modal';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import {
  IconAdvertisementComponent,
  IconCloseComponent,
  IconLoadingComponent,
} from '@pedix-workspace/angular-ui-icons';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { WorkingHoursMessageComponent } from '../working-hours-message/working-hours-message.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { HeaderComponent } from '../header/header.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { AppRequestContextService } from '../../../app-request-context.service';
import { AppCartItemsService } from '../../../app-cart-items.service';
import { PixelModule, PixelService } from 'ngx-multi-pixel';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  providers: [EstablishmentWorkingHoursService],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    HeaderComponent,
    DrawerComponent,
    SideMenuComponent,
    WorkingHoursMessageComponent,
    AlertComponent,
    IconAdvertisementComponent,
    IconCloseComponent,
    IconLoadingComponent,
    RouterOutlet,
    ModalHistoryComponent,
    PixelModule,
  ],
})
export class AppLayoutComponent implements OnInit {
  static firstRender = true;

  @ViewChild(DrawerComponent) drawer: DrawerComponent;

  establishment: ApiV1_Establishment;
  sideMenuOpen: boolean | null = null;
  hasCartItems = false;
  displayAdminButton = false;
  loadingLanguage = false;
  selectedLanguage: SUPPORTED_LANGUAGES;
  availableLanguages: AVAILABLE_LANGUAGES;
  openedStatus: EstablishmentOpenedStatus;
  announcementsHidden = false;
  searchText = '';

  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private renderer = inject(Renderer2);
  private meta = inject(Meta);
  private themeService = inject(ThemeService);
  private analytics = inject(AnalyticsService);
  private seoService = inject(SeoService);
  private appRequestContextService = inject(AppRequestContextService);
  private appCartItemsService = inject(AppCartItemsService);
  private establishmentWorkingHours = inject(EstablishmentWorkingHoursService);
  private pixel = inject(PixelService);

  constructor() {}

  get hasSubsidiaries() {
    return this.establishment?.subsidiaries?.length > 0;
  }

  get displayWorkingHoursMessage() {
    if (this.hasSubsidiaries) {
      return false;
    }
    return (
      this.catalogIsEnabled &&
      (this.openedStatus.isClosed ||
        this.openedStatus.isAboutToClose ||
        this.openedStatus.isTemporaryClosed)
    );
  }

  get catalogIsEnabled() {
    if (
      this.openedStatus.isTemporaryClosed &&
      !this.establishment.temporaryClosure?.allowCatalogBrowsing
    ) {
      return false;
    }
    return true;
  }

  get displayAnnouncements(): boolean {
    if (isPlatformServer(this.platformId)) {
      return false;
    }
    if (this.announcementsHidden) {
      return false;
    }
    return Boolean(
      this.establishment.announcements?.title || this.establishment.announcements?.body,
    );
  }

  ngOnInit() {
    this.selectedLanguage = this.appRequestContextService.selectedLanguage;
    this.establishment = this.appRequestContextService.establishment;
    this.searchText = this.activatedRoute.snapshot.queryParams['q'];

    this.analytics.establishmentSlug = this.establishment.slug;
    this.analytics.establishmentCurrency = this.establishment.currencyCode;
    this.analytics.establishmentId = this.establishment.id;
    this.displayAdminButton =
      this.isEstablishmentOwner(this.establishment, this.appRequestContextService.user) &&
      !this.appRequestContextService.previewMode;

    if (AppLayoutComponent.firstRender) {
      AppLayoutComponent.firstRender = false;

      this.analytics.userLanded();
    }

    if (this.establishment.theme) {
      this.themeService.configureTheme(this.establishment.theme);
    } else {
      this.themeService.resetTheme();
    }

    this.renderer.addClass(this.document.documentElement, 'ready');

    this.pixel.initialize([environment.pedixPixelId]);

    // Facebook pixel
    if (this.establishment.facebookPixel) {
      this.pixel.remove();
      this.pixel.initialize([this.establishment.facebookPixel, environment.pedixPixelId]);
    }

    // Google Analytics
    if (this.establishment.googleAnalytics) {
      this.addPreconnectLink('gtm', 'https://www.googletagmanager.com');

      this.analytics.initializeGoogleAnalyticsTracking(this.establishment.googleAnalytics);
    }

    // Google Tag Manager
    if (
      this.establishment.features.includes('GOOGLE_TAG_MANAGER') &&
      this.establishment.googleTagManager
    ) {
      this.addPreconnectLink('gtm', 'https://www.googletagmanager.com');

      this.analytics.loadGoogleTagManagerScript(this.renderer, this.establishment.googleTagManager);
    }

    // Facebook Domain Verification
    if (this.establishment.facebookDomainVerification) {
      this.meta.addTag({
        name: 'facebook-domain-verification',
        content: this.establishment.facebookDomainVerification,
      });
    }

    // SEO page establishment metadata
    this.seoService.setDefaultMetadata();

    // Available languages
    this.availableLanguages = this.getAvailableLanguages();

    this.appCartItemsService.cartItemsObservable$
      .pipe(untilDestroyed(this))
      .subscribe(cartItems => {
        this.hasCartItems = cartItems.length > 0 ? true : false;
      });

    this.establishmentWorkingHours.openedStatus$
      .pipe(untilDestroyed(this))
      .subscribe(openedStatus => {
        this.openedStatus = openedStatus;
      });
  }

  onClickLateralMenu($event) {
    this.sideMenuOpen = !this.sideMenuOpen;

    const path = $event.path || $event?.composedPath();

    if (this.sideMenuOpen && (!path || !path.includes(document.querySelector('.home-button')))) {
      this.sideMenuOpen = false;
    }
  }

  onNavItemClick() {
    if (this.drawer.display === 'desktop') {
      return;
    }
    this.sideMenuOpen = false;
  }

  onLanguageSelected(selectedLanguage: SUPPORTED_LANGUAGES) {
    document.cookie = `${LANGUAGE_COOKIE}=${selectedLanguage}`;

    const url = new URL(window.location.href);

    // NOTE: cookie is not respected; there is a task to work on this issue, that's why we are also sending language in queryString
    url.searchParams.set(LANGUAGE_QUERY, selectedLanguage);
    // url.searchParams.delete(LANGUAGE_QUERY);

    this.selectedLanguage = selectedLanguage;
    this.loadingLanguage = true;

    setTimeout(() => {
      window.location.href = url.toString();
    });
  }

  onHideAnnouncements() {
    this.announcementsHidden = true;
  }

  protected onProductSearch(searchText: string) {
    this.searchText = searchText;

    if (this.searchText) {
      this.router.navigate(['/busqueda'], { queryParams: { q: searchText } });
    }
  }

  private isEstablishmentOwner(
    establishment: Pick<Establishment, 'userId'>,
    user: UserDataWithClaims | null,
  ) {
    if (!user) {
      return false;
    }
    if (user.claims.appSuperAdmin) {
      return true;
    }
    return establishment.userId === user.uid;
  }

  private addPreconnectLink(id: string, preconnectUrl: string) {
    if (isPlatformBrowser(this.platformId) || this.document.getElementById(id)) {
      return;
    }
    const preconnectLink = this.document.createElement('link');

    preconnectLink.id = id;
    preconnectLink.href = preconnectUrl;
    preconnectLink.rel = 'preconnect';

    this.renderer.appendChild(this.document.head, preconnectLink);
  }

  private getAvailableLanguages(): AVAILABLE_LANGUAGES {
    if (!this.establishment.features.includes('ALT_LANGUAGE') || !this.establishment.altLanguage) {
      return [DEFAULT_LANGUAGE];
    }
    return [DEFAULT_LANGUAGE, this.establishment.altLanguage];
  }
}
