@if (label) {
  <div class="ui-textarea-heading">
    <label
      class="ui-label ui-label--{{ labelSize }}"
      [ngClass]="{ 'ui-label--required': isRequired }"
      [attr.for]="inputId"
    >
      {{ label }}
    </label>
    @if (maxLength) {
      <span class="ui-textarea-heading__counter"> {{ valueLength }} / {{ maxLength }} </span>
    }
  </div>
}
<div class="ui-input__container">
  <div class="ui-input__editor">
    <textarea
      #input
      [id]="inputId"
      class="ui-input__input"
      type="text"
      [value]="value"
      [autofocus]="autofocus"
      [placeholder]="placeholder"
      [readonly]="isDisabled"
      [maxLength]="maxLength"
      [rows]="rows"
      (keyup)="onKeyUp($event)"
    ></textarea>
    @if (shouldDisplayErrors) {
      <pxw-icon class="ui-input__error-icon" icon="info" size="sm" />
    }
  </div>
</div>
@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<ng-content></ng-content>
