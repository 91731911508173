@if (displayWarningMessage) {
  <pxw-tag [size]="size" [type]="type">
    {{ message }}
  </pxw-tag>
}
@if (consumedStock > 0 && stockInfo?.hasMissingStock) {
  <pxw-tag [size]="size" type="warning" appareance="bare">
    <pxw-icon icon="alert" />
    En el carrito agregaste {{ stockInfo?.totalStockInCart }}
  </pxw-tag>
}
