import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class AppHereMapsConfigService {
  private t = inject(TranslocoService);

  getSelectAddressText(): string {
    return this.t.translate('deliveryAddress.selectAddress');
  }
  getConfirmAddressText(): string {
    return this.t.translate('deliveryAddress.confirmAddress');
  }
  getWrongAddressText(): string {
    return this.t.translate('deliveryAddress.wrongAddress');
  }
  getLocationSelectedOnMapText(): string {
    return this.t.translate('deliveryAddress.locationSelectedOnMap');
  }
  getLocateOnMapText(): string {
    return this.t.translate('deliveryAddress.placeYourselfOnTheMap');
  }
  getChangeLocationText(): string {
    return this.t.translate('deliveryAddress.changeLocation');
  }
  getAddressNotFoundText(): string {
    return this.t.translate('deliveryAddress.addressNotFound');
  }
  getAllAddressesWrongText(): string {
    return this.t.translate('deliveryAddress.allAddressesWrong');
  }
  getGeolocationErrorText(): string {
    return this.t.translate('deliveryAddress.geolocationError');
  }
  getMapLocationPopupButton(): string {
    return this.t.translate('mapLocationPopup.readyLabel');
  }
  getMapLocationPopupInstructions(): string[] {
    return [
      this.t.translate('mapLocationPopup.instructions1'),
      this.t.translate('mapLocationPopup.instructions2'),
      this.t.translate('mapLocationPopup.instructions3'),
    ];
  }
}
