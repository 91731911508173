<ng-container *transloco="let t">
  <pxw-modal-dialog [title]="t('productFiltersDialog.title')" [showCloseButton]="true">
    <pxw-icon icon="filter" />
    <ng-template #modalContent>
      <p>{{ t('productFiltersDialog.tags') }}</p>
      <div class="filter-tags">
        @for (current of tagsWithQuantities; track current) {
          <pxw-tag
            size="sm"
            [type]="selectedTagIdsMap[current.productTag.id] ? 'info' : 'neutral'"
            (click)="toggleSelectedTag(current.productTag.id)"
            >{{ current.productTag.tag }} ({{ current.count }})</pxw-tag
          >
        }
      </div>
    </ng-template>
    <ng-template #modalButtons>
      @if (selectedTagIds?.length > 0) {
        <pxw-button
          size="md"
          [rounded]="true"
          color="pink"
          appareance="outline"
          (click)="onRemoveFilters()"
        >
          <pxw-icon icon="ban" />
          {{ t('productFiltersDialog.removeText') }}
        </pxw-button>
      }
      <pxw-button size="md" [rounded]="true" color="blue" (click)="onApplyFilters()">
        <pxw-icon icon="check" />
        {{ t('productFiltersDialog.acceptText') }}
      </pxw-button>
    </ng-template>
  </pxw-modal-dialog>
</ng-container>
