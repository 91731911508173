import { Product, Category } from '@pedix-workspace/utils';
import { Establishment } from '@pedix-workspace/utils';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Inject, Injectable, Optional, inject } from '@angular/core';
import { REQUEST } from '../../../express.tokens';

import { EstablishmentUrlPipe, ImageStoragePipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { environment } from '../../../environments/environment';
import { AppRequestContextService } from '../../app-request-context.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  siteName = 'Pedix';
  currentMetaTags: HTMLMetaElement[] = [];

  get defaultImage() {
    return `${environment.siteUrl}/assets/icons/icon-192x192.png`;
  }

  get establishment() {
    return this.appRequestContextService.establishment;
  }

  private request = inject(REQUEST, { optional: true });

  
  private title = inject(Title);
  private meta = inject(Meta);
  private appRequestContextService = inject(AppRequestContextService);
  private establishmentUrlPipe = inject(EstablishmentUrlPipe);
  private imageStoragePipe = inject(ImageStoragePipe);
  
  constructor() {}

  resetCurrentMetaTags() {
    this.currentMetaTags.map(metaTag => {
      this.meta.removeTagElement(metaTag);
    });
  }

  setDefaultMetadata() {
    this.resetCurrentMetaTags();

    if (this.establishment) {
      this.setEstablishmentMetadata(this.establishment);
    } else {
      this.setMetadata({ name: 'author', content: this.siteName });
      this.setMetadata({ name: 'description', content: 'Catálogo online y pedidos por Whatsapp.' });
      this.setMetadata({ property: 'og:title', content: this.siteName });
      this.setMetadata({
        property: 'og:description',
        content: 'Catálogo online y pedidos por Whatsapp.',
      });
      this.setMetadata({ property: 'og:site_name', content: this.siteName });
      this.setMetadata({ property: 'og:url', content: environment.siteUrl });
      this.setMetadata({ property: 'og:type', content: 'website' });
      this.setMetadata({ property: 'og:title', content: this.siteName });
      this.setMetadata({ property: 'og:image', itemprop: 'image', content: this.defaultImage });
      this.setMetadata({ property: 'og:image:alt', content: this.siteName });
      this.setMetadata({ property: 'twitter:card', content: 'summary_large_image' });
    }
  }

  setEstablishmentMetadata(
    establishment: Pick<
      Establishment,
      | 'name'
      | 'slug'
      | 'logo'
      | 'seoSocialMedia'
      | 'customDomain'
      | 'subdomainRedirection'
      | 'subdomainStatus'
    >,
  ) {
    this.resetCurrentMetaTags();

    this.title.setTitle(establishment.name);

    this.setMetadata({ name: 'author', content: this.siteName });
    this.setMetadata({ property: 'og:site_name', content: this.siteName });
    this.setMetadata({ property: 'twitter:card', content: 'summary_large_image' });
    this.setMetadata({ property: 'twitter:title', content: establishment.name });
    this.setMetadata({
      property: 'og:url',
      content: this.establishmentUrlPipe.transform(establishment),
    });
    this.setMetadata({ property: 'og:type', content: 'website' });
    this.setMetadata({ property: 'og:title', content: `${this.siteName} | ${establishment.name}` });
    this.setMetadata({ property: 'og:image:alt', content: establishment.name });
    this.setMetadata({ property: 'twitter:image:alt', content: establishment.name });

    // { property: 'twitter:creator', content: '@urbn.catering' },

    // { property: 'twitter:site', content: '@pedixapp' },

    // { property: 'og:image:type', content: 'image/jpeg' },

    // { property: 'og:image:type', content: 'image/jpeg' },
    // { property: 'og:image:width', content: '1200' },
    // { property: 'og:image:height', content: '630' },

    if (!establishment.seoSocialMedia) {
      let logo = this.defaultImage;

      if (establishment.logo && this.establishment.features.includes('SEO_SOCIAL_MEDIA')) {
        logo = this.imageStoragePipe.transform(establishment.logo, { size: '250x250' });
      }

      this.setMetadata({ property: 'og:image', itemprop: 'image', content: logo });
    } else {
      const userAgent = this.request?.headers['user-agent'] || '';
      const isWhatsAppCrawler = userAgent.includes('WhatsApp/');

      const mediaImage = isWhatsAppCrawler
        ? establishment.seoSocialMedia?.shortImage
        : establishment.seoSocialMedia?.image;
      const socialMediaImage =
        this.imageStoragePipe.transform(mediaImage || establishment.logo, { size: '250x250' }) ||
        this.defaultImage;
      const linkImage =
        this.imageStoragePipe.transform(mediaImage || establishment.logo, { size: '250x250' }) ||
        this.defaultImage;
      const description =
        establishment.seoSocialMedia?.description || establishment.seoSocialMedia?.shortDescription;
      const shortDescription =
        establishment.seoSocialMedia?.shortDescription || establishment.seoSocialMedia?.description;

      if (description) {
        this.setMetadata({ name: 'description', content: description });
      }
      if (establishment.seoSocialMedia?.keywords) {
        this.setMetadata({
          name: 'keywords',
          content: establishment.seoSocialMedia.keywords.join(', '),
        });
      }

      if (shortDescription) {
        this.setMetadata({ property: 'twitter:description', content: shortDescription });
        this.setMetadata({ property: 'og:description', content: shortDescription });
      }
      if (socialMediaImage) {
        this.setMetadata({ property: 'twitter:image', content: socialMediaImage });
        this.setMetadata({ property: 'og:image', content: socialMediaImage });
        this.setMetadata({ property: 'og:image:url', content: socialMediaImage });
      }
      if (linkImage) {
        this.setMetadata({ property: 'og:image', itemprop: 'image', content: linkImage });
      }
    }
  }

  setCategoryMetadata(
    category: Pick<Category, 'id' | 'name' | 'image'>,
    establishment: Pick<
      Establishment,
      | 'name'
      | 'slug'
      | 'logo'
      | 'seoSocialMedia'
      | 'customDomain'
      | 'subdomainRedirection'
      | 'subdomainStatus'
    >,
  ) {
    this.resetCurrentMetaTags();

    const pageTitle = `${establishment.name} | ${category.name}`;

    this.title.setTitle(pageTitle);

    this.setMetadata({ name: 'author', content: this.siteName });
    this.setMetadata({ property: 'og:site_name', content: this.siteName });
    this.setMetadata({ property: 'twitter:card', content: 'summary_large_image' });
    this.setMetadata({ property: 'twitter:title', content: pageTitle });
    this.setMetadata({
      property: 'og:url',
      content: this.establishmentUrlPipe.transform(establishment, ['categoria', category.id]),
    });
    this.setMetadata({ property: 'og:type', content: 'website' });
    this.setMetadata({ property: 'og:title', content: `${this.siteName} | ${pageTitle}` });
    this.setMetadata({ property: 'og:image:alt', content: category.name });
    this.setMetadata({ property: 'twitter:image:alt', content: category.name });

    const socialMediaImage =
      this.imageStoragePipe.transform(category.image || establishment.logo, {
        size: '1500x1500',
      }) || this.defaultImage;
    const linkImage =
      this.imageStoragePipe.transform(category.image || establishment.logo, { size: '250x250' }) ||
      this.defaultImage;
    const description =
      establishment.seoSocialMedia?.description || establishment.seoSocialMedia?.shortDescription;
    const shortDescription =
      establishment.seoSocialMedia?.shortDescription || establishment.seoSocialMedia?.description;

    if (description) {
      this.setMetadata({ name: 'description', content: description });
    }
    if (shortDescription) {
      this.setMetadata({ property: 'twitter:description', content: shortDescription });
      this.setMetadata({ property: 'og:description', content: shortDescription });
    }
    if (establishment.seoSocialMedia?.keywords) {
      this.setMetadata({
        name: 'keywords',
        content: establishment.seoSocialMedia.keywords.join(', '),
      });
    }
    if (socialMediaImage) {
      this.setMetadata({ property: 'twitter:image', content: socialMediaImage });
      this.setMetadata({ property: 'og:image', content: socialMediaImage });
      this.setMetadata({ property: 'og:image:url', content: socialMediaImage });
    }
    if (linkImage) {
      this.setMetadata({ property: 'og:image', itemprop: 'image', content: linkImage });
    }
  }

  setProductMetadata(
    product: Pick<Product, 'id' | 'name' | 'description' | 'images'>,
    establishment: Pick<
      Establishment,
      | 'name'
      | 'slug'
      | 'logo'
      | 'seoSocialMedia'
      | 'customDomain'
      | 'subdomainRedirection'
      | 'subdomainStatus'
    >,
  ) {
    this.resetCurrentMetaTags();

    const pageTitle = `${establishment.name} | ${product.name}`;

    this.title.setTitle(pageTitle);

    this.setMetadata({ name: 'author', content: this.siteName });
    this.setMetadata({ property: 'og:site_name', content: this.siteName });
    this.setMetadata({ property: 'twitter:card', content: 'summary_large_image' });
    this.setMetadata({ property: 'twitter:title', content: pageTitle });
    this.setMetadata({
      property: 'og:url',
      content: this.establishmentUrlPipe.transform(establishment, ['producto', product.id]),
    });
    this.setMetadata({ property: 'og:type', content: 'website' });
    this.setMetadata({ property: 'og:title', content: `${this.siteName} | ${pageTitle}` });
    this.setMetadata({ property: 'og:image:alt', content: product.name });
    this.setMetadata({ property: 'twitter:image:alt', content: product.name });

    const socialMediaImage =
      this.imageStoragePipe.transform(product.images[0] || establishment.logo, {
        size: '1500x1500',
      }) || this.defaultImage;
    const linkImage =
      this.imageStoragePipe.transform(product.images[0] || establishment.logo, {
        size: '250x250',
      }) || this.defaultImage;

    if (product.description) {
      this.setMetadata({ name: 'description', content: product.description });
      this.setMetadata({ property: 'twitter:description', content: product.description });
      this.setMetadata({ property: 'og:description', content: product.description });
    }
    if (establishment.seoSocialMedia?.keywords) {
      this.setMetadata({
        name: 'keywords',
        content: establishment.seoSocialMedia.keywords.join(', '),
      });
    }
    if (socialMediaImage) {
      this.setMetadata({ property: 'twitter:image', content: socialMediaImage });
      this.setMetadata({ property: 'og:image', content: socialMediaImage });
      this.setMetadata({ property: 'og:image:url', content: socialMediaImage });
    }
    if (linkImage) {
      this.setMetadata({ property: 'og:image', itemprop: 'image', content: linkImage });
    }
  }

  private setMetadata(metaDefinition: MetaDefinition) {
    const metaTag = this.meta.addTag(metaDefinition);

    this.currentMetaTags.push(metaTag);
  }
}
