import { Injectable, inject } from '@angular/core';
import { AppRequestContextService } from './app-request-context.service';
import { ApiV1_Category, ApiV1_Product } from '@pedix-workspace/api-v1';
import { SearchResultsService } from '@pedix-workspace/pedixapp-core-services';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppSearchResultsService extends SearchResultsService {
  private appRequestContextService = inject(AppRequestContextService);

  getCategories(): Observable<ApiV1_Category[]> {
    return of(this.appRequestContextService.categories);
  }
  getProducts(): Observable<ApiV1_Product[]> {
    return of(this.appRequestContextService.activeProducts);
  }
}
