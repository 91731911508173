import { Component, Input, OnInit, inject } from '@angular/core';
import { UiHereMapsConfigService } from '../here-maps-config.service';
import { HLatLng } from '../here-maps.types';
import { HERE_MAPS_SERVICE_API_KEY } from '../here-maps.service';

@Component({
  selector: 'pxw-here-maps-static',
  templateUrl: './here-maps-static.component.html',
  styleUrl: './here-maps-static.component.scss',
  standalone: true,
})
export class HereMapsStaticComponent implements OnInit {
  private hereMapsConfig = inject(UiHereMapsConfigService);
  private apiKey = inject(HERE_MAPS_SERVICE_API_KEY);

  @Input() hereMapsApiKey: string;
  @Input({ required: true }) center: HLatLng;
  @Input() marker: {
    center: HLatLng;
    label?: string;
  };
  @Input() zoom: number = 12;
  @Input() width: number = 400;
  @Input() height: number = 400;
  @Input() alt: string | null = null;

  get staticMapUrl() {
    let url = `https://image.maps.hereapi.com/mia/v3/base/mc/center:${this.center.lat},${this.center.lng};zoom=${this.zoom}/${this.width}x${this.height}/png?apiKey=${this.hereMapsApiKey}&style=lite.day`;

    if (this.marker) {
      url += `&overlay=point:${this.marker.center.lat},${this.marker.center.lng};icon=poi;label=${this.marker.label};text-color=%2321243d;text-outline-color=%23FFF;outline-width=2;size=large&features=pois:disabled`;
    }

    return url;
  }

  ngOnInit(): void {
    if (!this.hereMapsApiKey) {
      this.hereMapsApiKey = this.apiKey;
    }
  }
}
