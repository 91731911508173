import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { ItemListGroup } from './item-list.component';
import { ProductGroupingStrategy } from '@pedix-workspace/utils';
import { ItemListGroupProductsByGroupPipe } from './item-list-group-products-by-group.pipe';
import { ItemListGroupProductsByCategoryPipe } from './item-list-group-products-by-category.pipe';
import { SortProductsByStockPipe } from '@pedix-workspace/pedixapp-shared-pipes';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pxwItemListProductGroupingStrategy',
  standalone: true,
})
export class ItemListProductGroupingStrategyPipe<
  P extends { categoryId: string; groupName: string },
  C extends { id: string; name: string },
> implements PipeTransform
{
  private sortProductsByStockPipe = inject(SortProductsByStockPipe);
  private groupingStrategyByCategory = inject(ItemListGroupProductsByCategoryPipe);
  private groupingStrategyByProductGroup = inject(ItemListGroupProductsByGroupPipe);

  transform(
    products: P[],
    options: {
      groupingStrategy: ProductGroupingStrategy;
      categories?: C[];
      undefinedGroupName?: string;
    },
  ): ItemListGroup<{ id: string; name: string }, P>[] {
    if (!Array.isArray(products) || products.length === 0) {
      return [];
    }
    if (options.groupingStrategy === 'category' && options?.categories) {
      return this.groupingStrategyByCategory.transform(products, options.categories);
    }
    if (
      options.groupingStrategy === 'product-group' &&
      options?.undefinedGroupName &&
      products.some(product => product.groupName)
    ) {
      return this.groupingStrategyByProductGroup.transform(products, options.undefinedGroupName);
    }
    return [
      {
        group: {
          id: 'none',
          name: '',
        },
        indexedItems: this.sortProductsByStockPipe
          .transform(products)
          .map((product, listIndex) => ({
            listIndex,
            item: product,
          })),
      },
    ];
  }
}
