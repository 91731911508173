import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="image"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconImageComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.6667 8H10.3333C8.495 8 7 9.495 7 11.3333V34.6667C7 36.505 8.495 38 10.3333 38H33.6667C35.505 38 37 36.505 37 34.6667V11.3333C37 9.495 35.505 8 33.6667 8ZM10.3333 34.6667V11.3333H33.6667L33.67 34.6667H10.3333Z"
    />
    <path d="M18.6667 23.2857L17 21.7143L12 28H32L23.6667 17L18.6667 23.2857Z" />
  </svg>`,
})
export class IconImageComponent extends IconComponent {}
